// @flow
import React from 'react';
import Table from "components/table";
import {textStyle} from "components/table/Table";
import {Input} from "components/input";
import SludgeDto from 'dtos/sludge/SldugeDto';

type SludgeWriteTableProps = {
    onChangeInput(e: React.ChangeEvent<HTMLInputElement>): void;
    sludge: SludgeDto
};

function SludgeWriteTable({onChangeInput, sludge}: SludgeWriteTableProps) {
    return (
        <Table>
            <Table.Row >
                <Table.Col flex={1}>
                    <span css={textStyle}>처리량</span>
                </Table.Col>
                <Table.Col flex={1}>
                    <Input style={{}} onChange={onChangeInput} name="value" value={sludge.value}/>
                </Table.Col>
            </Table.Row>
            <Table.Row >
                <Table.Col flex={1}>
                    <span css={textStyle}>발생시설</span>
                </Table.Col>
                <Table.Col flex={1}>
                    <Input style={{}} onChange={onChangeInput} name="generatingFacility" value={sludge.generatingFacility}/>
                </Table.Col>
            </Table.Row>
            <Table.Row >
                <Table.Col flex={1}>
                    <span css={textStyle}>이송 처리시설</span>
                </Table.Col>
                <Table.Col flex={1}>
                    <Input style={{}} onChange={onChangeInput} name="transferProcessingFacility" value={sludge.transferProcessingFacility}/>
                </Table.Col>
            </Table.Row>
            <Table.Row >
                <Table.Col flex={1}>
                    <span css={textStyle}>이송업체</span>
                </Table.Col>
                <Table.Col flex={1}>
                    <Input style={{}} onChange={onChangeInput} name="transferCompany" value={sludge.transferCompany}/>
                </Table.Col>
            </Table.Row>
        </Table>
    )
}

export default SludgeWriteTable;
