import React from 'react'
import { ResponsiveLine } from '@nivo/line'
import { css } from '@emotion/react'

type ChartWithLineProps = {
	data: any[]
} & any

const ChartWithLine: React.FC<ChartWithLineProps> = ({ data, ...props }) => {
	return (
		<div css={Style}>
			<ResponsiveLine
				{...props}
				data={data}
				margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
				xScale={{ type: 'point' }}
				axisTop={null}
				axisRight={null}
				axisBottom={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: '날짜',
					legendPosition: 'middle',
					legendOffset: 32,
				}}
				axisLeft={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: 'ppm',
					legendPosition: 'middle',
					legendOffset: -40,
				}}
				pointSize={9}
				pointColor={{ theme: 'background' }}
				pointBorderWidth={2}
				pointBorderColor={{ from: 'serieColor' }}
				pointLabelYOffset={-12}
				useMesh={true}
				legends={[
					{
						anchor: 'bottom-right',
						direction: 'column',
						justify: false,
						translateX: 100,
						translateY: 0,
						itemsSpacing: 0,
						itemDirection: 'left-to-right',
						itemWidth: 80,
						itemHeight: 20,
						itemOpacity: 0.75,
						symbolSize: 12,
						symbolShape: 'circle',
						symbolBorderColor: 'rgba(0, 0, 0, .5)',
						effects: [
							{
								on: 'hover',
								style: {
									itemBackground: 'rgba(0, 0, 0, .03)',
									itemOpacity: 1,
								},
							},
						],
					},
				]}
			/>
		</div>
	)
}

const Style = css`
	height: 400px;
`

export default ChartWithLine
