import React from 'react'
import Selector from 'components/selector/Selector'
import Button from 'components/button'
import { NavLink } from 'react-router-dom'
import palette from 'styles/palette'
import { SearchType } from 'hooks/useSearch'
import SearchInput from 'components/input/SearchInput'
import { SearchKindEnum } from 'types/graphql'
import { SelectorValueType } from 'types/common'

type Props = {
	onPush(): void
	data: SearchType
	onChangeSearch(e: React.ChangeEvent<HTMLInputElement>): void
	onChangeSelector(value: SelectorValueType): void
}
const UtilsByUser: React.FC<Props> = ({ onPush, data, onChangeSearch, onChangeSelector }) => {
	return (
		<>
			<Selector
				options={[
					{ label: '아이디', value: SearchKindEnum.USER_ID },
					{ label: '소속', value: SearchKindEnum.GROUP_NAME },
					{ label: '거래처', value: SearchKindEnum.SITE_NAME },
					{ label: '이메일', value: SearchKindEnum.EMAIL },
					{ label: '이름', value: SearchKindEnum.USER_NAME },
				]}
				values={[data.searchKind]}
				onChange={onChangeSelector}
				className="group_selector"
			/>
			<SearchInput data={data} onChange={onChangeSearch} onPush={onPush} />
			<NavLink to="/user/write">
				<Button label="계정 등록" backgroundColor={palette.blue['primary']} primary css={{ padding: '0.85rem 2rem' }} />
			</NavLink>
		</>
	)
}

export default UtilsByUser
