import { useCallback } from 'react'
import useCustomMutation from 'hooks/useCustomMutation'
import { CreateFacility, CreateFacilityVariables } from 'types/graphql'
import { CREATE_FACILITY } from 'queries/facilityQuery'
import FacilityDto from 'dtos/facility/FacilityDto'
import useInput from 'hooks/useInput'

function useCreateFacility() {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [facility, _, events] = useInput<FacilityDto>(new FacilityDto())
	const [onSave, {}] = useCustomMutation<CreateFacility, CreateFacilityVariables>(CREATE_FACILITY)

	const _onSave = useCallback(() => {
		const data = facility.getCreateDto()
		onSave({ data })
	}, [facility])

	return {
		facility,
		onSave: _onSave,
		...events,
	}
}

export default useCreateFacility
