import { useQuery } from '@apollo/client'
import { UpdateUser, UpdateUserInput, UpdateUserVariables, User, User_selectUser, UserVariables } from 'types/graphql'
import { useParams } from 'react-router-dom'
import { UserParamsType } from 'types/user'
import useUserCommon from 'hooks/user/useUserCommon'
import { UPDATE_USER_QUERY, USER_QUERY } from 'queries/userQuery'

function useUpdateUser() {
	const { userId } = useParams<UserParamsType>()
	const { setUser, ...props } = useUserCommon<UpdateUser, UpdateUserVariables>(getUserUpdateObject(), UPDATE_USER_QUERY)
	const {} = useQuery<User, UserVariables>(USER_QUERY, {
		variables: { data: { id: userId ? Number(userId) : 0 } },
		onCompleted: result => {
			setUser(getUserUpdateObject(result.selectUser))
		},
	})

	return {
		...props,
		setUser,
	}
}

export const getUserUpdateObject = (data?: User_selectUser | null) => {
	return {
		id: data ? data.id : 0,
		userId: data ? data.userId : '',
		name: data ? data.name : '',
		password: data ? data.password : '',
		cellPhone: data ? data.cellPhone : '',
		email: data ? data.email : '',
		memo: data ? data.memo : '',
		roleId: data && data.role ? data.role.id : 0,
		siteIds: data && data.sites ? data.sites.map(site => site.id) : [],
		groupId: data && data.sites && data.sites.length > 0 ? data.sites[0].group?.id : -1,
	} as UpdateUserInput
}

export default useUpdateUser
