import React from 'react'
import Table from 'components/table'
import { textStyle } from 'components/table/Table'
import MedicineDto from 'dtos/medicine/MedicineDto'
import { Input } from 'components/input'

type Props = {
	medicines: MedicineDto[]
	onChangeInput(e: React.ChangeEvent<HTMLInputElement>): void
}
const MedicineWriteTable: React.FC<Props> = ({ medicines, onChangeInput }) => {
	return (
		<Table>
			<Table.Row bgColor="#E6E8EE">
				<Table.Col flex={20}>
					<span css={textStyle}>항목 (단위)</span>
				</Table.Col>
				<Table.Col flex={40}>
					<span css={textStyle}>구입량</span>
				</Table.Col>
				<Table.Col flex={40}>
					<span css={textStyle}>사용량</span>
				</Table.Col>
			</Table.Row>
			{medicines.map(medicine => {
				return (
					<Table.Row key={medicine.item.id}>
						<Table.Col flex={20}>
							<span css={textStyle}>{medicine.item.name}</span>
						</Table.Col>
						<Table.Col flex={40}>
							<Input
								value={medicine.orderAmount}
								onChange={onChangeInput}
								id={String(medicine.item.id)}
								name="orderAmount"
							/>
						</Table.Col>
						<Table.Col flex={40}>
							<Input
								value={medicine.useAmount}
								onChange={onChangeInput}
								id={String(medicine.item.id)}
								name="useAmount"
							/>
						</Table.Col>
					</Table.Row>
				)
			})}
		</Table>
	)
}
export default MedicineWriteTable
