import { css } from '@emotion/react'
import React, { useRef, forwardRef } from 'react'
import useMergedRef from '@react-hook/merged-ref'
import InputBase from 'components/inputBase/InputBase'
import palette from 'styles/palette'
import Icon from 'components/Icon'
import { IconType } from 'components/Icon/Icon'
import { font } from 'styles/Theme'

export type InputProps = {
	prefix?: string
	icon?: IconType
} & React.InputHTMLAttributes<HTMLInputElement>

function Input({ prefix, className, disabled, icon, ...rest }: InputProps, ref: React.Ref<HTMLDivElement>) {
	const innerRef = useRef<HTMLInputElement>(null)
	const mergedRef = useMergedRef(innerRef, ref)

	return (
		<InputBase
			css={wrapper(disabled, icon)}
			onClick={() => innerRef.current?.focus()}
			className={className}
			disabled={disabled}
		>
			{prefix !== undefined && <span>{prefix}</span>}
			{icon && <Icon name={icon} />}
			<input css={style} ref={mergedRef} disabled={disabled} {...rest} />
		</InputBase>
	)
}

const wrapper = (disabled?: boolean, name?: IconType) => css`
	padding: 1.6rem;
	display: flex;
	align-items: center;
	cursor: text;
	position: relative;
	width: 100%;
	span {
		color: ${palette.blueGrey[300]};
		margin-right: 0.5rem;
	}
	${disabled &&
	css`
		span {
			color: ${palette.blueGrey[200]};
		}
		cursor: not-allowed;
	`}

	${name &&
	css`
		padding-left: 3.2rem;
	`}

	& > svg {
		position: absolute;
		top: 50%;
		left: 1rem;
		transform: translateY(-50%);

		width: 1.6rem;
	}
`

const style = css`
	${font.body3}
	flex: 1;
	border: none;
	color: inherit;
	background: none;
	outline: none;
	padding: 0;
	text-align: center;
	//font-size: inherit;
	&::placeholder {
		color: ${palette.blueGrey[200]};
	}
	&:disabled {
		cursor: not-allowed;
		color: inherit;
	}
`

export default forwardRef<HTMLInputElement, InputProps>(Input)
