import { gql } from '@apollo/client'

export const SITES_QUERY = gql`
	query Sites($data: SelectSitesInput!) {
		selectSiteList(data: $data) {
			sites {
				id
				address
				group {
					id
					groupName
					itemPrices {
						item {
							id
						}
						price
					}
				}
				name
				cellPhone
				manager
				regDt
				memo
				area {
					name
					id
				}
			}
			paging {
				startNo
				limit
				endNo
				lastPageNo
				pageNo
			}
		}
	}
`

export const SITE_QUERY = gql`
	query Site($data: SelectSiteInput!) {
		selectSite(data: $data) {
			id
			address
			group {
				id
				groupName
			}
			name
			manager
			cellPhone
			memo
			salesType
			area {
				id
				name
			}
		}
	}
`

export const CREATE_SITE_QUERY = gql`
	mutation CreateSite($data: InsertSiteInput!) {
		insertSite(data: $data) {
			id
		}
	}
`

export const REMOVE_SITE_QUERY = gql`
	mutation RemoveSite($id: Float!) {
		deleteSite(id: $id)
	}
`

export const UPDATE_SITE_QUERY = gql`
	mutation UpdateSite($data: UpdateSiteInput!) {
		updateSite(data: $data)
	}
`
