import React, {useCallback} from 'react'
import useSearch, {getSearchInitData, onChangeType} from 'hooks/useSearch'
import {FacilityOperation, FacilityOperationVariables, SearchKindEnum} from 'types/graphql'
import {SelectorValueType} from 'types/common'
import View from 'components/templete/view'
import FacilityCountTable from 'components/facility/FacilityCountTable'
import SiteAndYearUtils from 'components/utils/SiteAndYearUtils'
import useCustomQuery from 'hooks/useCustomQuery'
import {FACILITY_OPERATION_QUERY} from 'queries/facilityQuery'
import {observer} from 'mobx-react-lite'

function FacilityCount() {
	const { searchData, onChange, onChangeSiteId, getUrlByPageNo } = useSearch(
		'/facility/count',
		getSearchInitData(SearchKindEnum.GROUP_NAME),
	)
	const { onRefetch, data } = useCustomQuery<FacilityOperation, FacilityOperationVariables>(
		FACILITY_OPERATION_QUERY,
		{
			data: {
				siteId: searchData.siteId,
				year: searchData.year,
			},
		},
		false,
	)

	const _onChangeSelector = useCallback(async (value: SelectorValueType) => {
		onChangeSiteId(value)
		await onRefetch({ data: { year: searchData.year, siteId: Number(value) } })
	}, [])

	const _onChange = async (type: keyof typeof onChangeType, value: string) => {
		onChange(type, value)
		await onRefetch({ data: { year: value, siteId: searchData.siteId } })
	}

	return (
		<View
			title="시설 관리 현황"
			tables={[<FacilityCountTable key="FacilityCountTable" data={data?.selectCountBySiteIdAndYear} />]}
			utils={<SiteAndYearUtils onChangeSelector={_onChangeSelector} searchData={searchData} onChange={_onChange} />}
			baseUrl={getUrlByPageNo}
		/>
	)
}

export default observer(FacilityCount)
