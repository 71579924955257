// @flow
import React from 'react';
import Table from "components/table";
import {textStyle} from "components/table/Table";
import {FacilityOperation_selectCountBySiteIdAndYear} from "types/graphql";

type FacilityCountTableProps = {
    data?: FacilityOperation_selectCountBySiteIdAndYear
};

const MONTH = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]

function FacilityCountTable({data}: FacilityCountTableProps) {
    return (
        <Table>
            <Table.Row bgColor="#E6E8EE">
                <Table.Col flex={6}>
                    <span css={textStyle}>날짜</span>
                </Table.Col>
                <Table.Col flex={16}>
                    <span css={textStyle}>수질분석</span>
                </Table.Col>
                <Table.Col flex={16}>
                    <span css={textStyle}>유량분석</span>
                </Table.Col>
                <Table.Col flex={16}>
                    <span css={textStyle}>일지</span>
                </Table.Col>
                <Table.Col flex={16}>
                    <span css={textStyle}>체크리스트</span>
                </Table.Col>
                <Table.Col flex={16}>
                    <span css={textStyle}>슬러지 처리량</span>
                </Table.Col>
                <Table.Col flex={16}>
                    <span css={textStyle}>시설물 수리횟수</span>
                </Table.Col>
            </Table.Row>
            {
                MONTH.map(m => {
                    const project = data?.projects.find(obj => obj.date.includes(m))
                    const flow = data?.influentFlows.find(obj => obj.date.includes(m))
                    const diary = data?.diaries.find(obj => obj.date.includes(m))
                    const check = data?.dailyChecks.find(obj => obj.date.includes(m))
                    const sludge = data?.sludges.find(obj => obj.date.includes(m))
                    const facility = data?.facilities.find(obj => obj.date.includes(m))
                    return (
                        <Table.Row key={m}>
                            <Table.Col flex={6}>
                                <span css={textStyle}>{m}월</span>
                            </Table.Col>
                            <Table.Col flex={16}>
                                <span css={textStyle}>{project ? project.count : 0}회</span>
                            </Table.Col>
                            <Table.Col flex={16}>
                                <span css={textStyle}>{flow ? flow.count : 0}회</span>
                            </Table.Col>
                            <Table.Col flex={16}>
                                <span css={textStyle}>{diary ? diary.count : 0}회</span>
                            </Table.Col>
                            <Table.Col flex={16}>
                                <span css={textStyle}>{check ? check.count : 0}회</span>
                            </Table.Col>
                            <Table.Col flex={16}>
                                <span css={textStyle}>{sludge ? sludge.count : 0}회</span>
                            </Table.Col>
                            <Table.Col flex={16}>
                                <span css={textStyle}>{facility ? facility.count : 0}회</span>
                            </Table.Col>
                        </Table.Row>
                    )
                })
            }
        </Table>
    )
};

export default FacilityCountTable;
