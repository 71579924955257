import {ApolloError, DocumentNode, useMutation} from '@apollo/client'
import {OperationVariables} from '@apollo/client/core'
import {toast} from 'react-toastify'
import {ValidationResultType} from 'types/common'

function useCustomMutation<TData = any, TVariables = OperationVariables>(
	query: DocumentNode,
	props?: {
		onCompleted?: (data: TData) => void
		onError?: (error: ApolloError) => void
		isBlocked?: boolean
	},
): [
	(variables: TVariables, event?: { onCompleted?(): void; onError?(message: string): void }) => void,
	{
		setValidationResult(result: ValidationResultType): void
	},
] {
	let validationResult: ValidationResultType = { result: true, message: '' }
	const [save] = useMutation<TData, TVariables>(query, {
		update: cache => cache.reset(),
		onCompleted: props && props.onCompleted,
		onError: props && props.onError,
	})

	const onSave = async (variables: TVariables, event?: { onCompleted?(): void; onError?(message: string): void }) => {
		try {
			if (validationResult.result) {
				if (props && props.isBlocked) {
					toast.info('저장중.... ( 저장이 끝나면 닫아주세요. )', { autoClose: false, hideProgressBar: true })
				}
				await save({ variables })
				event && event.onCompleted && event.onCompleted()
				toast.success('성공적으로 저장되었습니다.')
			} else {
				toast.warning(validationResult.message)
				event && event.onError && event.onError(validationResult.message)
			}
		} catch (e) {
			if (e instanceof Error) {
				toast.error(e.message)
				event && event.onError && event.onError(e.message)
			}
		}
	}

	const setValidationResult = (result: ValidationResultType) => {
		validationResult = result
	}

	return [onSave, { setValidationResult }]
}

export default useCustomMutation
