import { useCallback } from 'react'
import { FileUpload } from 'queries/FileQuery'
import { useMutation } from '@apollo/client'
import { fileUpload, fileUploadVariables } from 'types/graphql'

type ImageSaveType = { file: File; refId: number }

export const useImageUpload = ({ onCompleted }: { onCompleted?: (data: fileUpload) => void }) => {
	const [save, { data, loading }] = useMutation<fileUpload, fileUploadVariables>(FileUpload, {
		update: cache => cache.reset(),
		onError: error => console.log(error.message),
		onCompleted,
	})

	const imageSave = useCallback(async ({ file, refId }: ImageSaveType) => {
		const variables = {
			file,
			refId,
		}
		await save({ variables })
	}, [])

	return { imageSave, file: data?.fileUpload, loading }
}
