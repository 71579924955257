import React from 'react'
import Table from 'components/table'
import CheckButton from 'components/button/CheckButton'
import { textStyle } from 'components/table/Table'
import { Codes_selectCodeList_data, DailyCheckItemEnum } from 'types/graphql'
import palette from 'styles/palette'
import DailyCheckDto from 'dtos/dailyCheck/DailyCheckDto'
import { Input } from 'components/input'

type Props = {
	onToggle(type: 'red' | 'green'): (id: number) => void
	codes?: Codes_selectCodeList_data[] | null
	operation: DailyCheckDto
	onChangeInput(e: React.ChangeEvent<HTMLInputElement>): void
}
const ColDefaultStyle = {
	flexDirection: 'column',
	padding: '0',
} as React.CSSProperties

const OperationCheckTable: React.FC<Props> = ({ onToggle, codes, operation, onChangeInput }) => {
	return (
		<Table>
			<Table.Row bgColor="#E6E8EE">
				<Table.Col flex={14}>
					<span css={textStyle}>점검부</span>
				</Table.Col>
				<Table.Col flex={16} style={ColDefaultStyle}>
					<span css={textStyle}>항목</span>
				</Table.Col>
				<Table.Col flex={70} style={ColDefaultStyle}>
					<Table.Row>
						<Table.Col bgColor="#E6E8EE" style={{ border: '0px' }}>
							<span css={textStyle}>점검결과</span>
						</Table.Col>
					</Table.Row>
					<Table.Row style={{ borderBottom: '0px', width: '100%', backgroundColor: '#9098B4', color: '#fff' }}>
						<Table.Col style={{ borderLeft: '0px' }} flex={2}>
							<span css={textStyle}>이상 유</span>
						</Table.Col>
						<Table.Col flex={2}>
							<span css={textStyle}>이상 무</span>
						</Table.Col>
						<Table.Col style={{ borderRight: '0px' }} flex={6}>
							<span css={textStyle}>특이사항</span>
						</Table.Col>
					</Table.Row>
				</Table.Col>
			</Table.Row>

			{codes?.map(code => {
				return (
					<Table.Row bgColor="#E6E8EE" key={code.id}>
						<Table.Col flex={14}>
							<span css={textStyle}>{code.name}</span>
						</Table.Col>
						<Table.Col flex={16} style={ColDefaultStyle}>
							{code.childCodes.map((child, index) => {
								const borderTop = index === 0 ? 0 : `1px solid ${palette.grey['line']}`
								return (
									<Table.Row style={{ width: '100%', height: '100%', borderBottom: 0, borderTop }} key={child.id}>
										<Table.Col style={{ borderRight: '0px', borderLeft: '0px' }}>
											<span css={textStyle}>{child.name}</span>
										</Table.Col>
									</Table.Row>
								)
							})}
						</Table.Col>
						<Table.Col flex={70} style={ColDefaultStyle}>
							{code.childCodes.map((child, index) => {
								const borderBottom = index === code.childCodes.length - 1 ? '0px' : `1px solid ${palette.grey['line']}`
								const findData = operation.dailyCheckItems?.find(item => item.code.id === child.id)
								return (
									<Table.Row style={{ borderTop: '0px', width: '100%', borderBottom: 0 }} key={child.id + 'data'}>
										<Table.Col style={{ borderLeft: '0px', borderBottom }} flex={2}>
											<CheckButton
												value={findData && findData.value === DailyCheckItemEnum.ERROR ? child.id : -1}
												type="red"
												onToggle={onToggle('red')}
												id={child.id}
											/>
										</Table.Col>
										<Table.Col flex={2} style={{ borderBottom }}>
											<CheckButton
												value={findData && findData.value === DailyCheckItemEnum.SUCCESS ? child.id : -1}
												type="green"
												onToggle={onToggle('green')}
												id={child.id}
											/>
										</Table.Col>
										<Table.Col style={{ borderRight: '0px', borderBottom }} flex={6}>
											<Input
												value={findData ? String(findData.memo) : ''}
												name={String(child.id)}
												onChange={onChangeInput}
											/>
										</Table.Col>
									</Table.Row>
								)
							})}
						</Table.Col>
					</Table.Row>
				)
			})}
		</Table>
	)
}
export default OperationCheckTable
