import {
	DailyCheckItemEnum,
	InsertDailyCheckInput,
	Operation_dailyCheckByDay,
	Operation_dailyCheckByDay_dailyCheckItems,
	Operation_dailyCheckByDay_site,
} from 'types/graphql'

export default class DailyCheckDto implements Operation_dailyCheckByDay {
	__typename: 'DailyCheckEntity'
	id: number
	site: Operation_dailyCheckByDay_site | null
	dailyCheckItems: Operation_dailyCheckByDay_dailyCheckItems[] | null

	constructor(dailyCheck?: DailyCheckDto | Operation_dailyCheckByDay) {
		this.__typename = 'DailyCheckEntity'
		this.id = dailyCheck ? dailyCheck.id : 0
		this.dailyCheckItems = dailyCheck ? dailyCheck.dailyCheckItems : []
		this.site = dailyCheck ? dailyCheck.site : { id: 0, __typename: 'SiteEntity' }
	}

	getCreate(): InsertDailyCheckInput | undefined {
		if (this.dailyCheckItems)
			return {
				id: this.id,
				siteId: Number(this.site?.id),
				dailyCheckItem: this.dailyCheckItems.map(items => ({
					id: items.id,
					codeId: items.code.id,
					value: items.value,
					memo: items.memo,
				})),
			}
	}

	setMemo({ codeId, value }: { codeId: number; value: string }) {
		if (!this.dailyCheckItems) return
		for (const item of this.dailyCheckItems) {
			const isActiveItem = item.code.id === codeId
			if (isActiveItem) {
				item.memo = value
			}
		}
	}

	setValue({ codeId, type }: { codeId: number; type: 'red' | 'green' }) {
		if (!this.dailyCheckItems) return
		for (const item of this.dailyCheckItems) {
			const isActiveItem = item.code.id === codeId
			let value = item.value
			switch (item.value) {
				case DailyCheckItemEnum.NONE:
					value = type === 'red' ? DailyCheckItemEnum.ERROR : DailyCheckItemEnum.SUCCESS
					break
				case DailyCheckItemEnum.ERROR:
					value = type === 'red' ? DailyCheckItemEnum.NONE : DailyCheckItemEnum.SUCCESS
					break
				case DailyCheckItemEnum.SUCCESS:
					value = type === 'red' ? DailyCheckItemEnum.ERROR : DailyCheckItemEnum.NONE
					break
			}

			if (isActiveItem) {
				item.value = value
			}
		}
	}
}
