import React from 'react'
import * as svg from 'assets/icon'

export type IconType = keyof typeof svg
export const IconTypes = Object.keys(svg) as never[]
export type IconProps = {
	name: IconType
	className?: string
	style?: React.CSSProperties
	color?: string
	size?: string | number
}

function Icon({ name, className, style, color, size }: IconProps) {
	const SvgIcon = svg[name]
	return <SvgIcon css={{ width: size, height: 'auto', color: color }} className={className} style={style} />
}

export default Icon
