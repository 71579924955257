import { useCallback } from 'react'
import useCustomMutation from 'hooks/useCustomMutation'
import { Facility, FacilityVariables, UpdateFacility, UpdateFacilityVariables } from 'types/graphql'
import { FACILITY_QUERY, UPDATE_FACILITY_QUERY } from 'queries/facilityQuery'
import FacilityDto from 'dtos/facility/FacilityDto'
import useInput from 'hooks/useInput'
import { useQuery } from '@apollo/client'

function useUpdateFacility(id: number) {
	const [facility, setFacility, events] = useInput<FacilityDto>(new FacilityDto())
	const [onSave, {}] = useCustomMutation<UpdateFacility, UpdateFacilityVariables>(UPDATE_FACILITY_QUERY)
	useQuery<Facility, FacilityVariables>(FACILITY_QUERY, {
		variables: { data: { id } },
		onCompleted: data => {
			setFacility(new FacilityDto(data.selectFacility))
		},
	})

	const _onSave = useCallback(() => {
		const data = facility.getUpdateDto()
		if (data === null) return
		onSave({ data })
	}, [facility])

	return {
		facility,
		onSave: _onSave,
		...events,
	}
}

export default useUpdateFacility
