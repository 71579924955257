import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ApolloProvider } from '@apollo/client'
import client from './configs/apollo'
import GlobalStyle from './styles/GlobalStyle'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { LoginProvider } from 'stores/ContextProvider'

ReactDOM.render(
	<React.StrictMode>
		<HelmetProvider>
			<Helmet>
				<title>QnTECH</title>
			</Helmet>
			<LoginProvider>
				<ApolloProvider client={client}>
					<BrowserRouter>
						<GlobalStyle />
						<App />
					</BrowserRouter>
				</ApolloProvider>
			</LoginProvider>
		</HelmetProvider>
	</React.StrictMode>,
	document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
