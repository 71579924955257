const config = {
	nodeEnv: process.env.NODE_ENV,
	// 앱 관련 설정
	APP: {
		BACKEND_HOST: process.env.BACKEND_HOST || 'http://eco.qntech.co.kr:8073',
		// BACKEND_HOST: process.env.BACKEND_HOST || 'http://localhost:8079',
		API_HOST: process.env.API_HOST,
	},
}

export default config
