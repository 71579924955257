import React from 'react'
import { getSearchInitData, SearchType } from 'hooks/useSearch'
import { SelectorType, SelectorValueType } from 'types/common'
import Selector from 'components/selector/Selector'
import Button from 'components/button'
import { NavLink } from 'react-router-dom'
import { SearchKindEnum } from 'types/graphql'
import palette from 'styles/palette'
import YearInput from 'components/yearInput'
import SiteStore from 'stores/SiteStore'

const onChangeType = getSearchInitData(SearchKindEnum.MANAGER)
type Props = {
	onChangeSelector(value: SelectorValueType): void
	searchData: SearchType
	onChange: (type: keyof typeof onChangeType, value: string) => void
	groupUrl?: string
	printRef?: React.RefObject<HTMLDivElement>
	writeUrl?: string
}

function SiteAndYearUtils({ onChangeSelector, onChange, searchData, groupUrl, printRef, writeUrl }: Props) {
	return (
		<>
			<Selector
				options={[
					{ label: '휴게소를 선택해 주세요.', value: -1 },
					...(SiteStore.sites
						? SiteStore.sites.map(
								site =>
									({
										label: site.name,
										value: site.id,
									} as SelectorType),
						  )
						: []),
				]}
				values={[String(searchData.siteId)]}
				onChange={onChangeSelector}
				css={{ height: '100%', margin: '0 1rem', width: '24rem' }}
			/>
			<YearInput yarn={searchData.year} onChange={onChange} />
			{groupUrl && (
				<NavLink to={groupUrl}>
					<Button
						label="그래프 보기"
						backgroundColor={palette.grey['primary']}
						primary
						css={{ width: '10rem', padding: '1rem 0', marginRight: '1rem' }}
					/>
				</NavLink>
			)}
			{printRef && (
				<Button
					label="인쇄"
					backgroundColor={palette.grey['primary']}
					primary
					css={{ width: '10rem', padding: '1rem 0' }}
				/>
			)}
			{writeUrl && (
				<NavLink to={writeUrl}>
					<Button
						label="등록하기"
						backgroundColor={palette.grey['primary']}
						primary
						css={{ width: '10rem', padding: '1rem 0', marginRight: '1rem' }}
					/>
				</NavLink>
			)}
		</>
	)
}

export default SiteAndYearUtils
