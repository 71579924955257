import React from 'react';
import {css} from "@emotion/react";
import palette from "styles/palette";

type CheckButtonProps = {
    type: 'red' | 'green'
    onToggle(id: number): void;
    value: number;
    id: number;
}

function CheckButton({onToggle, type, value, id}: CheckButtonProps) {
    return (
        <div css={ContainerStyle}>
            <div css={CircleStyle(type, value === id)} onClick={() => onToggle(id)} id={String(id)} role="button" tabIndex={0}/>
        </div>
    )
}

export default CheckButton

const ContainerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const CircleStyle = (type: 'red' | 'green', isChecked: boolean) => css`
  width: 20px;
  height: 20px;
  border-radius: 100000px;
  
  border: 1px solid ${isChecked ? type === 'red' ? palette.red[100] : palette.green[100] : '#000'};
  
  ${
    isChecked && css`
      background-color: ${type === 'red' ? palette.red[100] : palette.green[100]}; 
    `
  }
`

