import React from 'react'
import MenuStore from 'stores/MenuStore'
import IconText from 'components/iconText'
import { css } from '@emotion/react'
import Paging from 'components/paging'
import { IconType } from 'components/Icon/Icon'

type Props = {
	name?: IconType
	title: string
	tables: JSX.Element[]
	utils?: JSX.Element
	paging?: any | null
	baseUrl?(pageNo: number | null | undefined): string
}
const View: React.FC<Props> = ({ name, title, tables, utils, paging, baseUrl }) => {
	const { getChildBySelected } = MenuStore
	const iconName = getChildBySelected() ? getChildBySelected()?.icon : 'notice'

	return (
		<div css={container}>
			<IconText
				name={name ? name : iconName ? iconName : 'notice'}
				label={title}
				color="#000"
				customCSS={iconTextStyle}
			/>
			{utils && <div css={utilContainerStyle}>{utils}</div>}
			<div css={{ marginTop: !utils ? '1rem' : '0' }}>{tables.map(table => table)}</div>
			{paging && baseUrl && (
				<Paging
					pageNo={Number(paging?.pageNo)}
					baseUrl={baseUrl}
					start={paging?.startNo}
					end={paging?.endNo}
					last={paging?.lastPageNo}
				/>
			)}
		</div>
	)
}

export default View

const container = css`
	height: 100%;
	padding: 4.4rem 2.2rem;
	overflow-y: scroll;
	overflow-x: hidden;
`

const iconTextStyle = css`
	font-size: 2rem;

	svg {
		color: #000;
	}
`

const utilContainerStyle = css`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	margin: 1rem 0;

	& > .group_selector {
		width: 14rem;
		height: auto;
	}
`
