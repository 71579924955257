import NoticeDto from 'dtos/notice/NoticeDto'
import useCustomMutation from 'hooks/useCustomMutation'
import useInput from 'hooks/useInput'
import { UPDATE_NOTICE_QUERY, NOTICE_QUERY } from 'queries/noticeQuery'
import React, { useCallback, useEffect } from 'react'
import { UpdateNotice, UpdateNoticeVariables, Notice, NoticeVariables } from 'types/graphql'
import { deepCopy } from 'utils/common'
import { useLazyQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'

function useUpdateNotice(id: number) {
	const [notice, setNotice] = useInput<NoticeDto | null>(null)
	const [onSave] = useCustomMutation<UpdateNotice, UpdateNoticeVariables>(UPDATE_NOTICE_QUERY)
	const history = useHistory()

	const [getNotice, { data }] = useLazyQuery<Notice, NoticeVariables>(NOTICE_QUERY, {
		variables: { id },
		onCompleted: ({ selectNotice }) => {
			if (selectNotice) {
				setNotice(new NoticeDto(selectNotice))
			}
		},
	})

	useEffect(() => {
		getNotice({ variables: { id } })
	}, [])

	const _onSave = useCallback(() => {
		if (!notice) return null
		const data = notice.getUpdate()
		onSave(
			{ data: { ...data, id } },
			{
				onCompleted: () => {
					history.goBack()
				},
			},
		)
	}, [notice])

	const onChangeInput = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			if (!notice) return null
			const name = e.target.name as 'title' | 'content'
			notice[name] = e.target.value
			setNotice(deepCopy(notice, new NoticeDto()))
		},
		[notice],
	)

	const onChangeContent = useCallback(
		(value: string) => {
			if (!notice) return null
			notice.content = value
			setNotice(deepCopy(notice, new NoticeDto()))
		},
		[notice],
	)

	return {
		onSave: _onSave,
		notice,
		setNotice,
		onChangeInput,
		onChangeContent,
		data: data?.selectNotice,
	}
}

export default useUpdateNotice
