import React from 'react'
import Table from 'components/table'
import { textStyle } from 'components/table/Table'
import { Projects_selectProjectListByRegDt } from 'types/graphql'
import moment from 'moment'

type Props = {
	projects: Projects_selectProjectListByRegDt[] | undefined
	selectProject: Projects_selectProjectListByRegDt | undefined
	onClick(id: number): void
}
const WaterDayTable: React.FC<Props> = ({ projects, onClick, selectProject }) => {
	return (
		<Table>
			<Table.Row bgColor="#E6E8EE">
				<Table.Col flex={3}>
					<span css={textStyle}>분석차수</span>
				</Table.Col>
				<Table.Col flex={10}>
					<span css={textStyle}>시간</span>
				</Table.Col>
				<Table.Col flex={10}>
					<span css={textStyle}>점검자명</span>
				</Table.Col>
				<Table.Col flex={43}>
					<span css={textStyle}>특이사항</span>
				</Table.Col>
			</Table.Row>

			{projects?.map((project, index) => (
				<Table.ButtonRow
					key={project.id + 'list'}
					onClick={() => onClick(project.id)}
					bgColor={selectProject?.id === project.id ? '#FFFCD5' : '#FFF'}
				>
					<Table.Col flex={3}>
						<span css={textStyle}>{index + 1}차</span>
					</Table.Col>
					<Table.Col flex={10}>
						<span css={textStyle}>{moment(project.regDt).format('HH시 mm분')}</span>
					</Table.Col>
					<Table.Col flex={10}>
						<span css={textStyle}>{project.user.name}</span>
					</Table.Col>
					<Table.Col flex={43}>
						<span css={textStyle}>{project.note}</span>
					</Table.Col>
				</Table.ButtonRow>
			))}
		</Table>
	)
}
export default WaterDayTable
