import React, { useReducer, useContext, createContext, Dispatch } from 'react'
import { Me_me } from 'types/graphql'

type State = {
	isLogin: boolean
	user?: Me_me
}

type Action = { type: 'LOGIN' } | { type: 'LOGOUT' } | { type: 'SET_USER'; value: Me_me }

type SampleDispatch = Dispatch<Action>

// Context 만들기
const SampleStateContext = createContext<State | null>(null)
const SampleDispatchContext = createContext<SampleDispatch | null>(null)

// 리듀서
function reducer(state: State, action: Action): State {
	switch (action.type) {
		case 'LOGIN':
			return {
				...state,
				isLogin: true,
			}
		case 'LOGOUT':
			return {
				...state,
				isLogin: false,
				user: undefined,
			}
		case 'SET_USER':
			return {
				...state,
				user: action.value,
			}

		default:
			throw new Error('Unhandled action')
	}
}

export function LoginProvider({ children }: { children: React.ReactNode }) {
	const [state, dispatch] = useReducer(reducer, {
		isLogin: false,
	})

	return (
		<SampleStateContext.Provider value={state}>
			<SampleDispatchContext.Provider value={dispatch}>{children}</SampleDispatchContext.Provider>
		</SampleStateContext.Provider>
	)
}

// state 와 dispatch 를 쉽게 사용하기 위한 커스텀 Hooks
export function useUserState(): State {
	const state = useContext(SampleStateContext)
	if (!state) throw new Error('Cannot find LoginProvider') // 유효하지 않을땐 에러를 발생
	return state
}

export function useUserDispatch() {
	const dispatch = useContext(SampleDispatchContext)
	if (!dispatch) throw new Error('Cannot find LoginProvider') // 유효하지 않을땐 에러를 발생
	return dispatch
}
