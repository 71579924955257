import { Login, LoginVariables } from 'types/graphql'
import { LOGIN_QUERY } from 'queries/userQuery'
import useInput from 'hooks/useInput'
import { useUserDispatch } from 'stores/ContextProvider'
import useCustomMutation from 'hooks/useCustomMutation'
import { useHistory } from 'react-router-dom'
import MenuStore from 'stores/MenuStore'

function useLogin() {
	const dispatch = useUserDispatch()
	const history = useHistory()
	const { setting } = MenuStore
	const [user, setUser, { onChangeInput }] = useInput<{ userId: string; password: string }>({
		userId: '',
		password: '',
	})
	const [signIn] = useCustomMutation<Login, LoginVariables>(LOGIN_QUERY)

	const _onLogin = async () => {
		try {
			const variables = {
				userId: user.userId,
				password: user.password,
			}
			await signIn(variables, {
				onCompleted: () => {
					dispatch({ type: 'LOGIN' })
					history.push('/water/day')
					setting(10001)
				},
				onError: e => {
					console.log(e)
					dispatch({ type: 'LOGOUT' })
				},
			})
		} catch (e) {}
	}
	return {
		onLogin: _onLogin,
		onChangeInput,
		setUser,
		user,
	}
}

export default useLogin
