import { ProjectItem_selectItemList, Projects_selectProjectListByRegDt, Samples_selectSampleList } from 'types/graphql'
import Table from 'components/table'
import { textStyle } from 'components/table/Table'
import React, { useCallback } from 'react'
import TitleTable from 'components/templete/titleTable'
import { css } from '@emotion/react'
import moment from 'moment'
import ChartWithBar from 'components/chart'

type Props = {
	items?: ProjectItem_selectItemList[]
	samples?: Samples_selectSampleList[]
	project?: Projects_selectProjectListByRegDt
}

function WaterDayPrintTable({ project, items, samples }: Props) {
	if (!project || !items || !samples) return null
	const FONT_SIZE = 30

	const getData = useCallback(() => {
		const result = []
		let obj: any = {}
		for (const sample of samples) {
			obj = {}
			for (const item of items) {
				const findData = project.measurements.find(m => m.item.id == item.id && m.sample.name === sample.name)
				if (findData && Number(findData.ppm) > 0) {
					obj[item.name] = Math.floor(Number(findData.ppm) * findData.dilution * 10) / 10
				}
			}
			obj.sampleName = sample.name
			result.push(obj)
		}
		return result
	}, [project, items, samples])

	const renderTableByItem = () => {
		return (
			<>
				<TitleTable
					title={moment(project.regDt).format('YYYY년 MM월 DD일 HH시 mm분')}
					name="inOutComeMng"
					style={style}
					size={24}
					key={project.id + 'dayPrint'}
					customCSS={css`
						font-size: ${FONT_SIZE}px;
					`}
				>
					<Table>
						<Table.Row bgColor="#E6E8EE">
							<Table.Col flex={10}>
								<span css={textStyle} style={{ fontSize: FONT_SIZE }}>
									측정항목
								</span>
							</Table.Col>
							{samples?.map(sample => (
								<Table.Col flex={10} key={sample.id + 'sample'}>
									<span css={textStyle} style={{ fontSize: FONT_SIZE }}>
										{sample.name}
									</span>
								</Table.Col>
							))}
						</Table.Row>
						{items?.map(item => (
							<Table.Row key={item.id + 'data'}>
								<Table.Col flex={10}>
									<span css={textStyle} style={{ fontSize: FONT_SIZE }}>
										{item.name}
									</span>
								</Table.Col>
								{samples?.map(sample => {
									const findProject = project.measurements.find(
										m => m.item.id === item.id && m.sample.name === sample.name,
									)
									return (
										<Table.Col flex={10} key={item.id + sample.id + 'data'}>
											<span css={textStyle} style={{ fontSize: FONT_SIZE }}>
												{findProject
													? Number(findProject.ppm) === -1
														? '초과'
														: Math.floor(findProject.ppm * findProject.dilution * 10) / 10
													: '-'}
											</span>
										</Table.Col>
									)
								})}
							</Table.Row>
						))}
					</Table>
				</TitleTable>

				<div style={{ display: 'flex' }}>
					<TitleTable
						title={items[0].name}
						name="inOutComeMng"
						size={24}
						style={{
							marginTop: '4rem',
							display: 'flex',
							width: '50%',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
						}}
						customCSS={css`
							font-size: ${FONT_SIZE}px;
						`}
					>
						<div css={ContainerStyle}>
							<ChartWithBar
								data={getData()}
								keys={[items[0].name]}
								indexBy="sampleName"
								groupMode="grouped"
								margin={{ top: 50, right: 0, bottom: 50, left: 60 }}
								customStyle={css`
									height: 100%;
									width: 800px !important;
								`}
							/>
						</div>
					</TitleTable>
					<TitleTable
						title={items[1].name}
						name="inOutComeMng"
						size={24}
						style={{
							marginTop: '4rem',
							display: 'flex',
							width: '50%',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
						}}
						customCSS={css`
							font-size: ${FONT_SIZE}px;
						`}
					>
						<div css={ContainerStyle}>
							<ChartWithBar
								data={getData()}
								keys={[items[1].name]}
								indexBy="sampleName"
								groupMode="grouped"
								margin={{ top: 50, right: 0, bottom: 50, left: 60 }}
								customStyle={css`
									height: 100%;
									width: 800px !important;
								`}
							/>
						</div>
					</TitleTable>
				</div>
				<div style={{ display: 'flex' }}>
					<TitleTable
						title={items[2].name}
						name="inOutComeMng"
						size={24}
						style={{
							marginTop: '4rem',
							display: 'flex',
							width: '50%',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
						}}
						customCSS={css`
							font-size: ${FONT_SIZE}px;
						`}
					>
						<div css={ContainerStyle}>
							<ChartWithBar
								data={getData()}
								keys={[items[2].name]}
								indexBy="sampleName"
								groupMode="grouped"
								margin={{ top: 50, right: 0, bottom: 50, left: 60 }}
								customStyle={css`
									height: 100%;
									width: 800px !important;
								`}
							/>
						</div>
					</TitleTable>
					<TitleTable
						title={items[3].name}
						name="inOutComeMng"
						size={24}
						style={{
							marginTop: '4rem',
							display: 'flex',
							width: '50%',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
						}}
						customCSS={css`
							font-size: ${FONT_SIZE}px;
						`}
					>
						<div css={ContainerStyle}>
							<ChartWithBar
								data={getData()}
								keys={[items[3].name]}
								indexBy="sampleName"
								groupMode="grouped"
								margin={{ top: 50, right: 0, bottom: 50, left: 60 }}
								customStyle={css`
									height: 100%;
									width: 800px !important;
								`}
							/>
						</div>
					</TitleTable>
				</div>
				<div style={{ display: 'flex' }}>
					<TitleTable
						title="오르토 인산염 + 알칼리도"
						name="inOutComeMng"
						size={24}
						style={{
							marginTop: '4rem',
							display: 'flex',
							width: '50%',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
						}}
						customCSS={css`
							font-size: ${FONT_SIZE}px;
						`}
					>
						<div css={ContainerStyle}>
							<ChartWithBar
								data={getData()}
								keys={items
									.filter(item => item.name === '알칼리도' || item.name === '오르토 인산염')
									.map(item => item.name)}
								indexBy="sampleName"
								groupMode="grouped"
								margin={{ top: 50, right: 0, bottom: 50, left: 60 }}
								valueScale={{ type: 'symlog' }}
								customStyle={css`
									height: 100%;
									width: 800px !important;
								`}
							/>
						</div>
					</TitleTable>
					<TitleTable
						title="암모니아성 질소 + 질산성 질소"
						name="inOutComeMng"
						size={24}
						style={{
							marginTop: '4rem',
							display: 'flex',
							width: '50%',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
						}}
						customCSS={css`
							font-size: ${FONT_SIZE}px;
						`}
					>
						<div css={ContainerStyle}>
							<ChartWithBar
								data={getData()}
								keys={items
									.filter(item => item.name === '질산성 질소' || item.name === '암모니아성 질소')
									.map(item => item.name)}
								indexBy="sampleName"
								groupMode="grouped"
								margin={{ top: 50, right: 0, bottom: 50, left: 60 }}
								valueScale={{ type: 'symlog' }}
								customStyle={css`
									height: 100%;
									width: 800px !important;
								`}
							/>
						</div>
					</TitleTable>
				</div>
			</>
		)
	}

	return (
		<div className="water_day_print" style={{ padding: 40, paddingTop: 100 }}>
			<div css={HeaderStyle}>
				<h1>{'<일일 보고서>'}</h1>
			</div>
			{renderTableByItem()}
		</div>
	)
}

export default WaterDayPrintTable

const style = css`
	margin-top: 3rem;
	margin-bottom: 3rem;
`

const ContainerStyle = css`
	border: 1px solid #000;
	background-color: #fff;
	padding: 1rem;
	flex: 1;
	height: 410px;
`

const HeaderStyle = css`
	& > h1 {
		font-size: 50px;
		text-align: center;
	}
`
