// @flow
import React from 'react'
import Icon from 'components/Icon'
import { css } from '@emotion/react'
import HeaderMenu from 'components/layout/header/HeaderMenu'
import useLogout from 'hooks/user/useLogout'

function Header() {
	const { logout, state } = useLogout()
	return (
		<header css={style}>
			<div>
				<Icon name="logo" size={120} color="#000" />
			</div>
			<HeaderMenu />
			{state.isLogin && (
				<div css={logoutStyle} role="button" onClick={() => logout({})} tabIndex={0}>
					<Icon name="logout" size={24} color="#000" />
					<p>로그아웃</p>
				</div>
			)}
		</header>
	)
}

export default Header

const style = css`
	display: flex;
	height: 78px;
	background-color: #fff;
	padding: 0 24px 0 0;
	box-shadow: 0 8px 10px #00000016;
	position: relative;

	& > div {
		display: flex;
		width: 218px;
		justify-content: center;
	}
`

const logoutStyle = css`
	margin-left: auto;
	display: flex;
	align-items: center;
	width: auto;

	&:hover {
		cursor: pointer;
	}

	& > p {
		font-size: 16px;
		margin-left: 1rem;
	}
`
