import { gql } from '@apollo/client'

export const LOGIN_QUERY = gql`
	mutation Login($userId: String!, $password: String!) {
		signIn(data: { userId: $userId, password: $password }) {
			id
		}
	}
`

export const LOGOUT_QUERY = gql`
	mutation Logout {
		logout
	}
`

export const SIGN_UP_QUERY = gql`
	mutation SignUp($data: SignUpInput!) {
		signUp(data: $data) {
			id
		}
	}
`

export const USER_QUERY = gql`
	query User($data: SelectUserInput!) {
		selectUser(data: $data) {
			id
			userId
			password
			email
			cellPhone
			name
			memo
			role {
				id
				name
			}
			sites {
				id
				name
				group {
					id
					groupName
				}
			}
		}
	}
`

export const USERS_QUERY = gql`
	query Users($data: SelectUserListInput!) {
		selectUserList(data: $data) {
			users {
				id
				userId
				cellPhone
				email
				regDt
				role {
					name
				}
				sites {
					id
					group {
						id
						groupName
					}
				}
			}

			paging {
				startNo
				lastPageNo
				endNo
				limit
				pageNo
			}
		}
	}
`

export const ME_QUERY = gql`
	query Me {
		me {
			id
			userId
			cellPhone
			email
			name
			sites {
				address
				name
				id
			}
			role {
				id
				name
			}
		}
	}
`

export const UPDATE_USER_QUERY = gql`
	mutation UpdateUser($data: UpdateUserInput!) {
		updateUser(data: $data) {
			data
		}
	}
`
