import React from 'react'
import IconText from 'components/iconText'
import { css } from '@emotion/react'
import SubMenu from 'components/layout/side/SubMenu'
import { NavLink, useLocation } from 'react-router-dom'
import { MenuDataType } from 'configs/menuData'
import MenuStore from 'stores/MenuStore'
import { media } from 'styles/GlobalStyle'

type Props = {
	menu: MenuDataType
}
const SideMenuItem: React.FC<Props> = ({ menu }) => {
	const { pathname } = useLocation()
	const { setting } = MenuStore

	const onToggle = (id: number) => () => {
		setting(id)
	}

	return (
		<li css={sideItemStyle()}>
			<NavLink to={menu.url} css={NavLinkStyle(menu.isSelected || pathname === menu.url)} onClick={onToggle(menu.id)}>
				<IconText
					name={menu.icon}
					label={menu.title}
					size={24}
					style={{ color: '#fff', fontSize: '19px', fontWeight: 'bold' }}
					color="#fff"
					customCSS={CustomStyle}
				/>
			</NavLink>
			{menu.isSelected && menu.child && <SubMenu menus={menu.child} />}
		</li>
	)
}

export default SideMenuItem

const sideItemStyle = () => css`
	display: flex;
	list-style: none;
	flex-direction: column;

	${media(
		'phone-land',
		`
		align-items: center;
		justify-content: center;
	`,
	)}

	${media(
		'phone-port',
		`
		align-items: center;
		justify-content: center;
	`,
	)}
`

const CustomStyle = css`
	display: flex;

	${media(
		'phone-land',
		`
		flex-direction: column;	
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);	
		& > span {
			display: none;
		}
		
		& > svg {margin: 0 !important}	
	`,
	)}

	${media(
		'phone-port',
		`
		flex-direction: column;
		align-items: center;
		justify-content: center;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);	
		& > span {
			display: none;
		}	
		& > svg {margin: 0 !important}
	`,
	)}
`

const NavLinkStyle = (isSelected: boolean) => css`
	position: relative;
	list-style: none;
	display: flex;
	width: 100%;
	padding: 26px 0 26px 36px;
	border-radius: 50px 0 0 50px;
	margin-bottom: 0.2rem;

	${isSelected && 'background-color: black;'}
	&:hover {
		background-color: black;
	}

	${media(
		'phone-land',
		`
		border-radius: 1000px;
		width: 60px;
		height: 60px;
	`,
	)}

	${media(
		'phone-port',
		`
		border-radius: 1000px;
		width: 60px;
		height: 60px;
	`,
	)}
`
