import {
    Facility_selectFacility, Facility_selectFacility_locationCode, Facility_selectFacility_site,
    Facility_selectFacility_titleCode, InsertFacilityInput, UpdateFacilityInput
} from "types/graphql";

export default class FacilityDto {
    amount: number;
    company: string;
    completedDate: any;
    cost: number;
    dateOfReceipt: any;
    detail: string | null;
    id: number | null;
    memo: string | null;
    locationCode: Facility_selectFacility_locationCode
    titleCode: Facility_selectFacility_titleCode
    site: Facility_selectFacility_site

    constructor(facility?: FacilityDto | Facility_selectFacility) {
        this.amount = facility ? Number(facility.amount) : 0;
        this.company = facility && facility.company ? facility.company : "";
        this.completedDate = facility ? facility.completedDate : "";
        this.cost = facility ? Number(facility.cost) : 0;
        this.dateOfReceipt = facility ? facility.dateOfReceipt : "";
        this.detail = facility ? facility.detail : "";
        this.id = facility ? Number(facility.id) : 0;
        this.memo = facility ? facility.memo : "";

        this.locationCode = facility ? facility.locationCode : {id: 0, name: "", __typename: "CodeEntity"};
        this.site = facility ? facility.site : {id: 0, name: "", __typename: "SiteEntity"};
        this.titleCode = facility ? facility.titleCode : {id: 0, name: "", __typename: "CodeEntity"};
    }

    getCreateDto(): InsertFacilityInput {
        return {
            amount: Number(this.amount),
            company: this.company,
            completedDate: this.completedDate,
            cost: Number(this.cost),
            dateOfReceipt: this.dateOfReceipt,
            detail: this.detail,
            memo: this.memo,
            locationCodeId: Number(this.locationCode.id),
            titleCodeId: Number(this.titleCode.id),
            siteId: Number(this.site.id),
        }
    }

    getUpdateDto(): UpdateFacilityInput | null {
        if(!this.id) return null;
        return {
            id: this.id,
            amount: Number(this.amount),
            company: this.company,
            completedDate: this.completedDate,
            cost: Number(this.cost),
            dateOfReceipt: this.dateOfReceipt,
            detail: this.detail,
            memo: this.memo,
            locationCodeId: Number(this.locationCode.id),
            titleCodeId: Number(this.titleCode.id),
            siteId: Number(this.site.id),
        }
    }
}
