import NoticeTable from 'components/notice/NoticeTable'
import View from 'components/templete/view'
import WriteUtils from 'components/utils/WriteUtils'
import useSearch, { getSearchInitData, SearchType } from 'hooks/useSearch'
import { NOTICES_QUERY } from 'queries/noticeQuery'
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Notices, NoticesVariables, SearchKindEnum } from 'types/graphql'
import { getURLParameters } from 'utils/common'
import { useQuery } from '@apollo/client'
import useMe from 'hooks/user/useMe'

function Notice() {
	const { push } = useHistory()
	const { search } = useLocation()
	const { user } = useMe()
	const { getUrlByPageNo } = useSearch('/notice', getSearchInitData(SearchKindEnum.GROUP_NAME))

	const { data, refetch } = useQuery<Notices, NoticesVariables>(NOTICES_QUERY, {
		variables: {
			data: {
				pageNo: 1,
				limit: 10,
			},
		},
	})

	useEffect(() => {
		const result = getURLParameters<SearchType>(search)

		if (!result) return

		refetch({
			data: {
				pageNo: Number(result.pageNo),
				limit: 10,
			},
		}).catch(error => console.log(error))
	}, [search])

	return (
		<View
			title="공지사항"
			tables={[<NoticeTable key="NoticeTable" notices={data?.selectNoticeList.notices} />]}
			utils={<WriteUtils onClick={user?.role.id === 10001 ? () => push('/notice/write') : undefined} />}
			baseUrl={getUrlByPageNo}
			paging={data?.selectNoticeList.paging}
		/>
	)
}

export default Notice
