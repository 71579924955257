import React from 'react'
import ModalContainer from 'components/modal/ModalContainer'
import { css } from '@emotion/react'

type props = {
	path: string
	onClose(): void
}

function ImageModal({ path, onClose }: props) {
	return (
		<ModalContainer>
			<div css={ContainerStyle}>
				<div css={ImageStyle(path)}>
					<button onClick={onClose} type="button" />
				</div>
			</div>
		</ModalContainer>
	)
}

export default ImageModal

const ContainerStyle = css`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	width: 80%;
	height: 80%;

	background-color: rgba(255, 255, 255, 1);
`

const ImageStyle = (path: string) => css`
	background-image: url(${path});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;
	height: 100%;

	display: flex;
	justify-content: flex-end;
	align-items: flex-start;

	& > button {
		position: relative;
		background-color: #000;
		color: #fff;
		width: 30px;
		height: 30px;

		&:hover {
			cursor: pointer;
			transform: scale(1.1);
		}

		&::before {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(45deg);
			content: '';
			width: 18px;
			height: 1px;
			background-color: #fff;
		}

		&:after {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(-45deg);
			content: '';
			width: 18px;
			height: 1px;
			background: #fff;
		}
	}
`
