import React from 'react'
import Table from 'components/table'
import _ from 'lodash'
import {textStyle} from 'components/table/Table'
import {Items_selectItemList, MedicineMonth_selectChemicalHistoryManagement} from 'types/graphql'
import moment from 'moment'
import {css} from "@emotion/react";
import palette from "styles/palette";

type Props = {
    data?: MedicineMonth_selectChemicalHistoryManagement[]
    items?: Items_selectItemList[]
}
const ColDefaultStyle = {
    flexDirection: 'column',
    padding: '0',
    borderRight: '0',
} as React.CSSProperties

const MedicineMonthTable: React.FC<Props> = ({data, items}) => {
    return (
        <table css={TableStyle({})}>
            <thead>
            <tr>
                <th>
                    <TableText>항목 (단위)</TableText>
                </th>
                {
                    _.range(0, 12).map(index => (<th key={index + 1 + "월"}><TableText>{index + 1}월</TableText></th>))
                }
            </tr>
            </thead>
            <tbody>
            {items?.map(item => {
                return (
                    <tr key={item.id}>
                        <td style={{
                            display: "flex",
                            border: 0,
                            borderLeft: `1px solid ${palette.grey['line']}`,
                            borderBottom: `1px solid ${palette.grey['line']}`
                        }}>
                            <td style={{flex: 1, border: 0}}><TableText>{item.name}</TableText></td>
                            <td style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "column",
                                border: 0,
                                borderLeft: `1px solid ${palette.grey['line']}`,
                            }}>
                                <tr style={{
                                    flex: 1,
                                    border: 0,
                                    borderBottom: `1px solid ${palette.grey['line']}`
                                }}>
                                    <TableText>구입량</TableText></tr>
                                <tr style={{flex: 1, border: 0}}>
                                    <TableText>사용량</TableText></tr>
                            </td>
                        </td>
                        {
                            _.range(0, 12).map((index) => {
                                const findData = data?.find(
                                    d => d.item.id === item.id && Number(moment(d.regDt).format('MM')) === index + 1,
                                )
                                return (
                                    <td key={index}>
                                        <tr css={textStyle}><TableText>{findData ? findData.orderAmount : 0}</TableText>
                                        </tr>
                                        <tr css={textStyle}><TableText>{findData ? findData.useAmount : 0}</TableText>
                                        </tr>
                                    </td>
                                )
                            })
                        }
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
}

export default MedicineMonthTable

interface TableTextProps {
    children: React.ReactNode
}

function TableText({children}: TableTextProps) {
    return (
        <div css={TextContainer}>
            <span>{children}</span>
        </div>
    )
}

const TextContainer = css`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  & > span {
    display: block;
    text-align: center;
    font-size: 1.4rem;
    padding: 1.4rem;
  }
`


type TableStyleTypes = {
    headerBgColor?: string
}

const TableStyle = ({headerBgColor = "#E6E8EE"}: TableStyleTypes) => css`
  width: 100%;
  //border: 1px solid #000;
  font-size: 1.4rem;
  text-align: center;
  background-color: #fff;


  thead {
    background-color: ${headerBgColor};
  }

  th, td {
    border: 1px solid ${palette.grey['line']};
  }

  td > tr:not(:last-child) {
    border-bottom: 1px solid ${palette.grey['line']};
  }

  table, td, tr, tbody, thead, span {
    vertical-align: middle;
  }

`
