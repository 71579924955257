import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { ME_QUERY } from 'queries/userQuery'
import Loading from 'components/loading'

type AuthRouteProps = {
	children: React.ReactNode
} & any // TODO: 원래는 타입이 "RouteProps" 이거여야 하는데 빌드할때 에러 때문에 임시 any

function AuthRoute({ children, ...rest }: AuthRouteProps) {
	const { data, loading } = useQuery(ME_QUERY)
	if (loading)
		return (
			<Route {...rest}>
				<Loading />
			</Route>
		)
	return <Route {...rest}>{data?.me ? children : <Redirect to="/login" />}</Route>
}

export default AuthRoute
