import { Items_selectItemList, Samples_selectSampleList, WaterMonth_selectProjectByMonth } from 'types/graphql'
import Table from 'components/table'
import { textStyle } from 'components/table/Table'
import React from 'react'
import moment from 'moment'

type Props = {
	items?: Items_selectItemList[]
	samples?: Samples_selectSampleList[]
	projects: WaterMonth_selectProjectByMonth[][]
}

function WaterPrintTable({ projects, items, samples }: Props) {
	const FONT_SIZE = 30
	const renderTableByItem = (item: Items_selectItemList, index: number) => {
		return (
			<div style={{ height: index === 0 ? '98vh' : (items?.length || 0) - 1 !== index ? '99vh' : '85vh' }}>
				<h1
					style={{
						fontSize: '30px',
						marginTop: '30px',
						marginBottom: '10px',
					}}
				>
					{item.name}
				</h1>
				<Table>
					<Table.Row bgColor="#E6E8EE">
						<Table.Col width="200px !important" padding="0" style={{ maxWidth: '120px' }}>
							<span css={textStyle} style={{ fontSize: FONT_SIZE }}>
								측정일
							</span>
						</Table.Col>
						{samples?.map(sample => {
							return (
								<Table.Col key={sample.id + 'print'}>
									<span css={textStyle} style={{ fontSize: FONT_SIZE }}>
										{sample.name}
									</span>
								</Table.Col>
							)
						})}
					</Table.Row>
					{projects.map(projectList => (
						<Table.Row key={projectList[0].id + 'print'} bgColor="#E6E8EE">
							<Table.Col width="200px !important" padding="0" style={{ maxWidth: '120px' }}>
								<span css={textStyle} style={{ fontSize: FONT_SIZE }}>
									{moment(projectList[0].regDt).format('MM월 DD일')}
								</span>
							</Table.Col>
							<Table.Col padding="0" style={{ flexDirection: 'column', border: '0', flex: '9' }}>
								{projectList.map((project, idx) => {
									const style =
										idx === 0
											? {
													borderBottom: 0,
													borderTop: 0,
													width: '100%',
											  }
											: { width: '100%' }
									return (
										<Table.Row key={project.id + 'print_data'} style={style}>
											{samples?.map(sample => {
												const findData = project.measurements.find(
													m => m.item.id === item.id && m.sample.name === sample.name,
												)
												return (
													<Table.Col key={sample.id + 'print_sample_col'}>
														<span css={textStyle} style={{ fontSize: FONT_SIZE }}>
															{findData
																? Number(findData.ppm) === -1
																	? '초과'
																	: Math.floor(findData.ppm * findData.dilution * 10) / 10
																: '-'}
														</span>
													</Table.Col>
												)
											})}
										</Table.Row>
									)
								})}
							</Table.Col>
						</Table.Row>
					))}
				</Table>
			</div>
		)
	}

	return <div className="water_month_print">{items?.map((item, index) => renderTableByItem(item, index))}</div>
}

export default WaterPrintTable
