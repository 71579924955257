// @flow
import React from 'react'
import { css } from '@emotion/react'
import { media } from 'styles/GlobalStyle'
import { useLocation } from 'react-router-dom'

type MainProps = {
	children: React.ReactNode
}

function Main({ children }: MainProps) {
	const { pathname } = useLocation()
	return (
		<main css={style(pathname === '/login')}>
			<div style={{ height: '100%' }}>{children}</div>
		</main>
	)
}

export default Main

const style = (isLoginPage: boolean) => css`
	${isLoginPage ? 'width: 100vw;' : 'width: calc(100vw - 250px);'}

	${media('phone-land', 'width: calc(100vw - 10rem - 4.4rem);')}
	${media('phone-port', 'width: calc(100vw - 10rem - 4.4rem);')}

	background-color: #f4f4f4;
`
