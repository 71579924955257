import {
    InsertSludgeInput,
    SludgeDate_selectSludgeByDate, SludgeDate_selectSludgeByDate_site,
    UpdateSludgeInput
} from "types/graphql";


export default class SludgeDto implements SludgeDate_selectSludgeByDate {
    __typename: "SludgeEntity";
    generatingFacility: string;
    id: number;
    site: SludgeDate_selectSludgeByDate_site;
    transferCompany: string;
    transferProcessingFacility: string;
    value: number;
    regDt: any;

    constructor(sludge?: SludgeDto | SludgeDate_selectSludgeByDate | null) {
        this.__typename = "SludgeEntity";
        this.generatingFacility = sludge ? sludge.generatingFacility : "";
        this.id = sludge ? sludge.id : 0;
        this.site = sludge ? sludge.site : {id: 0, name: "", __typename: "SiteEntity"};
        this.transferCompany = sludge ? sludge.transferCompany : "";
        this.transferProcessingFacility = sludge ? sludge.transferProcessingFacility : "";
        this.value = sludge ? Number(sludge.value) : 0;
    }

    getCreateDto(): InsertSludgeInput {
        return {
            id: this.id,
            siteId: this.site.id,
            transferCompany: this.transferCompany,
            transferProcessingFacility: this.transferProcessingFacility,
            value: Number(this.value),
            generatingFacility: this.generatingFacility
        }
    }

    getUpdateDto(): UpdateSludgeInput {
        return {
            id: this.id,
            transferCompany: this.transferCompany,
            transferProcessingFacility: this.transferProcessingFacility,
            value: Number(this.value),
            generatingFacility: this.generatingFacility
        }
    }

}
