import React from 'react'
import SideMenuItem from 'components/layout/side/SideMenuItem'
import { css } from '@emotion/react'
import { MenuDataType } from 'configs/menuData'
import { useUserState } from 'stores/ContextProvider'

type SideMenuProps = {
	menus: MenuDataType[] | undefined
}

const SideMenu: React.FC<SideMenuProps> = ({ menus }) => {
	const { user } = useUserState()
	return (
		<ul css={sideMenuStyle}>
			{menus &&
				menus
					.filter(menu => {
						return user ? user.role.id <= menu.role : false
					})
					.map(menu => <SideMenuItem key={menu.id} menu={menu} />)}
		</ul>
	)
}

export default SideMenu

const sideMenuStyle = css`
	padding: 0;
	overflow: hidden;
`
