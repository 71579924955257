// @flow
import React from 'react'
import InputBase from 'components/inputBase/InputBase'
import { css } from '@emotion/react'
import Icon from 'components/Icon'
import { SelectorType, SelectorValueType } from 'types/common'
import CheckBox from 'components/checkBox'
import Button from 'components/button'
import palette from 'styles/palette'
import { font } from 'styles/Theme'

type SelectorProps = {
	className?: string
	options: SelectorType[]
	values: string[]
	onChange?(value: SelectorValueType): void
	disabled?: boolean
	onToggle?(value: SelectorValueType): () => void
	onCompleted?(): void
}

function Selector({ className, options, values, onChange, disabled, onToggle, onCompleted }: SelectorProps) {
	const getLabelByValue = () => {
		const labels = values.reduce((total, value) => {
			const result = options.filter(option => option.value == value)[0]
			return result ? `${total}, ${result.label}` : `${total}`
		}, '')

		return labels.substring(1)
	}

	return (
		<InputBase className={className} disabled={disabled}>
			<div css={block}>
				<div css={selector} role="button" tabIndex={0}>
					<p>{getLabelByValue()}</p>
					<div css={selectorGroup} className="selector-group">
						{options.length > 0 ? (
							options.map(option => {
								const result = !!values.filter((v: string) => v == option.value)[0]
								return (
									<div
										key={option.value}
										css={selectorItem}
										role="button"
										tabIndex={0}
										onClick={() => {
											onToggle && onToggle(option.value)
											onChange && onChange(option.value)
										}}
									>
										{onToggle && <CheckBox value={result} onToggle={onToggle(option.value)} />}

										<p>{option.label}</p>
									</div>
								)
							})
						) : (
							<div css={selectorItem}>
								<p>데이터가 존재하지 않습니다.</p>
							</div>
						)}
						{onCompleted && <Button label="확인" onClick={onCompleted} />}
					</div>
				</div>
				<Icon name="arrow" size={14} color="#838383" />
			</div>
		</InputBase>
	)
}

const block = css`
	flex: 1;
	display: flex;
	position: relative;
	svg {
		position: absolute;
		right: 1rem;
		top: 50%;
		transform: translateY(-50%);
	}
`

const selector = css`
	background: none;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.4rem;
	-webkit-appearance: none;
	-moz-appearance: none;
	border: none;
	color: inherit;
	padding: 0.74rem 1.2rem;
	cursor: pointer;
	text-align: center;
	&:focus {
		outline: none;
	}
	&:disabled {
		opacity: 1;
		color: inherit;
	}
	& > p {
		${font.body3}
		margin-right: 1.2rem;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	&:focus > .selector-group,
	&:active > .selector-group {
		display: flex;
	}
	/* &::focus-visible {
    outline-color: rgb(0, 95, 204);
    outline-offset: 0px;
    outline-style: auto;
    outline-width: 1px;
  } */
`

const selectorGroup = css`
	display: none;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-height: 30rem;
	position: absolute;
	left: 0;
	top: 4rem;
	overflow: scroll;

	background-color: #fff;
	box-shadow: 0 1px 4px #00000026;
	border: 1px solid #d3d3d3;
	z-index: 9999;

	&:focus,
	&:active {
		display: block;
	}
`

const selectorItem = css`
	display: flex;
	align-items: center;
	width: 100%;
	padding: 1rem;

	&:not(:last-child) {
		border-bottom: 1px solid #d3d3d3;
	}

	& > p {
		display: inline-block;
		font-size: 1.4rem;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	& > div {
		margin-right: 1rem;
	}

	&:hover {
		background-color: ${palette.grey[200]};
	}
`

export default Selector
