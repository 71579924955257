import { Global, css } from '@emotion/react'
import React from 'react'
import emotionReset from 'emotion-reset'

export const media = (device: 'phone-port' | 'phone-land' | 'tablet' | 'laptop' | 'desktop' | number, css: string) => {
	if (device === 'phone-port') {
		return `
			@media (max-width: 480px) {
                ${css} 
            } 
        `
	}

	if (device === 'phone-land') {
		return `
			@media (min-width: 481px) and (max-width: 767px) {
                ${css} 
            } 
        `
	}

	if (device === 'tablet') {
		return `
			@media (min-width: 768px) and (max-width: 1024px) {
                ${css} 
            } 
        `
	}

	if (device === 'laptop') {
		return `
			@media (min-width: 1025px) and (max-width: 1280px) {
                ${css}
            } 
        `
	}

	if (device === 'desktop') {
		return `
			@media (min-width: 1281px) {
                ${css}
            } 
        `
	}

	return `
            @media only screen and (max-width: ${device}px) {
                ${css} 
            } 
        `
}
const GlobalStyle = () => <Global styles={css(cssStyle)} />

/**
 * 		${media('phone-port', 'font-size: 50%')}
 ${media('phone-land', 'font-size: 50%')}
 ${media('tablet', 'font-size: 56.25%')}
 ${media('desktop', 'font-size: 75%')}
 */
const cssStyle = css`
	${emotionReset}
	* {
		box-sizing: border-box;
	}
	html {
		font-family: 'Noto Sans KR', sans-serif;
		font-size: 62.5%;
		${media('tablet', 'font-size: 56.25%')}
		${media('desktop', 'font-size: 75%')}
	}
	input,
	button {
		background-color: transparent;
		border: none;
		outline: none;
	}
	a {
		color: #000;
		text-decoration: none;
		outline: none;
	}
	a:hover,
	a:active {
		text-decoration: none;
		color: #000;
	}

	@media print {
		#print {
			display: flex;
		}
	}

	li,
	ul {
		list-style: none;
	}
`

export default GlobalStyle
