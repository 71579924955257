import React, {useCallback, useEffect} from 'react'
import useSearch, {getSearchInitData} from 'hooks/useSearch'
import {SearchKindEnum} from 'types/graphql'
import {SelectorValueType} from 'types/common'
import View from 'components/templete/view'
import MedicineWriteTable from 'components/medicine/MedicineWriteTable'
import SiteAndDayUtils from 'components/utils/SiteAndDayUtils'
import useCreateMedicine from 'hooks/medicine/useCreateMedicine'
import moment from 'moment'

function MedicineWrite() {
    const {searchData, onChangeRegDt, getUrlByPageNo, onChangeSiteId} = useSearch(
        '/medicine/write',
        getSearchInitData(SearchKindEnum.GROUP_NAME),
    )
    const [refetch, {medicines, setMedicine, onSave, onChangeInput}] = useCreateMedicine()

    const _onChangeSelector = useCallback(
        (value: SelectorValueType) => {
            onChangeSiteId(value)
            refetch({variables: {data: {siteId: Number(value), regDt: searchData.regDt}}})
            setMedicine(medicines.map(data => Object.assign(data, {site: {...data.site, id: Number(value)}})))
        },
        [searchData.regDt, medicines],
    )

    const _onChangeRegDt = useCallback(
        (date: Date) => {
            onChangeRegDt(date)
            refetch({variables: {data: {siteId: Number(searchData.siteId), regDt: moment(date).format('YYYY-MM-DD')}}})
        },
        [searchData.siteId],
    )

    useEffect(() => {
        refetch({variables: {data: {siteId: searchData.siteId, regDt: moment(searchData.regDt).format("YYYY-MM-DD")}}})
    } ,[])

    return (
        <View
            title="일일 약품 사용량 등록 "
            tables={[<MedicineWriteTable key="MedicineWriteTable" medicines={medicines}
                                         onChangeInput={onChangeInput}/>]}
            utils={
                <SiteAndDayUtils
                    onChangeSelector={_onChangeSelector}
                    searchData={searchData}
                    onChangeRegDt={_onChangeRegDt}
                    onClick={searchData.regDt === moment().format('YYYY-MM-DD') ? onSave(searchData.siteId) : undefined}
                />
            }
            baseUrl={getUrlByPageNo}
        />
    )
}

export default MedicineWrite
