import NoticeWriteTable from 'components/notice/NoticeWriteTable'
import View from 'components/templete/view'
import WriteUtils from 'components/utils/WriteUtils'
import useCreateNotice from 'hooks/notice/useCreateNotice'
import React from 'react'

function NoticeWrite() {
	const { notice, onSave, onChangeInput, onChangeContent } = useCreateNotice()

	return (
		<View
			title="공지사항"
			tables={[
				<NoticeWriteTable
					key="NoticeWriteTable"
					notice={notice}
					onChangeInput={onChangeInput}
					onChangeContent={onChangeContent}
				/>,
			]}
			utils={<WriteUtils onClick={onSave} />}
		/>
	)
}

export default NoticeWrite
