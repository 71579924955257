import React from 'react'
import Table from 'components/table'
import { Users_selectUserList_users } from 'types/graphql'
import moment from 'moment'
import {textStyle} from "components/table/Table";
type Props = {
	data?: Users_selectUserList_users[]
}
const TableByUser: React.FC<Props> = ({ data }) => {
	return (
		<Table>
			<Table.Row bgColor="#E6E8EE">
				<Table.Col flex={3}>
					<span css={textStyle}>{''}</span>
				</Table.Col>
				<Table.Col flex={20}>
					<span css={textStyle}>등록일</span>
				</Table.Col>
				<Table.Col flex={20}>
					<span css={textStyle}>아이디</span>
				</Table.Col>
				<Table.Col flex={10}>
					<span css={textStyle}>소속</span>
				</Table.Col>
				<Table.Col flex={20}>
					<span css={textStyle}>전화번호</span>
				</Table.Col>
				<Table.Col flex={20}>
					<span css={textStyle}>이메일</span>
				</Table.Col>
				<Table.Col flex={10}>
					<span css={textStyle}>권한</span>
				</Table.Col>
			</Table.Row>
			{data?.map((user, index) => {
				return (
					<Table.LinkRow to={`/user/write/${user.id}`} key={user.id}>
						<Table.Col flex={3}>
							<span css={textStyle}>{index + 1}</span>
						</Table.Col>
						<Table.Col flex={20}>
							<span css={textStyle}>{moment(user.regDt).format('YYYY-MM-DD')}</span>
						</Table.Col>
						<Table.Col flex={20}>
							<span css={textStyle}>{user.userId}</span>
						</Table.Col>
						<Table.Col flex={10}>
							<span css={textStyle}>{user.sites && user.sites[0]?.group?.groupName}</span>
						</Table.Col>
						<Table.Col flex={20}>
							<span css={textStyle}>{user.cellPhone}</span>
						</Table.Col>
						<Table.Col flex={20}>
							<span css={textStyle}>{user.email}</span>
						</Table.Col>
						<Table.Col flex={10}>
							<span css={textStyle}>{user.role.name}</span>
						</Table.Col>
					</Table.LinkRow>
				)
			})}
		</Table>
	)
}




export default TableByUser
