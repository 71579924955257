import React, { useCallback, useState } from 'react'
import {
	ProjectItem_selectItemList,
	ProjectItem_selectSampleList,
	Projects_selectProjectListByRegDt,
} from 'types/graphql'
import { css } from '@emotion/react'
import CheckBoxGroup from 'components/checkBox/CheckBoxGroup'
import TitleTable from 'components/templete/titleTable'
import ChartWithMix from 'components/chart/ChartWithMix'
import { ApexOptions } from 'apexcharts'

type Props = {
	project?: Projects_selectProjectListByRegDt
	items?: ProjectItem_selectItemList[]
	samples?: ProjectItem_selectSampleList[]
	onClick?(id: number): void
}

function WaterGraphTable({ project, items, samples }: Props) {
	if (!items || !samples || !project) return null
	const [keys, setKeys] = useState(items.map(item => ({ id: item.id, name: item.name, isSelected: true })))

	const onToggle = useCallback(
		(id: number) => {
			setKeys(
				keys.map(key => {
					return key.id === id ? { ...key, isSelected: !key.isSelected } : { ...key }
				}),
			)
		},
		[keys],
	)

	const getSeries: () => ApexOptions['series'] = useCallback(() => {
		const result = []
		let obj: { name: string; type: string; data: number[] } = {
			name: '',
			type: '',
			data: [],
		}
		const filterItems = items.filter(item => {
			const findKey = keys.find(key => key.name === item.name)
			return findKey && findKey.isSelected
		})

		for (const item of filterItems) {
			obj = {
				name: '',
				type: '',
				data: [],
			}
			for (const sample of samples) {
				const findData = project.measurements.find(m => m.item.id == item.id && m.sample.name === sample.name)
				if (findData && Number(findData.ppm) > 0) {
					obj.data.push(Math.floor(Number(findData.ppm) * findData.dilution * 10) / 10)
				} else {
					obj.data.push(0)
				}
			}
			obj.type = item.name === '알칼리도' ? 'line' : 'column'
			obj.name = item.name
			result.push(obj)
		}

		return result
	}, [project, items, samples, keys])

	return (
		<TitleTable title="그래프" name="graph" size={24} style={{ marginTop: '4rem' }}>
			<div css={ContainerStyle}>
				<div css={SelectBoxContainerStyle}>
					{keys.map(key => (
						<CheckBoxGroup key={key.id} label={key.name} value={key.isSelected} onToggle={() => onToggle(key.id)} />
					))}
				</div>
				<ChartWithMix
					options={{
						xaxis: {
							categories: samples.map(sample => sample.name),
						},
					}}
					series={getSeries()}
				/>
			</div>
		</TitleTable>
	)
}

export default WaterGraphTable

const ContainerStyle = css`
	border: 1px solid #000;
	background-color: #fff;
	padding: 1rem 1rem 3rem;
	height: 500px;
`

const SelectBoxContainerStyle = css``
