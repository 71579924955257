import { useQuery } from '@apollo/client'
import NoticeReadTable from 'components/notice/NoticeReadTable'
import View from 'components/templete/view'
import WriteUtils from 'components/utils/WriteUtils'
import { DELETE_NOTICE_QUERY, NOTICE_QUERY } from 'queries/noticeQuery'
import React, { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { DeleteNotice, DeleteNoticeVariables, Notice, NoticeVariables } from 'types/graphql'
import useCustomMutation from 'hooks/useCustomMutation'
import useMe from 'hooks/user/useMe'

function NoticeRead() {
	const { id } = useParams<{ id: string }>()
	const { push } = useHistory()
	const { data } = useQuery<Notice, NoticeVariables>(NOTICE_QUERY, { variables: { id: Number(id) } })
	const [_onDelete] = useCustomMutation<DeleteNotice, DeleteNoticeVariables>(DELETE_NOTICE_QUERY)
	const { user } = useMe()

	const onDelete = useCallback(() => {
		_onDelete(
			{ data: { id: Number(id), title: '', content: '' } },
			{
				onCompleted() {
					push('/notice')
				},
			},
		)
	}, [])

	return (
		<View
			title="공지사항"
			tables={[<NoticeReadTable key="NoticeReadTable" notice={data?.selectNotice} />]}
			utils={
				<WriteUtils
					onClick={user?.role.id === 10001 ? () => push('/notice/write/' + id) : undefined}
					writeTitle="수정하기"
					onDelete={user?.role.id === 10001 ? onDelete : undefined}
				/>
			}
		/>
	)
}

export default NoticeRead
