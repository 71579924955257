import React from 'react'
import Table from "components/table";
import {textStyle} from "components/table/Table";
import {Items_selectItemList, MedicineRemains_selectChemicalHistoryRemains} from "types/graphql";

type Props = {
    data?: MedicineRemains_selectChemicalHistoryRemains[] | null
    items?: Items_selectItemList[]
}
const MedicineTable: React.FC<Props> = ({data, items}) => {
    return (
        <Table>
            <Table.Row bgColor="#E6E8EE">
                <Table.Col flex={1}>
                    <span css={textStyle}>항목 (단위)</span>
                </Table.Col>
                <Table.Col flex={1}>
                    <span css={textStyle}>재고량</span>
                </Table.Col>
            </Table.Row>
            {
                items?.map(item => {
                    const findRemains = data?.find(remains => remains.item.id === item.id)

                    return (
                        <Table.Row key={item.id}>
                            <Table.Col flex={1}>
                                <span css={textStyle}>{item.name}</span>
                            </Table.Col>
                            <Table.Col flex={1}>
                                <span css={textStyle}>{findRemains ? findRemains.remains : 0}</span>
                            </Table.Col>
                        </Table.Row>
                    )
                })
            }
        </Table>
    )
}
export default MedicineTable


