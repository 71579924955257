import React from 'react'
import {css} from '@emotion/react'
import Table from 'components/table'
import TitleTable from 'components/templete/titleTable'
import {textStyle} from 'components/table/Table'
import {
    ProjectItem_selectItemList,
    ProjectItem_selectSampleList,
    Projects_selectProjectListByRegDt,
} from 'types/graphql'
import Button from "components/button";
import palette from "styles/palette";

type Props = {
    project: Projects_selectProjectListByRegDt | undefined
    items: ProjectItem_selectItemList[] | undefined
    samples: ProjectItem_selectSampleList[] | undefined
    onDownloadCSV?(): void
}
const WaterDayDetailTable: React.FC<Props> = ({project, items, samples, onDownloadCSV}) => {
    return (
        <TitleTable title="측정 내역" name="inOutComeMng" style={style} size={24} button={<Button label="내보내기"
                                                                                              onClick={onDownloadCSV}
                                                                                              backgroundColor={palette.grey['primary']}
                                                                                              primary
                                                                                              css={{
                                                                                                  width: '10rem',
                                                                                                  padding: '1rem 0',
                                                                                                  marginBottom: '1rem'
                                                                                              }}/>}>
            <Table>
                <Table.Row bgColor="#E6E8EE">
                    <Table.Col flex={10}>
                        <span css={textStyle}>측정항목</span>
                    </Table.Col>
                    {samples?.map(sample => (
                        <Table.Col flex={10} key={sample.id + 'sample'}>
                            <span css={textStyle}>{sample.name}</span>
                        </Table.Col>
                    ))}
                </Table.Row>
                {items?.map(item => (
                    <Table.Row key={item.id + 'data'}>
                        <Table.Col flex={10}>
                            <span css={textStyle}>{item.name}</span>
                        </Table.Col>
                        {samples?.map(sample => {
                            const findProject = project?.measurements.find(
                                m => m.item.id === item.id && m.sample.name === sample.name,
                            )
                            return (
                                <Table.Col flex={10} key={item.id + sample.id + 'data'}>
									<span css={textStyle}>
										{findProject
                                            ? Number(findProject.ppm) === -1
                                                ? '초과'
                                                : Math.floor(findProject.ppm * findProject.dilution * 10) / 10
                                            : '-'}
									</span>
                                </Table.Col>
                            )
                        })}
                    </Table.Row>
                ))}
            </Table>
        </TitleTable>
    )
}
export default WaterDayDetailTable

const style = css`
  margin-top: 3rem;
  margin-bottom: 3rem;
`
