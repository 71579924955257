import React from 'react'
import { css } from '@emotion/react'
import { font, size as Size } from 'styles/Theme'

export type ButtonProps = {
	primary?: boolean
	fit?: boolean
	backgroundColor?: string
	size?: 'small' | 'medium' | 'large'
	label: string
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const Button: React.FC<ButtonProps> = ({
	primary = false,
	fit = false,
	size = 'medium',
	backgroundColor,
	label,
	...props
}) => {
	return (
		<button css={style(size, primary, fit)} style={{ backgroundColor }} {...props}>
			{label}
		</button>
	)
}

const style = (size: 'small' | 'medium' | 'large', isPrimary: boolean, isFit: boolean) => css`
	${font.body4}
	border: 0;
	border-radius: ${Size.radius};
	cursor: pointer;
	display: inline-block;
	line-height: 1;

	${size === 'small' && small}
	${size === 'medium' && medium}
	${size === 'large' && large}
	
	${isPrimary ? primary : secondary}
	
	${isFit && fit}
`
const small = css`
	${font.body5}
	padding: 9px 8px;
`

const medium = css`
	padding: 9px 11px;
`
const large = css`
	${font.body3}
	padding: 12px 24px;
`
const primary = css`
	color: white;
	background-color: #1ea7fd;
`
const secondary = css`
	color: #333;
	background-color: transparent;
	box-shadow: rgba(0, 0, 0, 0.15) 0 0 0 1px inset;
`

const fit = css`
	// TODO 임시
	padding: 9px 60px;
`

export default Button
