import React from 'react'
import {getSearchInitData, SearchType} from 'hooks/useSearch'
import {SelectorType, SelectorValueType} from 'types/common'
import Selector from 'components/selector/Selector'
import Button from 'components/button'
import {NavLink} from 'react-router-dom'
import {SearchKindEnum} from 'types/graphql'
import palette from 'styles/palette'
import MonthInput from 'components/monthInput'
import SiteStore from 'stores/SiteStore'
import ReactToPrint from 'react-to-print'


const onChangeType = getSearchInitData(SearchKindEnum.MANAGER)
type Props = {
    onChangeSelector(value: SelectorValueType): void
    searchData: SearchType
    onNext(): void
    onPrev(): void
    onChange: (type: keyof typeof onChangeType, value: string) => void
    graphUrl?: string
    writeUrl?: string
    printRef?: React.RefObject<HTMLDivElement>
    onDownloadCSV?(): void
}

function SiteAndMonthUtils({
                               onChangeSelector,
                               onChange,
                               onNext,
                               onPrev,
                               searchData,
                               graphUrl,
                               writeUrl,
                               printRef,
                               onDownloadCSV
                           }: Props) {
    return (
        <>
            <Selector
                options={[
                    {label: '휴게소를 선택해 주세요.', value: -1},
                    ...(SiteStore.sites
                        ? SiteStore.sites.map(
                            site =>
                                ({
                                    label: site.name,
                                    value: site.id,
                                } as SelectorType),
                        )
                        : []),
                ]}
                values={[String(searchData.siteId)]}
                onChange={onChangeSelector}
                css={{height: '100%', margin: '0 1rem', width: '24rem'}}
            />
            <MonthInput onNext={onNext} onPrev={onPrev} month={searchData.month} yarn={searchData.year}
                        onChange={onChange}/>
            {graphUrl && (
                <NavLink to={graphUrl}>
                    <Button
                        label="그래프 보기"
                        backgroundColor={palette.grey['primary']}
                        primary
                        css={{width: '10rem', padding: '1rem 0', marginRight: '1rem'}}
                    />
                </NavLink>
            )}
            {printRef && (
                <ReactToPrint
                    trigger={() => (
                        <Button
                            label="인쇄"
                            backgroundColor={palette.grey['primary']}
                            primary
                            css={{width: '10rem', padding: '1rem 0'}}
                        />
                    )}
                    content={() => printRef.current}
                />
            )}
            {writeUrl && (
                <NavLink to={writeUrl}>
                    <Button
                        label="등록하기"
                        backgroundColor={palette.grey['primary']}
                        primary
                        css={{width: '10rem', padding: '1rem 0', marginRight: '1rem'}}
                    />
                </NavLink>
            )}

            {
                onDownloadCSV && (
                    <Button label="내보내기"
                            onClick={onDownloadCSV}
                            backgroundColor={palette.grey['primary']}
                            primary
                            css={{width: '10rem', padding: '1rem 0', marginLeft: '1rem'}}/>
                )
            }
        </>
    )
}

export default SiteAndMonthUtils
