import {
	Diary_selectCodeList_data,
	Diary_selectDiary,
	Diary_selectDiary_diaryItems,
	Diary_selectDiary_site,
	InsertDiaryInput,
} from 'types/graphql'

class DiaryDto implements Diary_selectDiary {
	__typename: 'DiaryEntity'
	id: number
	diaryItems: Diary_selectDiary_diaryItems[]
	site: Diary_selectDiary_site

	constructor(diary?: DiaryDto | Diary_selectDiary) {
		this.__typename = 'DiaryEntity'
		this.id = diary ? diary.id : 0
		this.site = diary ? diary.site : { __typename: 'SiteEntity', id: 0 }
		this.diaryItems = diary ? diary.diaryItems : []
	}

	getCreate(): InsertDiaryInput {
		return {
			id: this.id,
			siteId: this.site.id,
			diaryItems: this.diaryItems.map(({ id, code, value }) => ({ codeId: Number(code.id), value: String(value), id })),
		}
	}

	setDiaryItemsInit(codes: Diary_selectCodeList_data[]) {
		const result: any[] = []
		for (const code of codes) {
			for (const child of code.childCodes) {
				result.push({ __typename: 'DiaryItemEntity', code: child, id: 0, value: '' })
			}
		}
		this.diaryItems = result
	}

	setDiaryItems(codes: Diary_selectCodeList_data[], newDiaryItems: Diary_selectDiary_diaryItems[]) {
		console.log("a")
		const result: any[] = []
		for (const code of codes) {
			for (const child of code.childCodes) {
				const findData = newDiaryItems.find(item => item.code.id === child.id)
				console.log(findData)
				result.push({ __typename: 'DiaryItemEntity', code: child, id: 0, value: findData ? findData.value : '' })
			}
		}
		this.diaryItems = result
	}

	setSiteId(siteId: number) {
		this.site.id = siteId
	}

}

export default DiaryDto
