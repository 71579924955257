import React from 'react'
import Table from "components/table";
import {textStyle} from "components/table/Table";
import {FlowsCount_selectFlowMonthResultWithRegDt} from "types/graphql";

type Props = {
    data?: FlowsCount_selectFlowMonthResultWithRegDt[] | null
}
const FlowCountTable: React.FC<Props> = ({ data }) => {
    return (
        <Table>
            <Table.Row bgColor="#E6E8EE">
                <Table.Col flex={10}>
                    <span css={textStyle}>{""}</span>
                </Table.Col>
                <Table.Col flex={30}>
                    <span css={textStyle}>장소</span>
                </Table.Col>
                <Table.Col flex={30}>
                    <span css={textStyle}>점검자</span>
                </Table.Col>
                <Table.Col flex={30}>
                    <span css={textStyle}>분석 횟수</span>
                </Table.Col>
            </Table.Row>
            {
                data?.map((flow, index) => (
                    <Table.Row key={index+flow.name}>
                        <Table.Col flex={10}>
                            <span css={textStyle}>{index+1}</span>
                        </Table.Col>
                        <Table.Col flex={30}>
                            <span css={textStyle}>{flow.place}</span>
                        </Table.Col>
                        <Table.Col flex={30}>
                            <span css={textStyle}>{flow.name}</span>
                        </Table.Col>
                        <Table.Col flex={30}>
                            <span css={textStyle}>{flow.count}회</span>
                        </Table.Col>
                    </Table.Row>
                ))
            }
        </Table>
    )
}
export default FlowCountTable
