import React, { useRef } from 'react'
import { css } from '@emotion/react'
import IconText from 'components/iconText'
import MenuStore from 'stores/MenuStore'
import palette from 'styles/palette'
import Button from 'components/button'
import ReactToPrint from 'react-to-print'

type Props = {
	data?: []
	title: string
	tables: JSX.Element[]
	onSave?: () => void
	onDelete?(): void
}
const Write: React.FC<Props> = ({ title, tables, onDelete, onSave }) => {
	const { getChildBySelected } = MenuStore
	const printRef = useRef<HTMLDivElement>(null)

	return (
		<div css={container}>
			<div css={headerStyle}>
				<IconText
					name={(getChildBySelected() && getChildBySelected()?.icon) || 'notice'}
					label={title}
					color="#000"
					customCSS={iconTextStyle}
				/>
				<div>
					{onSave && (
						<Button label="저장/확인" backgroundColor={palette.blue['primary']} primary size="large" onClick={onSave} />
					)}
					{onDelete && (
						<Button
							label="삭제"
							backgroundColor={palette.grey['primary']}
							primary
							size="large"
							css={{ marginLeft: '1rem' }}
							onClick={onDelete}
						/>
					)}

					<ReactToPrint
						trigger={() => (
							<Button
								label="인쇄"
								size="large"
								primary
								backgroundColor={palette.blue['primary']}
								css={{ marginLeft: '1rem' }}
							/>
						)}
						content={() => printRef.current}
					/>
				</div>
			</div>
			<div css={contentStyle}>{tables.map(table => table)}</div>
		</div>
	)
}

export default Write

const container = css`
	padding: 4.4rem 7.2rem;
`

const headerStyle = css`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px solid ${palette.blue['primary']};
	padding: 0.8rem 1rem;
`

const contentStyle = css`
	padding: 3rem 8rem;
`

const iconTextStyle = css`
	font-size: 2rem;

	svg {
		color: #000;

		.a {
			fill: currentColor;
		}
	}
`
