// @flow
import React from 'react';
import Table from "components/table";
import {textStyle} from "components/table/Table";
import {
    Codes_selectCodeList_data, Codes_selectCodeList_data_childCodes,
    Sites_selectSiteList_sites
} from "types/graphql";
import {Input} from "components/input";
import DateInput from "components/input/DateInput";
import Selector from "components/selector/Selector";
import FacilityDto from "dtos/facility/FacilityDto";
import {SelectorValueType} from "types/common";
import moment from "moment";

type FacilityRepairWriteTableProps = {
    sites?: Sites_selectSiteList_sites[]
    codes?: Codes_selectCodeList_data[] | null
    facility: FacilityDto
    onChangeInput(e: React.ChangeEvent<HTMLInputElement>): void
    onChangeSelectorByObjectId(type: string): (value: SelectorValueType) => void
    onChangeSelectorByValue(type: string): (value: SelectorValueType) => void
    titleCode?: Codes_selectCodeList_data_childCodes[]
};

function FacilityRepairWriteTable({
                                      sites = [],
                                      codes = [],
                                      facility,
                                      onChangeInput,
                                      onChangeSelectorByObjectId,
                                      onChangeSelectorByValue,
                                      titleCode
                                  }: FacilityRepairWriteTableProps) {
    return (
        <Table>
            <Table.Row>
                <Table.Col flex={1}>
                    <span css={textStyle}>휴게소명</span>
                </Table.Col>
                <Table.Col flex={1} style={{paddingTop: 0, paddingBottom: 0}}>
                    <Selector css={{width: "100%", margin: 0, height: "80%"}}
                              options={sites?.map(sites => ({label: sites.name, value: sites.id}))}
                              values={[String(facility.site.id)]} onChange={onChangeSelectorByObjectId('site')}/>
                </Table.Col>
            </Table.Row>
            <Table.Row>
                <Table.Col flex={1}>
                    <span css={textStyle}>시설물 위치</span>
                </Table.Col>
                <Table.Col flex={1} style={{paddingTop: 0, paddingBottom: 0}}>
                    <Selector css={{width: "100%", margin: 0, height: "80%"}}
                              options={!codes ? [] : codes.map(code => ({label: code.name, value: code.id}))}
                              values={[String(facility.locationCode.id)]}
                              onChange={onChangeSelectorByObjectId('locationCode')}/>
                </Table.Col>
            </Table.Row>
            <Table.Row>
                <Table.Col flex={1}>
                    <span css={textStyle}>시설물 명칭</span>
                </Table.Col>
                <Table.Col flex={1} style={{paddingTop: 0, paddingBottom: 0}}>
                    <Selector css={{width: "100%", margin: 0, height: "80%"}}
                              options={!titleCode ? [] : titleCode.map(code => ({label: code.name, value: code.id}))}
                              values={[String(facility.titleCode.id)]}
                              onChange={onChangeSelectorByObjectId('titleCode')}/>
                </Table.Col>
            </Table.Row>
            <Table.Row>
                <Table.Col flex={1}>
                    <span css={textStyle}>시설물 개수</span>
                </Table.Col>
                <Table.Col flex={1}>
                    <Input value={facility.amount} name="amount" onChange={onChangeInput}/>
                </Table.Col>
            </Table.Row>
            <Table.Row>
                <Table.Col flex={1}>
                    <span css={textStyle}>수리 접수일</span>
                </Table.Col>
                <Table.Col flex={1}>
                    <DateInput css={{width: '100%'}} value={moment(facility.dateOfReceipt).format("YYYY-MM-DD")}
                               onChange={(value: Date) => onChangeSelectorByValue("dateOfReceipt")(moment(value).format("YYYY-MM-DD"))}/>
                </Table.Col>
            </Table.Row>
            <Table.Row>
                <Table.Col flex={1}>
                    <span css={textStyle}>수리 완료일</span>
                </Table.Col>
                <Table.Col flex={1}>
                    <DateInput css={{width: '100%'}} value={moment(facility.completedDate).format("YYYY-MM-DD")}
                               onChange={(value: Date) => onChangeSelectorByValue("completedDate")(moment(value).format("YYYY-MM-DD"))}/>
                </Table.Col>
            </Table.Row>
            <Table.Row>
                <Table.Col flex={1}>
                    <span css={textStyle}>수리 내역</span>
                </Table.Col>
                <Table.Col flex={1}>
                    <Input value={facility.detail || ""} name="detail" onChange={onChangeInput}/>
                </Table.Col>
            </Table.Row>
            <Table.Row>
                <Table.Col flex={1}>
                    <span css={textStyle}>수리 업체</span>
                </Table.Col>
                <Table.Col flex={1}>
                    <Input value={facility.company} name="company" onChange={onChangeInput}/>
                </Table.Col>
            </Table.Row>
            <Table.Row>
                <Table.Col flex={1}>
                    <span css={textStyle}>수리 비용</span>
                </Table.Col>
                <Table.Col flex={1}>
                    <Input value={facility.cost} name="cost" onChange={onChangeInput}/>
                </Table.Col>
            </Table.Row>
            <Table.Row>
                <Table.Col flex={1}>
                    <span css={textStyle}>비고</span>
                </Table.Col>
                <Table.Col flex={1}>
                    <Input value={facility.memo || ""} name="memo" onChange={onChangeInput}/>
                </Table.Col>
            </Table.Row>
        </Table>
    )
};

export default FacilityRepairWriteTable;
