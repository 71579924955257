import { gql } from '@apollo/client'

export const CREATE_DIARY_QUERY = gql`
	mutation CreateDiary($data: InsertDiaryInput!) {
		insertDiary(data: $data) {
			id
		}
	}
`
export const DIARY_QUERY = gql`
	query Diary($data: SelectDiaryInput!) {
		selectDiary(data: $data) {
			id
			site {
				id
			}
			diaryItems {
				id
				code {
					id
					name
				}
				value
			}
		}

		selectCodeList(data: { ids: [11, 12] }) {
			data {
				id
				name
				childCodes {
					id
					name
				}
			}
		}
	}
`
