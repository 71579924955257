import React from 'react'
import { css } from '@emotion/react'

type props = {
	children: React.ReactNode
}

function ModalContainer({ children }: props) {
	return (
		<div css={BackgroundStyle}>
			<div css={ContainerStyle}>{children}</div>
		</div>
	)
}

export default ModalContainer

const BackgroundStyle = css`
	width: 100vw;
	height: 100vh;

	position: fixed;
	top: 0;
	left: 0;

	background-color: rgba(0, 0, 0, 0.4);
	z-index: 3;
`

const ContainerStyle = css`
	width: 100%;
	height: 100%;
	position: relative;
`
