import React from 'react'
import { css } from '@emotion/react'
import background from 'assets/image/login_background/login_custom.png'
import { Input } from 'components/input'
import Button from 'components/button'
import useLogin from 'hooks/user/useLogin'

function Login() {
	const { user, onLogin, onChangeInput } = useLogin()

	return (
		<div css={container}>
			<div css={loginContainer}>
				<form css={loginForm}>
					<h2>로그인</h2>
					<div css={loginRow}>
						<h3>아이디</h3>
						<Input
							value={user.userId}
							name="userId"
							onChange={onChangeInput}
							onKeyDown={({ keyCode }) => keyCode === 13 && onLogin()}
						/>
					</div>
					<div css={loginRow}>
						<h3>비밀번호</h3>
						<Input
							value={user.password}
							name="password"
							onChange={onChangeInput}
							type="password"
							onKeyDown={({ keyCode }) => keyCode === 13 && onLogin()}
						/>
					</div>
					<Button label="로그인" primary css={{ width: '100%' }} onClick={onLogin} type="button" />
				</form>
			</div>
			<div css={imageStyle} />
		</div>
	)
}

export default Login

const imageStyle = css`
	background-image: url(${background});
	background-size: cover;
	background-position: center;
	transform: scale(1.8);
	flex: 1;
	height: 50rem;
`

const container = css`
	background-color: #c6c6c6;
	width: 100vw;
	height: calc(100vh - 78px);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 10rem;
	overflow: hidden;
`

const loginContainer = css`
	flex: 1;
	display: flex;
	justify-content: center;
`

const loginForm = css`
	background-color: #fff;
	box-shadow: 15px 25px 40px #0000001a;
	width: 36rem;
	border-radius: 7px;
	padding: 6rem;
	& > h2 {
		font-size: 1.8rem;
		margin-bottom: 3rem;
	}
`

const loginRow = css`
	margin: 2rem 0;

	& > h3 {
		margin-bottom: 0.8rem;
	}
`
