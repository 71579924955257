import {
	InsertChemicalHistory,
	Medicine_chemicalForDay,
	Medicine_chemicalForDay_item,
	Medicine_chemicalForDay_site,
} from 'types/graphql'

export default class MedicineDto implements Medicine_chemicalForDay {
	__typename: 'ChemicalHistoryEntity'
	id: number
	item: Medicine_chemicalForDay_item
	orderAmount: number
	regDt: any
	site: Medicine_chemicalForDay_site | null
	useAmount: number

	constructor(medicine?: Medicine_chemicalForDay) {
		this.__typename = 'ChemicalHistoryEntity'
		this.id = medicine ? medicine.id : 0
		this.item = medicine ? medicine.item : { id: 0, name: '', __typename: 'ItemEntity', measure: '' }
		this.orderAmount = medicine ? medicine.orderAmount : 0
		this.regDt = medicine ? medicine.regDt : ''
		this.site = medicine ? medicine.site : { id: 0, __typename: 'SiteEntity', name: '' }
		this.useAmount = medicine ? medicine.useAmount : 0
	}

	getCreateDto(siteId: number): InsertChemicalHistory {
		return {
			id: this.id,
			itemId: this.item.id,
			siteId: siteId,
			orderAmount: this.orderAmount,
			useAmount: this.useAmount,
		}
	}
}
