import React from 'react'
import { css } from '@emotion/react'
import Button from 'components/button'
import { NavLink } from 'react-router-dom'
import Icon from 'components/Icon'
import { IconType } from 'components/Icon/Icon'
import _ from 'lodash'

export type LinkButtonProps = {
	isSelected: boolean
	label?: string
	to: string
	name?: IconType
}

function LinkButton({ isSelected, label, to, name }: LinkButtonProps) {
	return (
		<NavLink to={to}>
			{name ? (
				<Icon name={name} className="paging-icon" css={iconStyle} />
			) : (
				<Button
					label={label || ''}
					// backgroundColor={isSelected ? '#323232' : 'none'}
					primary={isSelected}
					css={{
						margin: '1rem .5rem',
						width: '3rem',
						height: '3rem',
						boxShadow: 'none',
						backgroundColor: isSelected ? '#323232' : 'none',
					}}
				/>
			)}
		</NavLink>
	)
}

const iconStyle = css`
	// border: '1px solid #323232',
	border-radius: 6px;
	width: 3rem;
	height: 3rem;
	margin: 1rem 0.5rem;
`

type Props = {
	baseUrl(pageNo: number | null | undefined): string
	pageNo: number
	start?: number | null
	end?: number | null
	last?: number | null
}
const Paging: React.FC<Props> = ({ start, end, baseUrl, pageNo, last }) => {
	const onPrev = () => {
		return pageNo - 1 < 1 ? baseUrl(1) : baseUrl(pageNo - 1)
	}

	const onNext = () => {
		return pageNo === last ? baseUrl(last) : baseUrl(pageNo + 1)
	}

	return (
		<div css={style}>
			<LinkButton isSelected={false} name={'leftAllArrow'} to={baseUrl(1)} />
			<LinkButton isSelected={false} name={'leftArrow'} to={onPrev()} />
			{_.range(start || 0, (end || 0) + 1).map((num: number) => {
				return <LinkButton key={num} isSelected={num === pageNo} to={baseUrl(num)} label={String(num)} />
			})}
			<LinkButton isSelected={false} name={'rightArrow'} to={onNext()} />
			<LinkButton isSelected={false} name={'rightAllArrow'} to={baseUrl(last)} />
		</div>
	)
}

export default Paging

const style = css`
	display: flex;
	justify-content: center;
	align-items: center;
`
