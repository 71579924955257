import React, {useCallback} from 'react'
import useSearch, {getSearchInitData} from "hooks/useSearch";
import {
    SearchKindEnum,
    SludgeDate,
    SludgeDateVariables,
} from "types/graphql";
import {SelectorValueType} from "types/common";
import View from "components/templete/view";
import SludgeWriteTable from "components/sludge/SludgeWriteTable";
import SiteAndDayUtils from "components/utils/SiteAndDayUtils";
import moment from "moment";
import {SLUDGE_DATE_QUERY} from "queries/sludgeQuery";
import useCreateSludge from "hooks/sludge/useCreateSludge";
import SludgeDto from "dtos/sludge/SldugeDto";
import useCustomQuery from "hooks/useCustomQuery";

function SludgeWrite() {
    const {searchData, onChangeRegDt, getUrlByPageNo, onChangeSiteId} = useSearch(
        '/sludge/write',
        getSearchInitData(SearchKindEnum.GROUP_NAME),
    )
    const result = useCustomQuery<SludgeDate, SludgeDateVariables>(SLUDGE_DATE_QUERY, {
        data: {siteId: searchData.siteId, regDt: moment(searchData.regDt).format("YYYY-MM-DD")}
    }, false)
    const {sludge, onSave, setSludge,} = useCreateSludge();


    const _onChangeSelector = useCallback((value: SelectorValueType) => {
        onChangeSiteId(value)
        result.onRefetch({data: {siteId: Number(value), regDt: searchData.regDt}})
            .then(response => {
                if (response?.data.selectSludgeByDate) setSludge(new SludgeDto(response?.data.selectSludgeByDate))
                else setSludge(new SludgeDto({
                    ...new SludgeDto(),
                    site: {id: Number(value), name: "", __typename: "SiteEntity"}
                }))
            })
    }, [searchData.regDt])

    const _onChangeRegDt = useCallback(async (date: Date) => {
        onChangeRegDt(date)
        result.onRefetch({
            data: {
                siteId: Number(searchData.siteId),
                regDt: moment(date).format("YYYY-MM-DD")
            }
        })
            .then((response) => {
                if(response?.data.selectSludgeByDate) setSludge(new SludgeDto(response?.data.selectSludgeByDate))
                else setSludge(new SludgeDto({...new SludgeDto()}))
            })

    }, [searchData.siteId])

    const _onChangeInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSludge(new SludgeDto({...sludge, [e.target.name]: e.target.value, site: {id: searchData.siteId, name: "", __typename: "SiteEntity"} }))
    }, [searchData.siteId, sludge])

    return (
        <View
            title="일일 슬러지 처리량 등록"
            tables={[<SludgeWriteTable key="SludgeWriteTable" onChangeInput={_onChangeInput} sludge={sludge}/>]}
            utils={
                <SiteAndDayUtils
                    onClick={moment().format("YYYY-MM-DD") === searchData.regDt ? onSave : undefined}
                    onChangeSelector={_onChangeSelector}
                    onChangeRegDt={_onChangeRegDt}
                    searchData={searchData}
                />
            }
            baseUrl={getUrlByPageNo}
        />
    )
}

export default SludgeWrite
