import React from 'react'
import Write from 'components/templete/write'
import UserInputTable from 'components/user/UserInputTable'
import { useParams } from 'react-router-dom'
import { UserParamsType } from 'types/user'
import useCreateUser from 'hooks/user/useCreateUser'
import useUpdateUser from 'hooks/user/useUpdateUser'
import { useQuery } from '@apollo/client'
import { GroupsArea, GroupsAreaVariables } from 'types/graphql'
import { GROUPS_AREA_QUERY } from 'queries/groupQuery'
import Constants from 'configs/constants'
import UserSiteInputTable from 'components/user/UserSiteInputTable'
import { isNumber } from 'utils/common'

const UserWrite: React.FC = ({}) => {
	const { userId } = useParams<UserParamsType>()
	const { onSave, onChangeInput, user, onChangeSelectorByValue, onChangeSelectorBySiteId } = userId
		? useUpdateUser()
		: useCreateUser()

	const _onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.name === 'cellPhone' && !isNumber(e.target.value)) return
		onChangeInput(e)
	}

	const { data } = useQuery<GroupsArea, GroupsAreaVariables>(GROUPS_AREA_QUERY, {
		variables: { data: { limit: 999999 }, ids: [Constants.code.ROLE_CODE] },
	})

	return (
		<Write
			title="계정 등록"
			onSave={onSave}
			tables={[
				<UserInputTable
					key={'userInputTable'}
					onChangeInput={_onChangeInput}
					data={user}
					onChangeSelectorByValue={onChangeSelectorByValue}
					roles={data?.selectCodeList.data}
				/>,
				<UserSiteInputTable
					key={'userSiteInputTable'}
					data={user}
					onChangeSelectorByValue={onChangeSelectorByValue}
					groups={data?.selectGroupList.groups}
					onChangeSelectorBySiteId={onChangeSelectorBySiteId}
				/>,
			]}
		/>
	)
}
export default UserWrite
