import useCustomMutation from "hooks/useCustomMutation";
import {CreateSludge, CreateSludgeVariables} from "types/graphql";
import {CREATE_SLUDGE_QUERY} from "queries/sludgeQuery";
import useInput from "hooks/useInput";
import SludgeDto from "dtos/sludge/SldugeDto";
import {useCallback} from "react";


function useCreateSludge() {
    const [sludge, setSludge, events] = useInput<SludgeDto>(new SludgeDto())
    const [onSave, {}] = useCustomMutation<CreateSludge, CreateSludgeVariables>(CREATE_SLUDGE_QUERY);

    const _onSave = useCallback(() => {
        const data = sludge.getCreateDto();
        onSave({data})
    }, [sludge])

    return {
        sludge, onSave: _onSave, ...events, setSludge
    }
}


export default useCreateSludge;
