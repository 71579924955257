import { css } from '@emotion/react'
import React, { useRef } from 'react'
import InputBase from 'components/inputBase/InputBase'
import palette from 'styles/palette'
import Icon from 'components/Icon'
import { IconType } from 'components/Icon/Icon'
import { font } from 'styles/Theme'
import ReactDatePicker from 'react-datepicker'
import 'styles/datapicker.css'

export type InputProps = {
	prefix?: string
	name?: IconType
	disabled?: boolean
} & any

function DateInput({ prefix, className, disabled, name, ...rest }: InputProps) {
	const innerRef = useRef<HTMLInputElement>(null)

	return (
		<InputBase
			css={wrapper(disabled, name)}
			onClick={() => innerRef.current?.focus()}
			className={className}
			disabled={disabled}
		>
			{prefix !== undefined && <span>{prefix}</span>}
			{name && <Icon name={name} />}
			<ReactDatePicker css={style} {...rest} maxDate={new Date()} />
		</InputBase>
	)
}

const wrapper = (disabled?: boolean, name?: IconType) => css`
	padding: 1.6rem;
	display: flex;
	align-items: center;
	cursor: text;
	position: relative;
	span {
		color: ${palette.blueGrey[300]};
		margin-right: 0.5rem;
	}
	${disabled &&
	css`
		span {
			color: ${palette.blueGrey[200]};
		}
		cursor: not-allowed;
	`}

	${name &&
	css`
		padding-left: 3.2rem;
	`}

	& > svg {
		position: absolute;
		top: 50%;
		left: 1rem;
		transform: translateY(-50%);

		width: 1.6rem;
	}

	& .react-datepicker-wrapper {
		width: 100%;
	}

	& .react-datepicker__input-container input {
		width: 100%;
	}
`

const style = css`
	${font.body3}
	flex: 1;
	border: none;
	color: inherit;
	background: none;
	outline: none;
	padding: 0;
	text-align: center;
	//font-size: inherit;
	&::placeholder {
		color: ${palette.blueGrey[200]};
	}
	&:disabled {
		cursor: not-allowed;
		color: inherit;
	}
`

export default DateInput
