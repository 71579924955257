const Constants = {
	message: {
		NONE_SITE_ID: '분석장소를 확인해 주세요.',
		NONE_FROM_DATE: '시작 일자를 확인해 주세요.',
		NONE_TO_DATE: '종료 일자를 확인해 주세요.',
		NONE_FLOW_ID: '처리공정을 선택해 주세요.',
		NONE_DATE: '조회 일자를 확인해 주세요.',
	},
	size: {
		sideMenuWidth: '25rem',
	},
	/**
	 * @설명 공통 코드
	 */
	code: {
		/**
		 * @설명 근무자 현황
		 */
		WORKER_STATUS_CODE: 11,
		WORKER_STATUS: {},
		/**
		 * @설명 전력량 현황
		 */
		ELECTRICITY_STATUS_CODE: 12,
		ELECTRICITY_STATUS: {},
		/**
		 * @설명 유입부
		 */
		INLET_CODE: 2,
		INLET: {},
		/**
		 * @설명 유량조정조
		 */
		FLOW_CONTROL_TANK_CODE: 3,
		FLOW_CONTROL_TANK: {},
		/**
		 * @설명 혐기/무산소
		 */
		ANAEROBIC_ANOXIC_TANK_CODE: 4,
		ANAEROBIC_ANOXIC_TANK: {},
		/**
		 * @설명 포기조
		 */
		ABANDONMENT_TANK_CODE: 5,
		ABANDONMENT_TANK: {},
		/**
		 * @설명 침전조
		 */
		VETERANS_CODE: 13,
		VETERANS: {},
		/**
		 * @설명 응집시설
		 */
		FLOCCULATION_FACILITY_CODE: 14,
		FLOCCULATION_FACILITY: {},
		/**
		 * @설명 방류조
		 */
		DISCHARGE_TANK_CODE: 8,
		DISCHARGE_TANK: {},
		/**
		 * @설명 기타시설
		 */
		OTHER_FACILITIES_CODE: 10,
		OTHER_FACILITIES: {},
		/**
		 * @설명 전기시설
		 */
		ELECTRIC_FACILITY_CODE: 9,
		ELECTRIC_FACILITY: {},

		AREA_CODE: 15,
		AREA: {
			FIRST: 150001,
		},

		ROLE_CODE: 1,
	},

	orderState: {
		CANCEL: '취소',
		COMPLETE: '배송완료',
		HOLD: '대기',
		ORDER: '주문',
		RECEIPT: 'RECEIPT',
	},

	validation: {
		CELL_PHONE_LENGTH: 11,
	},

	month: ['01월', '02월', '03월', '04월', '05월', '06월', '07월', '08월', '09월', '10월', '11월', '12월'],
	day: ['일', '월', '화', '수', '목', '금', '토'],
}

export default Constants
