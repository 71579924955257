import React from 'react'
import Table from 'components/table'
import TitleTable from 'components/templete/titleTable'
import { textStyle } from 'components/table/Table'
import { DailyOperation_chemicalForDay, DailyOperation_selectChemicalHistoryRemains } from 'types/graphql'

type Props = {
	items?: DailyOperation_chemicalForDay[]
	remains?: DailyOperation_selectChemicalHistoryRemains[] | null
}

const OperationDayTable3: React.FC<Props> = ({ items, remains }) => {
	return (
		<TitleTable title="약품 사용량" name="medicineMng" style={{ marginBottom: '2.4rem' }}>
			<Table>
				<Table.Row bgColor="#E6E8EE">
					<Table.Col flex={25}>
						<span css={textStyle}>항목 (단위)</span>
					</Table.Col>
					<Table.Col flex={25}>
						<span css={textStyle}>구입량</span>
					</Table.Col>
					<Table.Col flex={25}>
						<span css={textStyle}>사용량</span>
					</Table.Col>
					<Table.Col flex={25}>
						<span css={textStyle}>잔량</span>
					</Table.Col>
				</Table.Row>
				{items?.map(data => {
					const findRemains = remains?.find(remain => remain.item.id === data.item.id)
					return (
						<Table.Row key={data.id + 'item'}>
							<Table.Col flex={25}>
								<span css={textStyle}>{data.item.name}</span>
							</Table.Col>
							<Table.Col flex={25}>
								<span css={textStyle}>{data.orderAmount}</span>
							</Table.Col>
							<Table.Col flex={25}>
								<span css={textStyle}>{data.useAmount}</span>
							</Table.Col>
							<Table.Col flex={25}>
								<span css={textStyle}>{findRemains?.remains}</span>
							</Table.Col>
						</Table.Row>
					)
				})}
			</Table>
		</TitleTable>
	)
}
export default OperationDayTable3
