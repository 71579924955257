import { gql } from '@apollo/client'

export const FLOW_MEASURE_QUERY = gql`
	query FlowMeasure($siteId: Float!, $regDt: String!) {
		selectFlowList {
			id
			name
			ord
		}

		selectInfluentFlowRecently(data: { siteId: $siteId, regDt: $regDt }) {
			id
			regDt
			influentFlowItems {
				result
				flow {
					id
				}
			}
		}

		selectInfluentFlow(data: { siteId: $siteId, regDt: $regDt }) {
			id
			regDt
			inspector
			site {
				id
				name
			}
			influentFlowItems {
				result
				flow {
					id
				}
			}
		}

		selectTotalToRegDt(data: { siteId: $siteId, regDt: $regDt }) {
			month {
				id
				result
			}
			year {
				id
				result
			}
		}
	}
`

export const FLOWS_QUERY = gql`
	query Flows($data: SelectInfluentFlowListInput!) {
		selectInfluentFlowList(data: $data) {
			id
			regDt
			influentFlowItems {
				flow {
					id
					name
				}
				result
			}
		}

		selectFlowList {
			id
			name
			ord
		}
	}
`

export const FLOWS_COUNT_QUERY = gql`
	query FlowsCount($data: SelectManagementInput!) {
		selectFlowMonthResultWithRegDt(data: $data) {
			count
			name
			place
			regDt
		}
	}
`

export const CREATE_FLOW_QUERY = gql`
	mutation CreateFlow($data: InsertInfluentFlowInput!) {
		insertInfluentFlow(data: $data) {
			id
		}
	}
`
