import React from 'react'
import { css } from '@emotion/react'
import { NavLink } from 'react-router-dom'
import palette from 'styles/palette'
import { color } from 'styles/Theme'

export type TableProps = {
	children: React.ReactNode
	isScroll?: boolean
	style?: any
}

function Table({ children, isScroll, style }: TableProps) {
	return <div css={{ ...tableStyle(isScroll), style }}>{children}</div>
}

export type RowProps = {
	children: React.ReactNode
	bgColor?: string
	width?: string
	style?: React.CSSProperties
}

function Row({ children, bgColor, width, style }: RowProps) {
	return (
		<div css={rowStyle(bgColor, width)} style={style}>
			{children}
		</div>
	)
}

export type ColProps = {
	children: React.ReactNode
	flex?: number
	padding?: string
	bgColor?: string
	width?: string
	fontColor?: string
	style?: React.CSSProperties
}

function Col({ children, bgColor, flex, padding, width, fontColor, style }: ColProps) {
	return (
		<div css={[colStyle(flex, bgColor, width, padding, fontColor)]} style={style}>
			{children}
		</div>
	)
}

export type LinkRowProps = {
	children: React.ReactNode
	bgColor?: string
	isLight?: boolean
	to: string
	width?: string
}

function LinkRow({ children, bgColor, isLight, to, width }: LinkRowProps) {
	return (
		<NavLink to={to} css={linkRowStyle(bgColor, isLight, width)}>
			{children}
		</NavLink>
	)
}

export type ButtonRowProps = {
	children: React.ReactNode
	bgColor?: string
	isLight?: boolean
	onClick(): void
	width?: string
}

function ButtonRow({ children, bgColor, isLight, onClick, width }: ButtonRowProps) {
	return (
		<div css={linkRowStyle(bgColor, isLight, width)} onClick={onClick} role="button" tabIndex={0}>
			{children}
		</div>
	)
}

Table.Row = Row
Table.Col = Col
Table.LinkRow = LinkRow
Table.ButtonRow = ButtonRow

export default Table

const tableStyle = (isScroll?: boolean) => css`
	display: flex;
	flex-direction: column;
	width: 100%;
	font-size: 1.4rem;
	border: 1px solid #000;
	${isScroll && 'overflow-x: scroll'};
`

const colStyle = (
	flex?: string | number,
	bgColor?: string,
	width?: string,
	padding?: string,
	fontColor?: string,
) => css`
	display: flex;
	border-left: 1px solid ${palette.grey['line']};
	justify-content: center;
	align-items: center;
	flex: ${flex || '1'};
	min-height: 4.6rem;
	padding: ${padding || '0.8rem'};
	${bgColor && 'background-color: ' + bgColor};
	${width && 'min-width: ' + width};
	word-break: break-all;
	&:last-child {
		border-right: 1px solid ${palette.grey['line']};
	}
	${fontColor && 'color: ' + fontColor}
`

const rowStyle = (bgColor?: string, width?: string) => css`
	display: flex;
	flex-direction: row;
	${width && 'width: ' + width};
	border-top: 1px solid ${palette.grey['line']};
	background-color: ${bgColor ? bgColor : '#fff'};
	&:last-child {
		border-bottom: 1px solid ${palette.grey['line']};
	}
`

const linkRowStyle = (bgColor?: string, isLight?: boolean, width?: string) => css`
	display: flex;
	flex-direction: row;
	border-top: 1px solid ${palette.grey['line']};
	background-color: ${bgColor ? (isLight ? color.danger : bgColor) : isLight ? color.danger : '#fff'};
	${width && 'width: ' + width};
	&:last-child {
		border-bottom: 1px solid ${palette.grey['line']};
	}

	&:not(:first-child) {
		&:hover {
			background-color: #fafafa;
			cursor: pointer;
		}
	}
`

export const textStyle = css`
	display: block;
	text-align: center;
	font-size: 1.4rem;
`
