const color = {
	white: '#FFFFFF',
	black: '#434343',
	primary: '#6DC1D5',
	danger: '#f44336',
	success: '#48ad6e',
	warning: '#c59d36',
	secondary: '#CDCDD2', // gray
	lightGray: '#F5F5F6',
	lightGray2: '#F6F6F7',
	lightGray3: '#EFEFF1',
	lightGray4: '#F8F8F9',
	transparent: 'transparent',
	darkGray: '#898C95',
	bg: '#E3E7E9',
	line: '#878787',
}

const size = {
	base: '0.8rem',
	font: '1.4rem',
	radius: '6px',
	padding: '10px',
	padding2: '12px',

	// font sizes
	largeTitle: '52px',
	h1: '32px',
	h2: '24px',
	h3: '22px',
	h4: '17px',
	body1: '32px',
	body2: '22px',
	body3: '18px',
	body4: '16px',
	body5: '14px',
}

const font = {
	largeTitle: `font-family: 'NotoSansKR-Regular'; font-size: ${size.largeTitle}; line-height: 55px; `,
	h1: `font-family: 'NotoSansKR-Black'; font-size: ${size.h1}; line-height: 36px;`,
	h2: `font-family: 'NotoSansKR-Bold'; font-size: ${size.h2}; line-height: 30px; `,
	h3: `font-family: 'NotoSansKR-Bold'; font-size: ${size.h3}; line-height: 22px; `,
	h4: `font-family: 'NotoSansKR-Bold'; font-size: ${size.h4}; line-height: 22px; `,
	body1: `font-family: 'NotoSansKR-Regular'; font-size: ${size.body1}; line-height: 36px; `,
	body2: `font-family: 'NotoSansKR-Regular'; font-size: ${size.body2}; line-height: 30px; `,
	body3: `font-family: 'NotoSansKR-Regular'; font-size: ${size.body3}; line-height: 22px; `,
	body4: `font-family: 'NotoSansKR-Regular'; font-size: ${size.body4}; line-height: 20px; `,
	body5: `font-family: 'NotoSansKR-Regular'; font-size: ${size.body5}; line-height: 16px; `,
}

export { font, color, size }
