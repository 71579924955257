import ReactApexChart from 'react-apexcharts'
import React from 'react'
import { ApexOptions } from 'apexcharts'

interface ChartWithMixProps {
	options?: ApexOptions
	series: ApexOptions['series']
}

function ChartWithMix({ options, series }: ChartWithMixProps) {
	return <ReactApexChart options={{ ...defaultOptions, ...options }} series={series} type="line" height="100%" />
}

export default ChartWithMix

const defaultOptions: ApexOptions = {
	legend: {
		horizontalAlign: 'left',
		offsetX: 40,
	},
}
