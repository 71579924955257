import { InsertNoticeInput, Notice_selectNotice, UpdateNoticeInput } from 'types/graphql'

export default class NoticeDto implements Notice_selectNotice {
	__typename: 'NoticeEntity'
	id: number
	title: string
	content: string
	regDt: string

	constructor(notice?: NoticeDto | Notice_selectNotice) {
		this.__typename = 'NoticeEntity'
		this.id = notice ? notice.id : 0
		this.title = notice ? notice.title : ''
		this.content = notice ? notice.content : ''
		this.regDt = notice ? notice.regDt : ''
	}

	getCreate(): InsertNoticeInput {
		return {
			title: this.title,
			content: this.content,
		}
	}

	getUpdate(): UpdateNoticeInput {
		return {
			id: Number(this.id),
			title: this.title,
			content: this.content,
		}
	}
}
