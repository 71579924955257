import React, { useCallback, useEffect, useState } from 'react'
import useSearch, { getSearchInitData } from 'hooks/useSearch'
import { FlowMeasure, FlowMeasureVariables, SearchKindEnum } from 'types/graphql'
import { SelectorValueType } from 'types/common'
import View from 'components/templete/view'
import FlowDayTable from 'components/flow/FlowDayTable'
import SiteAndDayUtils from 'components/utils/SiteAndDayUtils'
import { FLOW_MEASURE_QUERY } from 'queries/flowQuery'
import moment from 'moment'
import useCreateFlow from 'hooks/flow/useCreateFlow'
import { useLazyQuery } from '@apollo/client'
import InfluentFlowDto from 'dtos/flow/InfluentFlowDto'
import { useUserState } from 'stores/ContextProvider'

function FlowDay() {
	const state = useUserState()
	const [isInstalling, setIsInstalling] = useState(false)
	const { searchData, onChangeSiteId, onChangeRegDt } = useSearch(
		'/flow/day',
		getSearchInitData(SearchKindEnum.GROUP_NAME),
	)
	const { influentFlow, setInfluentFlow, onChangeResult, onSave, onChangeSite } = useCreateFlow()
	const [refetch, { data }] = useLazyQuery<FlowMeasure, FlowMeasureVariables>(FLOW_MEASURE_QUERY, {
		variables: {
			siteId: searchData.siteId,
			regDt: searchData.regDt,
		},
		onCompleted: data => {
			const result = new InfluentFlowDto(data)
			state.user && result.setUser(state.user)
			setInfluentFlow(result)
			setIsInstalling(false)
		},
		onError: error => console.log(error),
	})

	const _onChangeSelector = useCallback(
		(value: SelectorValueType) => {
			onChangeSiteId(value)
			onChangeSite(value)
			refetch({ variables: { siteId: Number(value), regDt: searchData.regDt } })
		},
		[searchData.regDt],
	)

	const _onChangeRegDt = useCallback(
		(date: Date) => {
			onChangeRegDt(date)
			refetch({ variables: { siteId: searchData.siteId, regDt: moment(date).format('YYYY-MM-DD') } })
		},
		[searchData.siteId],
	)

	const _onSave = useCallback(() => {
		if (!(moment().format('YYYY-MM-DD') === searchData.regDt && !isInstalling)) return undefined
		return onSave(searchData.siteId, async () => {
			setIsInstalling(true)
			await refetch({ variables: { siteId: searchData.siteId, regDt: searchData.regDt } })
		})
	}, [searchData, isInstalling, influentFlow])

	useEffect(() => {
		refetch({ variables: { siteId: searchData.siteId, regDt: searchData.regDt } })
	}, [searchData])

	return (
		<View
			title="일일 유량측정"
			tables={[
				<FlowDayTable
					key="FlowDayTable"
					influentFlow={influentFlow}
					flows={data?.selectFlowList}
					onChangeResult={onChangeResult}
					isNotToday={moment().format('YYYY-MM-DD') !== searchData.regDt}
				/>,
			]}
			utils={
				<SiteAndDayUtils
					onChangeSelector={_onChangeSelector}
					searchData={searchData}
					onChangeRegDt={_onChangeRegDt}
					onClick={_onSave()}
				/>
			}
		/>
	)
}

export default FlowDay
