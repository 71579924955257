import React from 'react'
import { css } from '@emotion/react'
import palette from 'styles/palette'
import Icon from 'components/Icon'

export type CheckBoxProps = { value: boolean; onToggle(): void }

function CheckBox({ value, onToggle }: CheckBoxProps) {
	return (
		<div
			role="button"
			css={style(value)}
			tabIndex={0}
			onClick={onToggle}
			onKeyDown={e => {
				if ([' ', 'Enter'].includes(e.key)) {
					onToggle()
				}
			}}
		>
			{value && <Icon name="checked" />}
		</div>
	)
}

const style = (value: boolean) => css`
	border: 1px solid #cecece;
	border-radius: 0.5rem;
	background: ${value ? '#1473E6' : '#fff'};
	height: 2rem;
	width: 2rem;
	align-items: center;
	justify-content: center;
	display: flex;
	cursor: pointer;
	position: relative;
	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 1rem;
		height: 1rem;
		color: #fff;
	}
	outline: none;
	&:focus-visible {
		border: ${palette.cyan[400]} 1px solid;
	}
`

export default CheckBox
