import { css } from '@emotion/react'
import { Input } from 'components/input'
import Table from 'components/table'
import { textStyle } from 'components/table/Table'
import NoticeDto from 'dtos/notice/NoticeDto'
import React from 'react'
import palette from 'styles/palette'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

type Props = {
	notice: NoticeDto
	onChangeInput(e: React.ChangeEvent): void
	onChangeContent(value: string): void
}

function NoticeWriteTable({ notice, onChangeInput, onChangeContent }: Props) {
	return (
		<Table>
			<Table.Row>
				<Table.Col flex={20} bgColor={palette.tableHeadColor}>
					<span css={textStyle}>제목</span>
				</Table.Col>
				<Table.Col flex={80}>
					<Input onChange={onChangeInput} value={notice.title} name="title" />
				</Table.Col>
			</Table.Row>
			<Table.Row>
				<Table.Col flex={20} bgColor={palette.tableHeadColor}>
					<span css={textStyle}>내용</span>
				</Table.Col>
				<Table.Col flex={80}>
					<ReactQuill css={TextAreaStyle} onChange={content => onChangeContent(content)} value={notice.content} />
				</Table.Col>
			</Table.Row>
		</Table>
	)
}

export default NoticeWriteTable

const TextAreaStyle = css`
	width: 100%;
	min-height: 400px;
	font-size: 16px;
`
