// @flow
import React from 'react';
import Table from "components/table";
import {textStyle} from "components/table/Table";
import { SludgeYear_selectSludgeByYear } from 'types/graphql';
import moment from "moment";

type Props = {
    data?: SludgeYear_selectSludgeByYear[] | null
};

function SludgeYearTable({data}: Props) {
    return (
        <Table>
            <Table.Row bgColor="#E6E8EE">
                <Table.Col flex={15}>
                    <span css={textStyle}>날짜</span>
                </Table.Col>
                <Table.Col flex={15}>
                    <span css={textStyle}>총처리량</span>
                </Table.Col>
                <Table.Col flex={15}>
                    <span css={textStyle}>발생시설</span>
                </Table.Col>
                <Table.Col flex={20}>
                    <span css={textStyle}>이송처리시설</span>
                </Table.Col>
                <Table.Col flex={15}>
                    <span css={textStyle}>이송업체</span>
                </Table.Col>
            </Table.Row>
            {
                data?.map(sludge => (
                    <Table.Row key={sludge.id}>
                        <Table.Col flex={15}>
                            <span css={textStyle}>{moment(sludge.regDt).format("YYYY-MM")}</span>
                        </Table.Col>
                        <Table.Col flex={15}>
                            <span css={textStyle}>{sludge.value}</span>
                        </Table.Col>
                        <Table.Col flex={15}>
                            <span css={textStyle}>{sludge.generatingFacility}</span>
                        </Table.Col>
                        <Table.Col flex={20}>
                            <span css={textStyle}>{sludge.transferProcessingFacility}</span>
                        </Table.Col>
                        <Table.Col flex={15}>
                            <span css={textStyle}>{sludge.transferCompany}</span>
                        </Table.Col>
                    </Table.Row>
                ))
            }

        </Table>
    )
};

export default SludgeYearTable;
