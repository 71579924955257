import { getValidationByType, isNumber } from 'utils/common'
import Constants from 'configs/constants'
import { SignUpInput, UpdateUserInput } from 'types/graphql'
import useInput from 'hooks/useInput'
import useCustomMutation from 'hooks/useCustomMutation'
import { OperationVariables } from '@apollo/client/core'
import { DocumentNode } from '@apollo/client'

function useUserCommon<T = any, VT = OperationVariables>(initData: SignUpInput | UpdateUserInput, query: DocumentNode) {
	const [user, setUser, { ...onChange }] = useInput<SignUpInput | UpdateUserInput>(initData)
	const [onSave, { setValidationResult }] = useCustomMutation<T, VT>(query)

	const _onSave = async () => {
		const data = { ...user, roleId: Number(user.roleId) }
		setValidationResult(userValidation(data))
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore

		onSave({ data })
	}

	const onChangeSelectorBySiteId = (siteId: number) => () => {
		const findId = user && user.siteIds ? user.siteIds.filter(id => id === siteId) : []
		const filterIds = user && user.siteIds ? user.siteIds.filter(id => id !== siteId) : []
		setUser({
			...user,
			siteIds: findId.length > 0 ? [...filterIds] : [...filterIds, siteId],
		})
	}

	return {
		...onChange,
		onSave: _onSave,
		setValidationResult,
		user,
		setUser,
		onChangeSelectorBySiteId,
	}
}

export const userValidation = (user: SignUpInput | UpdateUserInput) => {
	if (!user.userId) return getValidationByType('유저아이디', false)
	else if (!user.password) return getValidationByType('비밀번호', false)
	else if (!user.name) return getValidationByType('이름', false)
	else if (
		!user.cellPhone ||
		!isNumber(user.cellPhone) ||
		user.cellPhone.length !== Constants.validation.CELL_PHONE_LENGTH
	)
		return getValidationByType('휴대폰', false)
	//else if (!user.groupId) return getValidationByType('거래처', false)
	else if (!user.email) return getValidationByType('이메일', false)
	else if (!user.roleId) return getValidationByType('권한', false)

	return getValidationByType('', true)
}

export default useUserCommon
