import React from 'react'
import { css } from '@emotion/react'
import Selector from 'components/selector/Selector'
import moment from 'moment'
import { SelectorType } from 'types/common'
import { getSearchInitData } from 'hooks/useSearch'
import { SearchKindEnum } from 'types/graphql'

const onChangeType = getSearchInitData(SearchKindEnum.MANAGER)
type Props = {
	yarn: string
	onChange: (type: keyof typeof onChangeType, value: string) => void
}
const YearInput: React.FC<Props> = ({ yarn, onChange }) => {
	const MIN_YARN = 2020
	const getYarn = () => {
		const MAX_YARN = Number(moment().format('YYYY'))
		const result: SelectorType[] = []
		for (let i = MAX_YARN; i >= MIN_YARN; i--) {
			result.push({ label: String(i), value: i })
		}

		return result
	}

	return (
		<Selector
			options={getYarn()}
			values={[yarn]}
			onChange={value => onChange('year', String(value))}
			css={css`
				width: 10rem;
				height: auto;
			`}
		/>
	)
}

export default YearInput
