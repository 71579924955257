import { gql } from '@apollo/client'

export const FileUpload = gql`
	mutation fileUpload($file: Upload!, $refId: Float!) {
		fileUpload(file: $file, refId: $refId) {
			refId
			url
		}
	}
`
