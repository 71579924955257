import React from 'react'

type Props = {
	message?: string
}
const Loading: React.FC<Props> = ({}) => {
	return <div>Loading...</div>
}

export default Loading
