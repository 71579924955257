import React, {useEffect, useState} from 'react'
import Table from 'components/table'
import TitleTable from 'components/templete/titleTable'
import {textStyle} from 'components/table/Table'
import {FlowMeasure} from "types/graphql";
import InfluentFlowDto from "dtos/flow/InfluentFlowDto";

type Props = {
	data?: FlowMeasure
}
const ColDefaultStyle = {
	flexDirection: 'column',
	padding: '0',
} as React.CSSProperties

const OperationDayTable1: React.FC<Props> = ({data}) => {
	const [influentFlow, setInfluentFlow] = useState<InfluentFlowDto>()
	console.log(data)

	useEffect(()=> {
		if(!data) return
		setInfluentFlow(new InfluentFlowDto(data))
	}, [data])


	return (
		<TitleTable title="일일 처리 현황" name="flowMng" style={{ marginBottom: '2.4rem' }}>
			<Table>
				<Table.Row bgColor="#E6E8EE">
					<Table.Col flex={20}>
						<span css={textStyle}>구분</span>
					</Table.Col>
					<Table.Col flex={80} style={ColDefaultStyle}>
						<Table.Row>
							<Table.Col bgColor="#E6E8EE" style={{ border: '0px' }}>
								<span css={textStyle}>측정값 (m2)</span>
							</Table.Col>
						</Table.Row>
						<Table.Row style={{ borderBottom: '0px', width: '100%', backgroundColor: '#9098B4', color: '#fff' }}>
							<Table.Col style={{ borderLeft: '0px' }}>
								<span css={textStyle}>전일지침</span>
							</Table.Col>
							<Table.Col>
								<span css={textStyle}>금일지침</span>
							</Table.Col>
							<Table.Col>
								<span css={textStyle}>일일유량</span>
							</Table.Col>
							<Table.Col>
								<span css={textStyle}>월간누계</span>
							</Table.Col>
							<Table.Col style={{ borderRight: '0px' }}>
								<span css={textStyle}>연간누계</span>
							</Table.Col>
						</Table.Row>
					</Table.Col>
				</Table.Row>
				{
					data?.selectFlowList.map((flow, index) => {
						const findItem = influentFlow?.influentFlowItems.find(item => item.flow.id === flow.id)
						const findItemByMonth = influentFlow?.itemsByMonth?.find(item => item.flow.id === flow.id)
						const findItemByYear = influentFlow?.itemsByYear?.find(item => item.flow.id === flow.id)
						const findItemByDay = influentFlow?.itemsByDay?.find(item => item.flow.id === flow.id)
						const findOriginItem = influentFlow?.itemsByOrigin?.find(
							influentFlowItem => influentFlowItem.flow.id === flow.id,
						)
						const itemResult = findItem?.result || 0
						const originItemResult = findOriginItem?.result || 0

						return (
							<Table.Row key={flow.id + "___"+"OperationDayTable1"}>
								<Table.Col flex={20}>
									<span css={textStyle}>{flow.name}</span>
								</Table.Col>
								<Table.Col flex={80} style={{ padding: 0 }}>
									<Table.Row style={{ width: '100%', borderTop: '0px' }} key={flow.name}>
										<Table.Col style={{ borderLeft: '0px' }}>
											<span css={textStyle}>{findItemByDay?.result || 0}</span>
										</Table.Col>
										<Table.Col padding="0 0.8rem">
											<span css={textStyle}>{itemResult}</span>
										</Table.Col>
										<Table.Col>
											<span css={textStyle}>{(findItemByDay?.result || 0) - itemResult}</span>
										</Table.Col>
										<Table.Col>
											<span css={textStyle}>{(findItemByMonth?.result || 0) - originItemResult + itemResult}</span>
										</Table.Col>
										<Table.Col style={{ borderRight: '0px' }}>
											<span css={textStyle}>{(findItemByYear?.result || 0) - originItemResult + itemResult}</span>
										</Table.Col>
									</Table.Row>
								</Table.Col>
							</Table.Row>
						)
					})
				}
			</Table>
		</TitleTable>
	)
}
export default OperationDayTable1
