import { gql } from '@apollo/client'

export const CODES_QUERY = gql`
	query Codes($ids: [Float!]!) {
		selectCodeList(data: { ids: $ids }) {
			data {
				id
				name
				childCodes {
					id
					name
				}
			}
		}
	}
`
