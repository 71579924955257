import {action, observable} from 'mobx'
import { Me_me_sites} from 'types/graphql'

class SiteStore {
    @observable
    private _sites!: Me_me_sites[]

    @action
    set sites(site: Me_me_sites[]) {
        this._sites = site
    }

    get sites() {
        return this._sites
    }
}

export default new SiteStore()
