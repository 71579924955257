import React from 'react'
import Icon from 'components/Icon'
import { IconType } from 'components/Icon/Icon'
import { css } from '@emotion/react'
import { font } from 'styles/Theme'

type Props = {
	name: IconType
	label: string
	style?: React.CSSProperties
	size?: string | number
	customCSS?: any
	color?: string
	subTitle?: string
}
const IconText: React.FC<Props> = ({ name, label, style, size = 30, customCSS, color, subTitle }) => {
	return (
		<p css={[textStyle, customCSS]} style={style}>
			<Icon name={name} size={size} style={{ marginRight: 8 }} color={color} />
			<span>{label}</span>
			<span className="sub_title">{subTitle}</span>
		</p>
	)
}

export default IconText

const textStyle = css`
	display: inline-flex;
	align-items: flex-end;
	margin: 0;
	${font.body2}
	height: 28px;

	& > span {
		line-height: 23px;
	}

	& > .sub_title {
		display: block;
		margin-left: 10px;
		${font.body5};
	}
`
