import {useLazyQuery} from '@apollo/client'
import DiaryDto from 'dtos/diary/DiaryDto'
import useCustomMutation from 'hooks/useCustomMutation'
import moment from 'moment'
import {CREATE_DIARY_QUERY, DIARY_QUERY} from 'queries/diaryQuery'
import React, {useCallback, useState} from 'react'
import {CreateDiary, CreateDiaryVariables, Diary, DiaryVariables} from 'types/graphql'
import {deepCopy} from 'utils/common'

function useCreateDiary() {
	const [diary, setDiary] = useState<DiaryDto>(new DiaryDto())
	const [onSave] = useCustomMutation<CreateDiary, CreateDiaryVariables>(CREATE_DIARY_QUERY)
	const [ refetch, {data} ] = useLazyQuery<Diary, DiaryVariables>(DIARY_QUERY, {
		fetchPolicy: 'cache-and-network',
		variables: { data: { regDt: moment().format('YYYY-MM-DD'), siteId: 0 } },
		onCompleted: ({ selectCodeList, selectDiary }) => {
			if (selectDiary) {
				const newDiary = new DiaryDto(selectDiary)
				console.log(newDiary.diaryItems)
				setDiary(deepCopy(newDiary, new DiaryDto()))
			} else if (selectCodeList.data) {
				const newDiary = new DiaryDto()
				newDiary.setDiaryItemsInit(selectCodeList.data)
				setDiary(newDiary)
			}
		},
		onError: error => {
			console.log(error)
		},
	})

	const _onSave = useCallback((siteId: number) => () => {
		const data = diary.getCreate()
		onSave({ data: {...data, siteId: siteId} })
	}, [diary])

	const onChangeInput = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const { name, value } = e.target
			diary.diaryItems = diary.diaryItems.map(item => {
				if (item.code.id !== Number(name)) return item
				return { ...item, value }
			})
			setDiary(deepCopy(diary, new DiaryDto()))
		},
		[diary],
	)

	const onChangeSite = useCallback(
		(id: number) => {
			diary.site = { __typename: 'SiteEntity', id }
			setDiary(deepCopy(diary, new DiaryDto()))
		},
		[diary],
	)

	return {
		onSave: _onSave,
		diary,
		setDiary,
		onChangeSite,
		onChangeInput,
		onRefetch: refetch,
		codes: data?.selectCodeList.data,
	}
}

export default useCreateDiary
