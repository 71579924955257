// @flow
import React from 'react';
import Table from "components/table";
import {textStyle} from "components/table/Table";
import {Facilities_selectFacilityListByWeb_facilities} from "types/graphql";
import moment from "moment";
import {numberWithCommas} from "utils/common";

type SludgeWriteTableProps = {
    facilities?: Facilities_selectFacilityListByWeb_facilities[]
};

function FacilityRepairTable({facilities}: SludgeWriteTableProps) {
    return (
        <Table>
            <Table.Row bgColor="#E6E8EE">
                <Table.Col flex={15}>
                    <span css={textStyle}>시설물 명칭</span>
                </Table.Col>
                <Table.Col flex={5}>
                    <span css={textStyle}>시설물 개수</span>
                </Table.Col>
                <Table.Col flex={10}>
                    <span css={textStyle}>수리 접수일</span>
                </Table.Col>
                <Table.Col flex={10}>
                    <span css={textStyle}>수리 완료일</span>
                </Table.Col>
                <Table.Col flex={20}>
                    <span css={textStyle}>수리 내역</span>
                </Table.Col>
                <Table.Col flex={10}>
                    <span css={textStyle}>수리 업체</span>
                </Table.Col>
                <Table.Col flex={10}>
                    <span css={textStyle}>수리비용</span>
                </Table.Col>
                <Table.Col flex={20}>
                    <span css={textStyle}>비고</span>
                </Table.Col>
            </Table.Row>
            {
                facilities?.map(facility => {
                    return (
                        <Table.LinkRow to={`/facility/repair/${facility.id}`} key={facility.id}>
                            <Table.Col flex={15}>
                                <span css={textStyle}>{facility.titleCode.name}</span>
                            </Table.Col>
                            <Table.Col flex={5}>
                                <span css={textStyle}>{facility.amount}</span>
                            </Table.Col>
                            <Table.Col flex={10}>
                                <span css={textStyle}>{moment(facility.dateOfReceipt).format("YYYY-MM-DD")}</span>
                            </Table.Col>
                            <Table.Col flex={10}>
                                <span css={textStyle}>{moment(facility.completedDate).format("YYYY-MM-DD")}</span>
                            </Table.Col>
                            <Table.Col flex={20}>
                                <span css={textStyle}>{facility.detail}</span>
                            </Table.Col>
                            <Table.Col flex={10}>
                                <span css={textStyle}>{facility.company}</span>
                            </Table.Col>
                            <Table.Col flex={10}>
                                <span css={textStyle}>{numberWithCommas(facility.cost)} 원</span>
                            </Table.Col>
                            <Table.Col flex={20}>
                                <span css={textStyle}>{facility.memo}</span>
                            </Table.Col>
                        </Table.LinkRow>
                    )
                })
            }

        </Table>
    )
};

export default FacilityRepairTable;
