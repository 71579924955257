import NoticeDto from 'dtos/notice/NoticeDto'
import useCustomMutation from 'hooks/useCustomMutation'
import useInput from 'hooks/useInput'
import { CREATE_NOTICE_QUERY } from 'queries/noticeQuery'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { CreateNotice, CreateNoticeVariables } from 'types/graphql'
import { deepCopy } from 'utils/common'

function useCreateNotice() {
	const [notice, setNotice] = useInput<NoticeDto>(new NoticeDto({ title: '', content: '', id: 0 } as NoticeDto))
	const history = useHistory()
	const [onSave] = useCustomMutation<CreateNotice, CreateNoticeVariables>(CREATE_NOTICE_QUERY)

	const _onSave = useCallback(() => {
		const data = notice.getCreate()
		onSave(
			{ data },
			{
				onCompleted: () => {
					history.goBack()
				},
			},
		)
	}, [])

	const onChangeInput = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.target.name as 'title'
		notice[name] = e.target.value
		setNotice(deepCopy(notice))
	}, [])

	const onChangeContent = useCallback((value: string) => {
		notice.content = value
		setNotice(deepCopy(notice))
	}, [])

	return {
		onSave: _onSave,
		notice,
		setNotice,
		onChangeInput,
		onChangeContent,
	}
}

export default useCreateNotice
