import React from 'react'
import SubMenuItem from 'components/layout/side/SubMenuItem'
import { css } from '@emotion/react'
import { MenuDataType } from 'configs/menuData'

type Props = {
	menus: MenuDataType[] | undefined
}
const SubMenu: React.FC<Props> = ({ menus }) => {
	return (
		<ul css={style}>{menus && menus.map(menu => <SubMenuItem key={menu.id} label={menu.title} to={menu.url} />)}</ul>
	)
}

export default SubMenu

const style = css`
	padding-left: 36px;
`
