import React, {useCallback} from 'react';
import View from "components/templete/view";
import MedicineTable from "components/medicine/MedicineTable";
import SiteUtils from "components/utils/SiteUtils";
import useSearch, {getSearchInitData} from "hooks/useSearch";
import {
    Items,
    ItemsVariables,
    ItemTypeEnum,
    MedicineRemains,
    MedicineRemainsVariables,
    SearchKindEnum,
} from "types/graphql";
import {SelectorValueType} from "types/common";
import {useQuery} from "@apollo/client";
import useCustomQuery from "hooks/useCustomQuery";
import {MEDICINE_REMAINS_QUERY} from "queries/medicineQuery";
import {ITEMS_QUERY} from "queries/ItemQuery";
import {observer} from "mobx-react-lite";

function Medicine() {
    const {searchData, onChangeSiteId} = useSearch(
        '/medicine',
        getSearchInitData(SearchKindEnum.GROUP_NAME),
    )
    const {onRefetch, data} = useCustomQuery<MedicineRemains, MedicineRemainsVariables>(MEDICINE_REMAINS_QUERY, {data: {siteId: searchData.siteId}}, false)
    const itemsResult = useQuery<Items, ItemsVariables>(ITEMS_QUERY, {variables: {data: {type: [ItemTypeEnum.ITEM1, ItemTypeEnum.ITEM2]}}})

    const _onChangeSelector = useCallback((value: SelectorValueType) => {
        onChangeSiteId(Number(value))
        onRefetch({data: {siteId: Number(value)}})
    }, [])

    return (
        <View
            title="약품 재고량"
            tables={[<MedicineTable key="MedicineTable" data={data?.selectChemicalHistoryRemains}
                                    items={itemsResult.data?.selectItemList}/>]}
            utils={<SiteUtils onChangeSelector={_onChangeSelector} searchData={searchData}/>}
        />
    )

}

export default observer(Medicine);
