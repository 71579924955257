import React from 'react'
import { Input } from 'components/input'
import { textStyle } from 'components/table/Table'
import Table from 'components/table'
import palette from 'styles/palette'
import TitleTable from 'components/templete/titleTable'
import { Diary_selectDiary_diaryItems, Diary_selectCodeList_data } from 'types/graphql'
import { IconType } from 'components/Icon/Icon'

type Props = {
	items: Diary_selectDiary_diaryItems[]
	code?: Diary_selectCodeList_data
	marginTop?: string
	onChangeInput(e: React.ChangeEvent<HTMLInputElement>): void
	title: string
	name: IconType
}

function OperationWriteTable({ items, code, marginTop, onChangeInput, title, name }: Props) {
	return (
		<TitleTable title={title} name={name} style={{ marginTop }}>
			<Table>
				<Table.Row bgColor={palette.tableHeadColor}>
					{code?.childCodes.map(child => (
						<Table.Col flex={1} key={child.id}>
							<span css={textStyle}>{child.name}</span>
						</Table.Col>
					))}
				</Table.Row>
				<Table.Row>
					{code?.childCodes.map(child => {
						const findItem = items?.find(item => item.code.id === child.id)
						return (
							<Table.Col flex={1} key={child.id + 'data'}>
								<Input
									style={{ width: '100%' }}
									name={String(child.id)}
									disabled={child.id === 120001 || child.id === 120002}
									value={child.id === 120001 ? '전력량' : findItem?.value}
									onChange={onChangeInput}
								/>
							</Table.Col>
						)
					})}
				</Table.Row>
			</Table>
		</TitleTable>
	)
}

export default OperationWriteTable
