import React, { useCallback } from 'react'
import Table from 'components/table'
import { textStyle } from 'components/table/Table'
import { FlowMeasure_selectFlowList } from 'types/graphql'
import moment from 'moment'
import { numberWithCommas } from 'utils/common'
import { Input } from 'components/input'
import InfluentFlowDto from 'dtos/flow/InfluentFlowDto'

type Props = {
	influentFlow?: InfluentFlowDto
	flows?: FlowMeasure_selectFlowList[]
	onChangeResult(e: React.ChangeEvent<HTMLInputElement>): void
	isNotToday: boolean
}
const ColDefaultStyle = {
	flexDirection: 'column',
	padding: '0',
} as React.CSSProperties

function FlowDayTable({ influentFlow, flows, onChangeResult, isNotToday }: Props) {
	const getTime = useCallback(() => {
		if (!influentFlow?.recentlyRegDt) return 0
		const prevTime = moment(influentFlow?.recentlyRegDt)
		const time = moment(influentFlow?.regDt)
		return numberWithCommas(Math.floor(moment.duration(time.diff(prevTime)).asHours()))
	}, [influentFlow])

	return (
		<Table>
			<Table.Row bgColor="#E6E8EE">
				<Table.Col flex={10} style={ColDefaultStyle}>
					<span css={textStyle}>점검자</span>
				</Table.Col>
				<Table.Col flex={10} style={ColDefaultStyle}>
					<span css={textStyle}>구분</span>
				</Table.Col>
				<Table.Col flex={70} style={ColDefaultStyle}>
					<Table.Row>
						<Table.Col bgColor="#E6E8EE" style={{ border: '0px' }}>
							<span css={textStyle}>측정값 (m2)</span>
						</Table.Col>
					</Table.Row>
					<Table.Row style={{ borderBottom: '0px', width: '100%', backgroundColor: '#9098B4', color: '#fff' }}>
						<Table.Col style={{ borderLeft: '0px' }}>
							<span css={textStyle}>전일지침</span>
						</Table.Col>
						<Table.Col>
							<span css={textStyle}>금일지침</span>
						</Table.Col>
						<Table.Col>
							<span css={textStyle}>일일유량</span>
						</Table.Col>
						<Table.Col>
							<span css={textStyle}>월간누계</span>
						</Table.Col>
						<Table.Col style={{ borderRight: '0px' }}>
							<span css={textStyle}>연간누계</span>
						</Table.Col>
					</Table.Row>
				</Table.Col>
				<Table.Col flex={10} style={ColDefaultStyle}>
					<span css={textStyle}>측정간격 (시)</span>
				</Table.Col>
			</Table.Row>

			<Table.Row>
				<Table.Col flex={10} style={ColDefaultStyle}>
					<span css={textStyle}>{influentFlow?.inspector || ''}</span>
				</Table.Col>
				<Table.Col flex={10} style={ColDefaultStyle}>
					{flows?.map((flow, index) => {
						return index === 0 ? (
							<Table.Row style={{ width: '100%', borderTop: '0px' }} key={flow.id}>
								<Table.Col style={{ borderLeft: '0px', borderRight: '0px', borderTop: '0px' }}>
									<span css={textStyle}>{flow.name}</span>
								</Table.Col>
							</Table.Row>
						) : (
							<Table.Row style={{ width: '100%' }} key={flow.id}>
								<Table.Col style={{ borderLeft: '0px', borderRight: '0px' }}>
									<span css={textStyle}>{flow.name}</span>
								</Table.Col>
							</Table.Row>
						)
					})}
				</Table.Col>
				<Table.Col flex={70} style={ColDefaultStyle}>
					{flows?.map((flow, index) => {
						const findItem = influentFlow?.influentFlowItems.find(item => item.flow.id === flow.id)
						const findItemByMonth = influentFlow?.itemsByMonth?.find(item => item.flow.id === flow.id)
						const findItemByYear = influentFlow?.itemsByYear?.find(item => item.flow.id === flow.id)
						const findItemByDay = influentFlow?.itemsByDay?.find(item => item.flow.id === flow.id)
						const findOriginItem = influentFlow?.itemsByOrigin?.find(
							influentFlowItem => influentFlowItem.flow.id === flow.id,
						)
						const itemResult = findItem?.result || 0
						const originItemResult = findOriginItem?.result || 0
						return index === 0 ? (
							<Table.Row style={{ width: '100%', borderTop: '0px' }} key={flow.name}>
								<Table.Col style={{ borderLeft: '0px' }}>
									<span css={textStyle}>{findItemByDay?.result || 0}</span>
								</Table.Col>
								<Table.Col padding="0 0.8rem">
									<Input
										value={itemResult}
										style={{ width: '100%' }}
										onChange={onChangeResult}
										name={String(flow.id)}
										disabled={isNotToday}
										autoComplete="off"
									/>
								</Table.Col>
								<Table.Col>
									<span css={textStyle}>{(findItemByDay?.result || 0) - itemResult}</span>
								</Table.Col>
								<Table.Col>
									<span css={textStyle}>{(findItemByMonth?.result || 0) - originItemResult + itemResult}</span>
								</Table.Col>
								<Table.Col style={{ borderRight: '0px' }}>
									<span css={textStyle}>{(findItemByYear?.result || 0) - originItemResult + itemResult}</span>
								</Table.Col>
							</Table.Row>
						) : (
							<Table.Row style={{ width: '100%' }} key={flow.name}>
								<Table.Col style={{ borderLeft: '0px' }}>
									<span css={textStyle}>{findItemByDay?.result || 0}</span>
								</Table.Col>
								<Table.Col padding="0 0.8rem">
									<Input
										value={itemResult}
										style={{ width: '100%' }}
										onChange={onChangeResult}
										name={String(flow.id)}
										disabled={isNotToday}
										autoComplete="off"
									/>
								</Table.Col>
								<Table.Col>
									<span css={textStyle}>{(findItemByDay?.result || 0) - itemResult}</span>
								</Table.Col>
								<Table.Col>
									<span css={textStyle}>{(findItemByMonth?.result || 0) - originItemResult + itemResult}</span>
								</Table.Col>
								<Table.Col style={{ borderRight: '0px' }}>
									<span css={textStyle}>{(findItemByYear?.result || 0) - originItemResult + itemResult}</span>
								</Table.Col>
							</Table.Row>
						)
					})}
				</Table.Col>
				<Table.Col flex={10} style={ColDefaultStyle}>
					<span css={textStyle}>{Number(getTime()) === -1 ? 0 : getTime()}</span>
				</Table.Col>
			</Table.Row>
		</Table>
	)
}

export default FlowDayTable
