import React from 'react'
import Table from 'components/table'
import TitleTable from 'components/templete/titleTable'
import { textStyle } from 'components/table/Table'
import { css } from '@emotion/react'
import { ProjectItem_selectItemList, Projects_selectProjectListByRegDt_files } from 'types/graphql'

type Props = {
	files?: Projects_selectProjectListByRegDt_files[]
	items?: ProjectItem_selectItemList[]
	onOpenModal(path: string): void
	onChangeFile(e: React.ChangeEvent<HTMLInputElement>): void
}

const OperationDayTable5: React.FC<Props> = ({ files, items, onOpenModal }) => {
	return (
		<TitleTable title="사진 등록" name="camera">
			<Table>
				<Table.Row>
					{items?.map(item => (
						<Table.Col flex={25} key={item.id}>
							<span css={textStyle}>{item.name}</span>
						</Table.Col>
					))}
				</Table.Row>

				<Table.Row>
					{items?.map(item => {
						const file = files?.find(file => file.item.id === item.id)
						return (
							<Table.Col flex={25} key={item.id + 'file'}>
								<div
									css={ImageContainerStyle}
									role="button"
									tabIndex={0}
									onClick={() => file && onOpenModal(file.filePath)}
								>
									{file ? (
										<div css={ImageStyle(file.filePath)} />
									) : (
										<span
											css={textStyle}
											style={{
												display: 'inline-block',
												width: '100%',
												height: '10px',
												color: '#e8e8e8',
												fontSize: '1.3rem',
											}}
										>
											{''}
										</span>
									)}
								</div>
							</Table.Col>
						)
					})}
				</Table.Row>
			</Table>
		</TitleTable>
	)
}
export default OperationDayTable5

const ImageContainerStyle = css`
	width: 100px;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		cursor: pointer;
	}
`

const ImageStyle = (imageSrc: string) => css`
	background-image: url(${imageSrc});
	background-size: cover;
	width: 100%;
	height: 100%;

	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
`
