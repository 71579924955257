import {gql} from '@apollo/client'

export const CREATE_SLUDGE_QUERY = gql`
    mutation CreateSludge($data: InsertSludgeInput!) {
        insertSludge(data: $data) {
            id
        }
    }
`

export const SLUDGES_QUERY = gql`
    query Sludges($data: SelectSludgeListInput!) {
        selectSludgeList(data: $data) {
            id
            transferCompany
            value
            regDt
            transferProcessingFacility
            generatingFacility
            site {
                id
                name
            }
        }
    }
`

export const SLUDGE_DATE_QUERY = gql`
    query SludgeDate($data: SelectSludgeListInput!) {
        selectSludgeByDate(data: $data) {
            id
            transferCompany
            value
            regDt
            transferProcessingFacility
            generatingFacility
            site {
                id
                name
            }
        }
    }
`

export const UPDATE_SLUDGE_QUERY = gql`
    mutation UpdateSludge($data: UpdateSludgeInput!) {
        updateSludge(data: $data) {
            data
        }
    }
`

export const SLUDGE_YEAR_QUERY = gql`
    query SludgeYear($data: SelectSludgeListInput!) {
        selectSludgeByYear(data: $data) {
            id
            transferCompany
            value
            regDt
            transferProcessingFacility
            generatingFacility
            site {
                id
                name
            }
        }
    }
`
