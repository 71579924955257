import React, {useCallback} from 'react';
import {Codes, CodesVariables, Sites, SitesVariables} from "types/graphql";
import {useQuery} from "@apollo/client";
import View from "components/templete/view";
import FacilityRepairWriteTable from "components/facility/FacilityRepairWriteTable";
import {CODES_QUERY} from "queries/codeQuery";
import useCreateFacility from "hooks/facility/useCreateFacility";
import {SITES_QUERY} from "queries/siteQuery";
import WriteUtils from "components/utils/WriteUtils";
import {useParams} from "react-router-dom";
import useUpdateFacility from "hooks/facility/useUpdateFacility";

function FacilityRepairWrite() {
    const {id} = useParams<{ id?: string }>()
    const {
        facility,
        onChangeInput,
        onChangeSelectorByObjectId,
        onSave,
        onChangeSelectorByValue
    } = id ? useUpdateFacility(Number(id)) : useCreateFacility();
    const {data} = useQuery<Sites, SitesVariables>(SITES_QUERY, {variables: {data: {limit: 99999999}}})
    const code = useQuery<Codes, CodesVariables>(CODES_QUERY, {variables: {ids: [2, 3, 4, 5, 6, 7, 8, 9, 10]}})

    const getTitleCode = useCallback(() => {
        const result = code.data?.selectCodeList.data?.find(code => code.id === facility.locationCode.id)
        return result?.childCodes
    }, [facility.locationCode])

    return (
        <View
            title="일일 슬러지 처리량 등록"
            tables={[<FacilityRepairWriteTable key="FacilityRepairWriteTable"
                                               sites={data?.selectSiteList.sites}
                                               codes={code.data?.selectCodeList.data}
                                               facility={facility}
                                               onChangeInput={onChangeInput}
                                               onChangeSelectorByObjectId={onChangeSelectorByObjectId}
                                               onChangeSelectorByValue={onChangeSelectorByValue}
                                               titleCode={getTitleCode()}
            />]}
            utils={<WriteUtils onClick={onSave}/>}
        />
    )
}

export default FacilityRepairWrite;
