// @flow
import React from 'react'

export type LayoutProps = {
	children: React.ReactNode
}

function Layout({ children }: LayoutProps) {
	return <div>{children}</div>
}

export default Layout
