import React, { useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Layout from 'components/layout'
import { Route, Switch } from 'react-router-dom'
import Header from 'components/layout/header/Header'
import Side from 'components/layout/side/Side'
import Main from 'components/layout/Main'
import { css } from '@emotion/react'
import User from 'pages/user/User'
import UserWrite from 'pages/user/UserWrite'
import Login from 'pages/user/Login'
import useMe from 'hooks/user/useMe'
import WaterDay from 'pages/water/WaterDay'
import WaterMonth from 'pages/water/WaterMonth'
import WaterCount from 'pages/water/WaterCount'
import FlowDay from 'pages/flow/FlowDay'
import FlowMonth from 'pages/flow/FlowMonth'
import OperationDay from 'pages/operation/OperationDay'
import FlowCount from 'pages/flow/FlowCount'
import OperationCheck from 'pages/operation/OperationCheck'
import MedicineWrite from 'pages/medicine/MedicineWrite'
import MedicineMonth from 'pages/medicine/MedicineMonth'
import SludgeWrite from 'pages/sludge/SludgeWrite'
import SludgeMonth from 'pages/sludge/SludgeMonth'
import SludgeYear from 'pages/sludge/SludgeYear'
import FacilityRepair from 'pages/facility/FacilityRepair'
import FacilityCount from 'pages/facility/FacilityCount'
import Notice from 'pages/notice/Notice'
import FacilityRepairWrite from 'pages/facility/FacilityRepairWrite'
import WaterMonthGraph from 'pages/water/WaterMonthGraph'
import FlowGraph from 'pages/flow/FlowGraph'
import FlowMonthGraph from 'pages/flow/FlowMonthGraph'
import Medicine from 'pages/medicine/Medicine'
import { observer } from 'mobx-react-lite'
import NoticeRead from 'pages/notice/NoticeRead'
import NoticeWriteOrUpdate from 'pages/notice/NoticeWriteOrUpdate'
import OperationWrite from 'pages/operation/OperationWrite'
import AuthRoute from 'components/route/AuthRoute'

function App() {
	const { me, isLogin } = useMe()

	useEffect(() => {
		me()
	}, [isLogin])

	return (
		<Layout>
			<ToastContainer />
			<Header />
			<div css={container}>
				<Side />
				<Main>
					<Switch>
						<AuthRoute path={['/', '/home', '/water/day']} exact>
							<WaterDay />
						</AuthRoute>
						<AuthRoute path="/water/month" exact>
							<WaterMonth />
						</AuthRoute>
						<AuthRoute path="/water/month/graph" exact>
							<WaterMonthGraph />
						</AuthRoute>
						<AuthRoute path="/water/count" exact>
							<WaterCount />
						</AuthRoute>
						<AuthRoute path="/flow/day" exact>
							<FlowDay />
						</AuthRoute>
						<AuthRoute path="/flow/graph" exact>
							<FlowGraph />
						</AuthRoute>
						<AuthRoute path="/flow/month" exact>
							<FlowMonth />
						</AuthRoute>
						<AuthRoute path="/flow/month/graph" exact>
							<FlowMonthGraph />
						</AuthRoute>
						<AuthRoute path="/flow/count" exact>
							<FlowCount />
						</AuthRoute>
						<AuthRoute path="/operation/day" exact>
							<OperationDay />
						</AuthRoute>
						<AuthRoute path="/operation/write" exact>
							<OperationWrite />
						</AuthRoute>
						<AuthRoute path="/operation/check" exact>
							<OperationCheck />
						</AuthRoute>
						<AuthRoute path="/medicine/stock" exact>
							<Medicine />
						</AuthRoute>
						<AuthRoute path="/medicine/write" exact>
							<MedicineWrite />
						</AuthRoute>
						<AuthRoute path="/medicine/month" exact>
							<MedicineMonth />
						</AuthRoute>
						<AuthRoute path="/sludge/write" exact>
							<SludgeWrite />
						</AuthRoute>
						<AuthRoute path="/sludge/month" exact>
							<SludgeMonth />
						</AuthRoute>
						<AuthRoute path="/sludge/year" exact>
							<SludgeYear />
						</AuthRoute>
						<AuthRoute path="/facility/repair" exact>
							<FacilityRepair />
						</AuthRoute>
						<AuthRoute path="/facility/repair/write" exact>
							<FacilityRepairWrite />
						</AuthRoute>
						<AuthRoute path="/facility/repair/:id" exact>
							<FacilityRepairWrite />
						</AuthRoute>
						<AuthRoute path="/facility/count" exact>
							<FacilityCount />
						</AuthRoute>
						<AuthRoute path="/user" exact>
							<User />
						</AuthRoute>
						<AuthRoute path="/user/write" exact>
							<UserWrite />
						</AuthRoute>
						<AuthRoute path="/user/write/:userId" exact>
							<UserWrite />
						</AuthRoute>
						<AuthRoute path="/notice" exact>
							<Notice />
						</AuthRoute>
						<AuthRoute path="/notice/write/:id?" exact>
							<NoticeWriteOrUpdate />
						</AuthRoute>
						<AuthRoute path="/notice/:id" exact>
							<NoticeRead />
						</AuthRoute>
						<Route path="/login">
							<Login />
						</Route>
						<Route path="/">
							<div>
								<h1>404 Page</h1>
							</div>
						</Route>
					</Switch>
				</Main>
			</div>
		</Layout>
	)
}

export default observer(App)

const container = css`
	display: flex;
	flex-direction: row;
	height: calc(100vh - 78px);
`
