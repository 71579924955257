import React, {useCallback} from 'react'
import useSearch, {getSearchInitData, onChangeType} from 'hooks/useSearch'
import {SearchKindEnum, WaterCount as WaterCountQuery, WaterCountVariables} from 'types/graphql'
import {SelectorValueType} from 'types/common'
import View from 'components/templete/view'
import WaterCountTable from 'components/water/WaterCountTable'
import SiteAndMonthUtils from 'components/utils/SiteAndMonthUtils'
import moment from 'moment'
import useCustomQuery from 'hooks/useCustomQuery'
import {WATER_COUNT_QUERY} from 'queries/waterQuery'

function WaterCount() {
    const {searchData, onChange, onChangeSiteId, onNext, onPrev, getUrlByPageNo, getPrevMonth, getNextMonth} =
        useSearch('/water/count', getSearchInitData(SearchKindEnum.GROUP_NAME))

    const {onRefetch, data} = useCustomQuery<WaterCountQuery, WaterCountVariables>(
        WATER_COUNT_QUERY,
        {
            data: {
                siteId: Number(searchData.siteId),
                regDt: moment(`${searchData.year}-${searchData.month}`).format("YYYY-MM")
            }
        },
        false,
    )

    const _onChangeSelector = useCallback(
        (value: SelectorValueType) => {
            onChangeSiteId(Number(value))
            _onRefetch({
                siteId: Number(value),
                regDt: moment(`${searchData.year}-${searchData.month}-01`).format('YYYY-MM'),
            })
        },
        [searchData],
    )
    const _onChange = useCallback((type: keyof typeof onChangeType, value: string) => {
        onChange(type, value)
    }, [searchData])

    const _onChangeMonth = useCallback(
        (type: 'next' | 'prev') => () => {
            const isNext = type === 'next'
            const month = isNext ? getNextMonth() : getPrevMonth()

            _onRefetch({
                siteId: Number(searchData.siteId),
                regDt: `${searchData.year}-${month}`,
            })

            isNext ? onNext() : onPrev()
        },
        [searchData],
    )

    const _onRefetch = ({siteId, regDt}: { siteId: number; regDt: string }) => {
        onRefetch({
            data: {
                siteId,
                regDt,
            },
        })
    }
    return (
        <View
            title="수질분석 횟수"
            tables={[<WaterCountTable key="WaterMonthTable" data={data?.selectProjectCountBySiteIdAndMonth}/>]}
            utils={
                <SiteAndMonthUtils
                    onChangeSelector={_onChangeSelector}
                    searchData={searchData}
                    onChange={_onChange}
                    onNext={_onChangeMonth('next')}
                    onPrev={_onChangeMonth('prev')}
                />
            }
            baseUrl={getUrlByPageNo}
        />
    )
}

export default WaterCount
