import { gql } from '@apollo/client'

export const NOTICES_QUERY = gql`
	query Notices($data: SelectNoticeListInput!) {
		selectNoticeList(data: $data) {
			notices {
				id
				title
				regDt
			}

			paging {
				pageNo
				startNo
				endNo
				lastPageNo
			}
		}
	}
`

export const CREATE_NOTICE_QUERY = gql`
	mutation CreateNotice($data: InsertNoticeInput!) {
		insertNotice(data: $data) {
			id
		}
	}
`

export const UPDATE_NOTICE_QUERY = gql`
	mutation UpdateNotice($data: UpdateNoticeInput!) {
		updateNotice(data: $data) {
			data
		}
	}
`

export const DELETE_NOTICE_QUERY = gql`
	mutation DeleteNotice($data: UpdateNoticeInput!) {
		deleteNotice(data: $data) {
			data
		}
	}
`

export const NOTICE_QUERY = gql`
	query Notice($id: Float!) {
		selectNotice(id: $id) {
			id
			title
			regDt
			content
		}
	}
`
