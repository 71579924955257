import React from 'react'
import { ApolloError } from '@apollo/client'

type Props = {
	error?: ApolloError
}
const Error: React.FC<Props> = ({ error }) => {
	console.log(error)
	return <div>{error?.message}</div>
}

export default Error
