import React, { useCallback, useState } from 'react'
import { useQuery } from '@apollo/client'
import { CODES_QUERY } from 'queries/codeQuery'
import { CREATE_OPERATION_QUERY } from 'queries/operationQuery'
import { Codes, CodesVariables, DailyCheckItemEnum, CreateOperation, CreateOperationVariables } from 'types/graphql'
import DailyCheckDto from 'dtos/dailyCheck/DailyCheckDto'
import useCustomMutation from 'hooks/useCustomMutation'
import { deepCopy } from 'utils/common'

function useCreateDaily() {
	const [operation, setOperation] = useState<DailyCheckDto>(new DailyCheckDto())
	const code = useQuery<Codes, CodesVariables>(CODES_QUERY, {
		variables: { ids: [2, 3, 4, 5, 6, 7, 8, 9, 10] },
		onCompleted: ({ selectCodeList }) => {
			if (selectCodeList && selectCodeList.data) {
				const result: any[] = []
				for (const code of selectCodeList.data) {
					for (const child of code.childCodes) {
						result.push({
							__typename: 'DailyCheckItemEntity',
							id: 0,
							value: DailyCheckItemEnum.NONE,
							memo: '',
							code: {
								__typename: 'CodeEntity',
								id: child.id,
								name: child.name,
								parentCode: {
									__typename: 'CodeEntity',
									id: code.id,
									name: code.name,
								},
							},
						})
					}
				}

				setOperation(
					Object.assign(operation, {
						dailyCheckItems: result,
					}),
				)
			}
		},
	})
	const [onSave] = useCustomMutation<CreateOperation, CreateOperationVariables>(CREATE_OPERATION_QUERY)

	const _onSave = useCallback(() => {
		const data = operation.getCreate()
		if (data) onSave({ data })
	}, [])

	const onChangeToggle = useCallback(
		(type: 'red' | 'green', id: number) => {
			operation.setValue({ codeId: id, type })
			setOperation(deepCopy(operation))
		},
		[operation.dailyCheckItems],
	)

	const onChangeInput = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const { name, value } = e.target
			operation.setMemo({ codeId: Number(name), value })
			setOperation(deepCopy(operation))
		},
		[operation.dailyCheckItems],
	)

	const onChangeSiteId = useCallback((siteId: number) => {
		operation.site = { id: siteId, __typename: 'SiteEntity' }
		setOperation(deepCopy(operation))
	}, [])

	return {
		onSave: _onSave,
		operation,
		setOperation,
		onChangeToggle,
		onChangeInput,
		onChangeSite: onChangeSiteId,
		code,
	}
}

export default useCreateDaily
