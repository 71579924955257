import React, { useCallback } from 'react'
import useSearch, { getSearchInitData, onChangeType } from 'hooks/useSearch'
import { SearchKindEnum, Sludges, SludgesVariables } from 'types/graphql'
import { SelectorValueType } from 'types/common'
import View from 'components/templete/view'
import SludgeMonthTable from 'components/sludge/SludgeMonthTable'
import SiteAndMonthUtils from 'components/utils/SiteAndMonthUtils'
import useCustomQuery from 'hooks/useCustomQuery'
import { SLUDGES_QUERY } from 'queries/sludgeQuery'
import moment from 'moment'

function SludgeMonth() {
	const { searchData, onChange, onChangeSiteId, onNext, onPrev, getUrlByPageNo, getNextMonth, getPrevMonth } =
		useSearch('/sludge/month', getSearchInitData(SearchKindEnum.GROUP_NAME))
	const { onRefetch, data } = useCustomQuery<Sludges, SludgesVariables>(
		SLUDGES_QUERY,
		{
			data: {
				siteId: searchData.siteId,
				regDt: moment(`${searchData.year}-${searchData.month}`).format('YYYY-MM'),
			},
		},
		false,
	)

	const _onChangeSelector = useCallback(
		(value: SelectorValueType) => {
			onChangeSiteId(Number(value))
			_onRefetch({
				siteId: Number(value),
				regDt: moment(`${searchData.year}-${searchData.month}`).format('YYYY-MM'),
			})
		},
		[searchData.year, searchData.month],
	)

	const _onChange = useCallback((type: keyof typeof onChangeType, value: string) => {
		onChange(type, value)
	}, [])

	const _onChangeMonth = useCallback(
		(type: 'next' | 'prev') => () => {
			const isNext = type === 'next'
			const month = isNext ? getNextMonth() : getPrevMonth()
			_onRefetch({
				siteId: Number(searchData.siteId),
				regDt: moment(`${searchData.year}-${month}`).format('YYYY-MM'),
			})
			isNext ? onNext() : onPrev()
		},
		[searchData.siteId, searchData.year],
	)

	const _onRefetch = ({ siteId, regDt }: { siteId: number; regDt: string }) => {
		onRefetch({
			data: {
				siteId,
				regDt,
			},
		})
	}

	return (
		<View
			title="월간 슬러지 처리량"
			tables={[<SludgeMonthTable key="SludgeMonthTable" data={data?.selectSludgeList} />]}
			utils={
				<SiteAndMonthUtils
					onChangeSelector={_onChangeSelector}
					searchData={searchData}
					onChange={_onChange}
					onNext={_onChangeMonth('next')}
					onPrev={_onChangeMonth('prev')}
				/>
			}
			baseUrl={getUrlByPageNo}
		/>
	)
}

export default SludgeMonth
