import React from 'react'
import { NavLink } from 'react-router-dom'
import { css } from '@emotion/react'
import { font } from 'styles/Theme'
import MenuStore from 'stores/MenuStore'

type Props = {
	label: string
	to: string
	isSelected: boolean
	id: number
}
const HeaderMenuItem: React.FC<Props> = React.memo(({ label, to, isSelected, id }) => {
	const { setting } = MenuStore
	return (
		<li css={style}>
			<NavLink to={to} css={linkStyle(isSelected)} onClick={() => setting(id)}>
				<p>{label}</p>
			</NavLink>
		</li>
	)
})

HeaderMenuItem.displayName = 'HeaderMenuItem'

export default HeaderMenuItem

const style = css`
	${font.body3}
	padding-right: 45px;
`
const linkStyle = (isSelected: boolean) => css`
	${isSelected &&
	css`
		color: #095aba;
		text-decoration: underline;
	`}
`
