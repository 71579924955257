import React from 'react'
import { Input } from 'components/input/index'
import { SearchType } from 'hooks/useSearch'

type Props = {
	data: SearchType
	onChange(e: React.ChangeEvent<HTMLInputElement>): void
	onPush(): void
}
const SearchInput: React.FC<Props> = ({ data, onChange, onPush }) => {
	return (
		<Input
			name="search"
			value={data.search}
			placeholder="검색하실 내용을 적어주세요."
			onChange={onChange}
			onKeyDown={e => {
				if (e.key === 'Enter') onPush()
			}}
			css={{ width: '30rem', marginLeft: '1rem', marginRight: '1rem' }}
		/>
	)
}

export default SearchInput
