import React from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { css } from '@emotion/react'

export type ChartWithBarProps = {
	data: any[]
	keys: string[]
	indexBy: string
	customStyle?: any
} & any

const ChartWithBar: React.FC<ChartWithBarProps> = ({ data, indexBy, keys, margin, customStyle, ...props }) => {
	return (
		<div css={[Style, customStyle]}>
			<ResponsiveBar
				data={data}
				keys={keys}
				indexBy={indexBy}
				margin={margin ? margin : { top: 50, right: 130, bottom: 50, left: 60 }}
				padding={0.3}
				valueScale={{ type: 'linear' }}
				indexScale={{ type: 'band', round: true }}
				colors={{ scheme: 'nivo' }}
				defs={[
					{
						id: 'dots',
						type: 'patternDots',
						background: 'inherit',
						color: '#38bcb2',
						size: 10,
						padding: 1,
						stagger: true,
					},
					{
						id: 'lines',
						type: 'patternLines',
						background: 'inherit',
						color: '#eed312',
						rotation: -45,
						lineWidth: 6,
						spacing: 10,
					},
				]}
				fill={[
					{
						match: {
							id: 'fries',
						},
						id: 'dots',
					},
					{
						match: {
							id: 'sandwich',
						},
						id: 'lines',
					},
				]}
				borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
				axisTop={null}
				axisRight={null}
				axisBottom={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: '',
					legendPosition: 'middle',
					legendOffset: 32,
				}}
				axisLeft={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: 'ppm',
					legendPosition: 'middle',
					legendOffset: -40,
				}}
				labelSkipWidth={12}
				labelSkipHeight={12}
				labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
				legends={[
					{
						dataFrom: 'keys',
						anchor: 'bottom',
						direction: 'row',
						justify: false,
						translateX: 0,
						translateY: 50,
						itemsSpacing: 70,
						itemWidth: 100,
						itemHeight: 20,
						itemDirection: 'left-to-right',
						itemOpacity: 0.85,
						symbolSize: 20,
						effects: [
							{
								on: 'hover',
								style: {
									itemOpacity: 1,
								},
							},
						],
					},
				]}
				animate={true}
				motionStiffness={90}
				motionDamping={15}
				{...props}
			/>
		</div>
	)
}

const Style = css`
	height: 400px;

	& svg g g g text {
		font-size: 20px !important;
	}
`

export default ChartWithBar
