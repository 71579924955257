import React, {useCallback, useEffect, useState} from 'react'
import useSearch, {getSearchInitData} from 'hooks/useSearch'
import {
    AnalysisProcessEnum,
    Codes,
    CodesVariables,
    DailyOperation,
    DailyOperationVariables,
    FlowMeasure,
    FlowMeasureVariables, ProjectItem,
    Projects,
    Projects_selectProjectListByRegDt,
    ProjectsVariables,
    SearchKindEnum
} from 'types/graphql'
import {SelectorValueType} from 'types/common'
import View from 'components/templete/view'
import OperationDayTable from 'components/operation/OperationDayTable'
import OperationDayTable1 from 'components/operation/OperationDayTable1'
import OperationDayTable3 from 'components/operation/OperationDayTable3'
import OperationDayTable4 from 'components/operation/OperationDayTable4'
import SiteAndDayUtils from 'components/utils/SiteAndDayUtils'
import {useLazyQuery, useQuery} from '@apollo/client'
import {DAILY_OPERATION_QUERY} from 'queries/operationQuery'
import moment from 'moment'
import {CODES_QUERY} from 'queries/codeQuery'
import {FLOW_MEASURE_QUERY} from "queries/flowQuery";
import {PROJECT_ITEM_QUERY, PROJECTS_QUERY} from "queries/waterQuery";

function OperationDay() {
    const {searchData, onChangeRegDt, onChangeSiteId} = useSearch(
        '/water/month',
        getSearchInitData(SearchKindEnum.GROUP_NAME),
    )
    const [onFetch, {data}] = useLazyQuery<DailyOperation, DailyOperationVariables>(DAILY_OPERATION_QUERY)
    const [onFlowFetch, flowResult] = useLazyQuery<FlowMeasure, FlowMeasureVariables>(FLOW_MEASURE_QUERY, {
        onError: error => console.log(error),
    })
    const [project, setProject] = useState<Projects_selectProjectListByRegDt>()
    const [onProjectFetch] = useLazyQuery<Projects, ProjectsVariables>(PROJECTS_QUERY, {
        variables: {data: {siteId: Number(searchData.siteId), regDt: moment(searchData.regDt).format('YYYY-MM-DD')}},
        onCompleted: ({selectProjectListByRegDt}) => {
            if (selectProjectListByRegDt && selectProjectListByRegDt.length > 0) {
                setProject(selectProjectListByRegDt[0])
            }
        },
    })
    const codeResult = useQuery<Codes, CodesVariables>(CODES_QUERY, {variables: {ids: [11, 12]}})
    const itemResult = useQuery<ProjectItem>(PROJECT_ITEM_QUERY)

    const _onChangeSelector = useCallback(
        (value: SelectorValueType) => {
            onChangeSiteId(Number(value))
            onFetch({variables: {regDt: searchData.regDt, siteId: Number(value)}})
            onFlowFetch({variables: {regDt: searchData.regDt, siteId: Number(value)}})
            onProjectFetch({variables: {data: {siteId: Number(value), regDt: searchData.regDt}}})
        },
        [searchData],
    )

    const _onChangeRegDt = useCallback(
        (date: Date) => {
            onChangeRegDt(date)
            onFetch({variables: {regDt: moment(date).format('YYYY-MM-DD'), siteId: searchData.siteId}})
            onFlowFetch({variables: {regDt: moment(date).format('YYYY-MM-DD'), siteId: searchData.siteId}})
            onProjectFetch({variables: {data: {siteId: searchData.siteId, regDt: moment(date).format("YYYY-MM-DD")}}})
        },
        [searchData],
    )

    const getSamples = useCallback(() => {
        return itemResult.data?.selectSampleList.filter(
            sample => sample.analysisProcess === AnalysisProcessEnum.A2O,
        )
    }, [itemResult])

    useEffect(() => {
        onFetch({variables: {siteId: searchData.siteId, regDt: moment().format("YYYY-MM-DD")}})
        onFlowFetch({variables: {siteId: searchData.siteId, regDt: moment().format("YYYY-MM-DD")}})
        onProjectFetch({variables: {data: {siteId: searchData.siteId, regDt: moment().format("YYYY-MM-DD")}}})
    }, [])

    return (
        <View
            title="일일 수질 분석 결과"
            tables={[
                <OperationDayTable
                    key="OperationDayTable"
                    items={data?.selectDiary?.diaryItems.filter(item => item.code.id < 120000)}
                    codes={codeResult.data?.selectCodeList.data?.find(code => code.id === 11)?.childCodes}
                    title="근무자 현황"
                    iconName="mans"
                />,
                <OperationDayTable1 key="OperationDayTable1" data={flowResult.data}/>,
                <OperationDayTable
                    key="OperationDayTable2"
                    items={data?.selectDiary?.diaryItems.filter(item => item.code.id > 120000)}
                    codes={codeResult.data?.selectCodeList.data?.find(code => code.id === 12)?.childCodes}
                    title="전력량 현황"
                    iconName="power"
                />,
                <OperationDayTable3
                    key="OperationDayTable3"
                    items={data?.chemicalForDay}
                    remains={data?.selectChemicalHistoryRemains}
                />,
                <OperationDayTable4 key="OperationDayTable4"
                                    project={project}
                                    items={itemResult.data?.selectItemList}
                                    samples={getSamples()}
                />,
            ]}
            utils={
                <SiteAndDayUtils onChangeSelector={_onChangeSelector} searchData={searchData}
                                 onChangeRegDt={_onChangeRegDt}/>
            }
        />
    )
}

export default OperationDay
