import React, {useCallback, useRef} from 'react'
import View from 'components/templete/view'
import useSearch, {getSearchInitData, onChangeType} from 'hooks/useSearch'
import {
    AnalysisProcessEnum,
    Items,
    ItemsVariables,
    ItemTypeEnum,
    Samples,
    SearchKindEnum,
    WaterMonth as WaterMonthQuery,
    WaterMonth_selectProjectByMonth,
    WaterMonthVariables,
} from 'types/graphql'
import {SelectorValueType} from 'types/common'
import WaterMonthTable from 'components/water/WaterMonthTable'
import {WATER_MONTH_QUERY} from 'queries/waterQuery'
import moment from 'moment'
import SiteAndMonthUtils from 'components/utils/SiteAndMonthUtils'
import {useQuery} from '@apollo/client'
import {ITEMS_QUERY} from 'queries/ItemQuery'
import {SAMPLES_QUERY} from 'queries/sampleQuery'
import WaterPrintTable from 'components/water/WaterPrintTable'
import {css} from '@emotion/react'
import * as XLSX from "xlsx";

function WaterMonth() {
    const printRef = useRef<HTMLDivElement>(null)
    const {searchData, onChange, onChangeSiteId, onNext, onPrev, getPrevMonth, getNextMonth} = useSearch(
        '/water/month',
        getSearchInitData(SearchKindEnum.GROUP_NAME),
    )
    const itemResult = useQuery<Items, ItemsVariables>(ITEMS_QUERY, {
        variables: {data: {type: [ItemTypeEnum.ITEM1]}},
    })
    const sampleResult = useQuery<Samples>(SAMPLES_QUERY)

    const {refetch, data} = useQuery<WaterMonthQuery, WaterMonthVariables>(WATER_MONTH_QUERY, {
        variables: {
            data: {
                siteId: Number(searchData.siteId),
                regDt: moment(`${searchData.year}-${searchData.month}`).format('YYYY-MM'),
            },
        },
    })

    const _onChangeSelector = useCallback(
        (value: SelectorValueType) => {
            onChangeSiteId(Number(value))
            _onRefetch({
                siteId: Number(value),
                regDt: moment(`${searchData.year}-${searchData.month}-01`).format('YYYY-MM'),
            })
        },
        [searchData.year, searchData.month],
    )
    const _onChange = useCallback(
        (type: keyof typeof onChangeType, value: string) => {
            onChange(type, value)
        },
        [searchData],
    )

    const _onChangeMonth = useCallback(
        (type: 'next' | 'prev') => () => {
            const isNext = type === 'next'
            const month = isNext ? getNextMonth() : getPrevMonth()

            _onRefetch({
                siteId: Number(searchData.siteId),
                regDt: `${searchData.year}-${month}`,
            })

            isNext ? onNext() : onPrev()
        },
        [searchData],
    )

    const _onRefetch = ({siteId, regDt}: { siteId: number; regDt: string }) => {
        refetch({
            data: {
                siteId,
                regDt,
            },
        })
    }

    const getProjects = useCallback(() => {
        let result: WaterMonth_selectProjectByMonth[][] = []
        if (data?.selectProjectByMonth) {
            const map = new Map<string, WaterMonth_selectProjectByMonth[]>()
            for (const project of data.selectProjectByMonth) {
                const findProjects = map.get(moment(project.regDt).format('YYYY-MM-DD'))
                if (findProjects) {
                    findProjects.push(project)
                    map.set(moment(project.regDt).format('YYYY-MM-DD'), findProjects)
                } else {
                    map.set(moment(project.regDt).format('YYYY-MM-DD'), [project])
                }
            }
            result = Array.from(map.values())
        }
        return result
    }, [data])

    const onDownloadCSV = useCallback(() => {
        const headersMap = getHeaders()
        const dataMap = getData()

        console.log({headersMap, dataMap})

        if (!headersMap || !dataMap) return

        const {headers, headersMerge} = headersMap
        const {data, sideMerge} = dataMap

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet([
            ...headers,
            ...data
        ]);

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        ws['!merges'] = [
            ...sideMerge,
            ...headersMerge
        ]
        XLSX.writeFile(wb, moment().format("YYYYMMDDHHmmss") + "-month.xlsx");
    }, [data, itemResult, sampleResult]);

    const getSamples = useCallback(() => {
        return sampleResult.data?.selectSampleList?.filter(sample => sample.analysisProcess === AnalysisProcessEnum.A2O)
    }, [sampleResult])

    const getData = useCallback(() => {
        let mergeIndex = 1
        const sideMerge = [{s: {r: 0, c: 0}, e: {r: 1, c: 0}}]
        const samples = getSamples()
        const items = itemResult.data?.selectItemList
        const result: string[][] = []

        if (!samples || !items) return

        getProjects().forEach((project) => {
            const size = project.length
            const start = mergeIndex + 1
            const end = mergeIndex + size
            mergeIndex = end

            sideMerge.push(
                {s: {r: start, c: 0}, e: {r: end, c: 0}},
            )

            project.forEach((data, idx) => {
                const initData = idx === 0 ? [moment((project[0].regDt)).format("MM월 DD일")] : [""]
                for (const sample of samples) {
                    for (const item of items) {
                        const findData = data.measurements.find(measurement =>
                            measurement.item.id === item.id && measurement.sample.name === sample.name,
                        )
                        const value = findData
                            ? Number(findData.ppm) === -1
                                ? '초과'
                                : Math.floor(findData.ppm * findData.dilution * 10) / 10
                            : '-'
                        initData.push(String(value))
                    }
                }
                result.push(initData)
            })
        })

        return {data: result, sideMerge}
    }, [itemResult, sampleResult, data])

    const getHeaders = useCallback(() => {
        const samples = getSamples()
        const items = itemResult.data?.selectItemList

        if (!samples || !items) return
        const topHeaders = ["측정일"]
        const bottomHeaders = [""]

        for (const sample of samples) {
            topHeaders.push(sample.name)
            topHeaders.push("")
            topHeaders.push("")
            topHeaders.push("")

            items.forEach(item => {
                bottomHeaders.push(item.name)
            })
        }

        const headersMerge = [
            {s: {r: 0, c: 1}, e: {r: 0, c: 4}},
            {s: {r: 0, c: 5}, e: {r: 0, c: 8}},
            {s: {r: 0, c: 9}, e: {r: 0, c: 12}},
            {s: {r: 0, c: 13}, e: {r: 0, c: 16}},
            {s: {r: 0, c: 17}, e: {r: 0, c: 20}},
            {s: {r: 0, c: 21}, e: {r: 0, c: 24}},
            {s: {r: 0, c: 25}, e: {r: 0, c: 28}}
        ]

        return {headers: [topHeaders, bottomHeaders], headersMerge}
    }, [sampleResult, itemResult])

    return (
        <>
            <div ref={printRef} css={printStyle}>
                <h1>{'<월간 보고서>'}</h1>
                <WaterPrintTable
                    projects={getProjects()}
                    items={itemResult.data?.selectItemList}
                    samples={getSamples()}
                />
            </div>
            <View
                title="월간 분석"
                tables={[
                    <WaterMonthTable
                        key="WaterMonthTable"
                        items={itemResult.data?.selectItemList}
                        samples={sampleResult.data?.selectSampleList}
                        projects={getProjects()}
                    />,
                ]}
                utils={
                    <SiteAndMonthUtils
                        onChangeSelector={_onChangeSelector}
                        searchData={searchData}
                        onChange={_onChange}
                        onNext={_onChangeMonth('next')}
                        onPrev={_onChangeMonth('prev')}
                        graphUrl={`/water/month/graph?year=${searchData.year}&month=${searchData.month}&siteId=${searchData.siteId}`}
                        printRef={printRef}
                        onDownloadCSV={onDownloadCSV}
                    />
                }
            />
        </>
    )
}

export default WaterMonth

const printStyle = css`
  flex-direction: column;
  padding: 40px;
  display: none;

  & > h1 {
    font-size: 40px;
    text-align: center;
  }

  @media print {
    display: flex;
  }
`
