import { ApolloClient, InMemoryCache } from '@apollo/client'
import config from './config'
import { createUploadLink } from 'apollo-upload-client'

const client = new ApolloClient({
	connectToDevTools: config.nodeEnv === 'development',
	credentials: 'include',
	// link: createHttpLink({ uri: config.APP.BACK_END_URL, credentials: 'include' }),
	// @ts-ignore
	link: createUploadLink({
		uri: '/graphql',
		credentials: 'include',
	}),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'network-only',
			errorPolicy: 'ignore',
		},
		query: {
			fetchPolicy: 'network-only',
			errorPolicy: 'all',
		},
	},
	cache: new InMemoryCache({
		typePolicies: {
			Query: {
				fields: {
					selectSludgeList: {
						merge(existing, incoming) {
							return incoming
						},
					},
				},
			},
		},
	}),
})

export default client
