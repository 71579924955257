import { gql } from '@apollo/client'

export const CREATE_FACILITY = gql`
	mutation CreateFacility($data: InsertFacilityInput!) {
		insertFacility(data: $data ) {
			id
		}
	}
`

export const FACILITIES_QUERY = gql`
	query Facilities($data: SelectFacilityListInputByWeb!) {
		selectFacilityListByWeb(data: $data){
			facilities {
				id,
				dateOfReceipt,
				regDt,
				completedDate,
				locationCode {
					id,
					name
				}
				titleCode {
					id,
					name,
				}
				company,
				detail,
				cost,
				amount,
				memo
			}
			paging {
				lastPageNo,
				pageNo,
				endNo,
				skip,
				startNo,
				totalCount
			}
		}
	}
`

export const FACILITY_QUERY = gql`
	query Facility($data: SelectFacilityInput!) {
		selectFacility(data: $data) {
			id
			dateOfReceipt,
			regDt,
			completedDate,
			locationCode {
				id,
				name
			}
			titleCode {
				id,
				name,
			}
			site {
				id,
				name
			}
			company,
			detail,
			cost,
			amount,
			memo
		}
	}
`

export const UPDATE_FACILITY_QUERY = gql`
	mutation UpdateFacility($data: UpdateFacilityInput!) {
		updateFacility(data: $data) {
			data
		}
	}
`

export const FACILITY_OPERATION_QUERY = gql`
	query FacilityOperation($data: GetCountInput!) {
		selectCountBySiteIdAndYear(data: $data) {
			sludges {
				date
				count
			}
			facilities {
				date
				count
			}
			diaries {
				date
				count
			}
			influentFlows {
				date
				count
			}
			dailyChecks {
				date,
				count
			}
			projects {
				date,
				count
			}
		}
	}
`
