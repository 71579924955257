import React from 'react'
import { SearchType } from 'hooks/useSearch'
import { SelectorType, SelectorValueType } from 'types/common'
import Selector from 'components/selector/Selector'
import Button from 'components/button'
import { NavLink } from 'react-router-dom'
import palette from 'styles/palette'
import DateInput from 'components/input/DateInput'
import SiteStore from 'stores/SiteStore'
import ReactToPrint from 'react-to-print'

type Props = {
	onChangeSelector(value: SelectorValueType): void
	searchData: SearchType
	onChangeRegDt(date: Date): void
	onClick?(e: React.MouseEvent<HTMLButtonElement>): void
	graphUrl?: string
	printRef?: React.RefObject<HTMLDivElement>
	onTogglePrint?(): void
}

function SiteAndDayUtils({
	onChangeSelector,
	searchData,
	onChangeRegDt,
	onClick,
	graphUrl,
	printRef,
	onTogglePrint,
}: Props) {
	return (
		<>
			<Selector
				options={[
					{ label: '휴게소를 선택해 주세요.', value: -1 },
					...(SiteStore.sites
						? SiteStore.sites.map(
								site =>
									({
										label: site.name,
										value: site.id,
									} as SelectorType),
						  )
						: []),
				]}
				values={[String(searchData.siteId)]}
				onChange={onChangeSelector}
				css={{ height: '100%', margin: '0 1rem', width: '24rem' }}
			/>
			<DateInput css={{ marginLeft: '1rem', marginRight: '1rem' }} onChange={onChangeRegDt} value={searchData.regDt} />
			{graphUrl && (
				<NavLink to={graphUrl}>
					<Button
						label="그래프 보기"
						backgroundColor={palette.grey['primary']}
						primary
						css={{ width: '10rem', padding: '1rem 0', marginRight: '1rem' }}
					/>
				</NavLink>
			)}
			{printRef && (
				<ReactToPrint
					trigger={() => (
						<Button
							label="인쇄"
							backgroundColor={palette.grey['primary']}
							primary
							css={{ width: '10rem', padding: '1rem 0' }}
						/>
					)}
					content={() => printRef.current}
				/>
			)}
			{onTogglePrint && (
				<Button
					label="인쇄"
					onClick={onTogglePrint}
					backgroundColor={palette.grey['primary']}
					primary
					css={{ width: '10rem', padding: '1rem 0' }}
				/>
			)}
			{onClick && (
				<Button
					label="저장하기"
					onClick={onClick}
					backgroundColor={palette.grey['primary']}
					primary
					css={{ width: '10rem', padding: '1rem 0' }}
				/>
			)}
		</>
	)
}

export default SiteAndDayUtils
