import { gql } from '@apollo/client'

export const ITEMS_QUERY = gql`
	query Items($data: SelectItemListInput!) {
		selectItemList(data: $data) {
			id
			name
			measure
			type
			ord
		}
	}
`
