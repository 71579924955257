import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { SelectorValueType } from 'types/common'

function useInput<T>(initState: T): [
	T,
	Dispatch<SetStateAction<T>>,
	{
		onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void
		onChangeSelector: (e: React.ChangeEvent<HTMLSelectElement>) => void
		onChangeInputByValue: (type: string) => (value: any) => void
		onChangeSelectorByObjectId: (type: string) => (value: any) => void
		onChangeSelectorByValue: (type: string) => (value: SelectorValueType) => void
	},
] {
	const [state, setState] = useState<T>(initState)

	const onChangeInput = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setState({ ...state, [e.target.name]: e.target.value })
		},
		[state],
	)

	const onChangeInputByValue = useCallback(
		(type: string) => (value: any) => {
			setState({ ...state, [type]: value })
		},
		[state],
	)

	const onChangeSelectorByValue = useCallback(
		(type: string) => (value: SelectorValueType) => {
			setState({ ...state, [type]: value })
		},
		[state],
	)

	const onChangeSelector = useCallback(
		(e: React.ChangeEvent<HTMLSelectElement>) => {
			setState({ ...state, [e.target.name]: e.target.value })
		},
		[state],
	)

	const onChangeSelectorByObjectId = useCallback(
		(type: string) => (value: SelectorValueType) => {
			setState({ ...state, [type]: { id: Number(value), name: '' } })
		},
		[state],
	)

	return [
		state,
		setState,
		{ onChangeInput, onChangeInputByValue, onChangeSelectorByValue, onChangeSelector, onChangeSelectorByObjectId },
	]
}

export default useInput
