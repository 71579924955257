import {action, observable} from 'mobx'
import {MENU_DATA, MenuDataType} from 'configs/menuData'
import moment from 'moment'

class MenuStore {
	@observable menuData: MenuDataType[] = []

	constructor() {
		this.checkMenuData()
	}

	/**
	 * URL 변경시 실행되야 하는 함수
	 * @param id
	 */
	@action
	setting = (id: number) => {
		this.menuData = this.menuData.map(menu => {
			const findMenu = menu.child?.find(child => child.id === id)
			return this.getChildByBoolean(menu.id === id || !!findMenu, menu, id)
		})
		localStorage.setItem('menu', JSON.stringify({ menus: this.menuData, expire: moment().format('YYYYMMDDHHmmss') }))
	}

	/**
	 * 자식 메뉴 세팅해서 리턴 해주는 함수
	 * @param childMenus
	 * @param id
	 */
	@action
	getSettingChild = (childMenus: MenuDataType[], id: number): MenuDataType[] | undefined => {
		return childMenus.map(menu => {
			return this.getChildByBoolean(menu.id === id, menu, id)
		})
	}

	/**
	 * MenuData 확인하는 함수
	 * localStorage.menu 없다면 세팅
	 * menuData 없다면 세팅
	 */
	@action
	checkMenuData = () => {
		if (!localStorage.getItem('menu')) {
			localStorage.setItem('menu', JSON.stringify({ menus: MENU_DATA, expire: moment().format('YYYYMMDDHHmmss') }))
		}
		const storageObject = JSON.parse(localStorage.getItem('menu') || '')
		console.log(Number(moment().format('YYYYMMDDHHmmss')) - Number(storageObject.expire))
		const isExpire = Number(moment().format('YYYYMMDDHHmmss')) - Number(storageObject.expire) > 200
		if (!this.menuData.length && !isExpire) {
			this.menuData = storageObject.menus
			return
		}

		if (isExpire) {
			localStorage.setItem('menu', JSON.stringify({ menus: MENU_DATA, expire: moment().format('YYYYMMDDHHmmss') }))
		}
		this.menuData = MENU_DATA
	}

	/**
	 * 선택된 메뉴 조회
	 */
	@action
	getChildBySelected = () => {
		return this.menuData.filter(menu => menu.isSelected)[0]?.child?.find(child => child.isSelected)
	}

	@action
	getMenu = (user: any) => {
		return this.menuData.find(menu => {
			return menu.isSelected && (user ? user.role.id <= menu.role : false)
		})
	}

	/**
	 * result 값에 따라 isSelected 분기 함수
	 * @param result
	 * @param menu
	 * @param id
	 */
	private getChildByBoolean = (result: boolean, menu: MenuDataType, id: number) => {
		return result
			? {
					...menu,
					isSelected: true,
					child: this.getSettingChild(menu.child || [], id),
			  }
			: {
					...menu,
					isSelected: false,
			  }
	}
}

export default new MenuStore()
