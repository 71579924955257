import React from 'react'
import Table from 'components/table'
import TitleTable from 'components/templete/titleTable'
import { textStyle } from 'components/table/Table'
import { Codes_selectCodeList_data_childCodes, DailyOperation_selectDiary_diaryItems } from 'types/graphql'
import { IconType } from 'components/Icon/Icon'

type Props = {
	items?: DailyOperation_selectDiary_diaryItems[]
	codes?: Codes_selectCodeList_data_childCodes[]
	title: string
	iconName: IconType
}

const OperationDayTable: React.FC<Props> = ({ items, codes, title, iconName }) => {
	return (
		<TitleTable title={title} name={iconName} style={{ marginBottom: '2.4rem' }}>
			<Table>
				<Table.Row bgColor="#E6E8EE">
					{codes?.map(code => (
						<Table.Col flex={1} key={code.id + '2Table'}>
							<span css={textStyle}>{code.name}</span>
						</Table.Col>
					))}
				</Table.Row>
				<Table.Row>
					{codes?.map(code => {
						const findItem = items?.find(item => item.code.id === code.id)
						return (
							<Table.Col flex={1} key={code.id}>
								<span css={textStyle}>{findItem?.value}</span>
							</Table.Col>
						)
					})}
				</Table.Row>
			</Table>
		</TitleTable>
	)
}
export default OperationDayTable
