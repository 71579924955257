import React from 'react'
import Table from 'components/table'
import { textStyle } from 'components/table/Table'
import moment from 'moment'
import _ from 'lodash'
import { Flows_selectFlowList, Flows_selectInfluentFlowList } from 'types/graphql'

type Props = {
	data?: Flows_selectInfluentFlowList[]
	date: moment.Moment
	flows?: Flows_selectFlowList[]
}

const FlowMonthTable: React.FC<Props> = ({ data, date, flows }) => {
	return (
		<Table>
			<Table.Row bgColor="#E6E8EE">
				<Table.Col flex={8}>
					<span css={textStyle}>날짜</span>
				</Table.Col>
				<Table.Col flex={92} style={{ padding: 0, borderRight: 0, borderLeft: 0 }}>
					{flows?.map(flow => {
						return (
							<Table.Col flex={12} key={flow.id}>
								<span css={textStyle}>{flow.name}</span>
							</Table.Col>
						)
					})}
				</Table.Col>
			</Table.Row>
			{_.range(Number(date.startOf('month').format('DD')), Number(date.endOf('month').format('DD')) + 1)
				.filter(day => {
					const findData = data?.find(flow => Number(moment(flow.regDt).format('DD')) === day)
					return !!findData
				})
				.map(day => {
					const findData = data?.filter(flow => Number(moment(flow.regDt).format('DD')) === day)
					return (
						<Table.Row key={day}>
							<Table.Col flex={8}>
								<span css={textStyle}>{day}일</span>
							</Table.Col>
							<Table.Col flex={92} style={{ padding: 0, borderRight: 0, borderLeft: 0, flexDirection: 'column' }}>
								{findData?.map((influentFlow, index) => {
									const rowStyle =
										index === 0 ? { borderTop: 0, borderBottom: 0, width: '100%' } : { borderBottom: 0, width: '100%' }
									return (
										<Table.Row style={rowStyle} key={influentFlow.id}>
											{flows?.map(flow => {
												const findItem = influentFlow.influentFlowItems.find(item => item.flow.id === flow.id)
												return (
													<Table.Col flex={12} key={flow.id + influentFlow.id}>
														<span css={textStyle}>{findItem ? findItem.result : 0}</span>
													</Table.Col>
												)
											})}
										</Table.Row>
									)
								})}
							</Table.Col>
						</Table.Row>
					)
				})}
		</Table>
	)
}
export default FlowMonthTable
