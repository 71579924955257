import { gql } from '@apollo/client'

export const SAMPLES_QUERY = gql`
	query Samples {
		selectSampleList {
			id
			name
			analysisProcess
		}
	}
`
