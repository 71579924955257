import {
	FlowMeasure,
	FlowMeasure_selectInfluentFlow,
	FlowMeasure_selectInfluentFlow_influentFlowItems,
	FlowMeasure_selectInfluentFlow_site,
	FlowMeasure_selectInfluentFlowRecently,
	FlowMeasure_selectTotalToRegDt,
	InsertInfluentFlowInput,
	Me_me,
} from 'types/graphql'

export default class InfluentFlowDto implements FlowMeasure_selectInfluentFlow {
	__typename: 'InfluentFlowEntity'
	id: number
	influentFlowItems: FlowMeasure_selectInfluentFlow_influentFlowItems[]
	inspector: string
	regDt: any
	site: FlowMeasure_selectInfluentFlow_site | null

	itemsByYear?: FlowMeasure_selectInfluentFlow_influentFlowItems[]
	itemsByMonth?: FlowMeasure_selectInfluentFlow_influentFlowItems[]
	itemsByDay?: FlowMeasure_selectInfluentFlow_influentFlowItems[]
	itemsByOrigin?: FlowMeasure_selectInfluentFlow_influentFlowItems[]
	recentlyRegDt: any
	userId?: number

	constructor(data?: FlowMeasure) {
		this.__typename = 'InfluentFlowEntity'
		this.id = data?.selectInfluentFlow ? data.selectInfluentFlow.id : 0
		this.influentFlowItems = data ? this.getItem(data) : []
		this.inspector = data?.selectInfluentFlow ? data.selectInfluentFlow.inspector : ''
		this.regDt = data?.selectInfluentFlow ? data.selectInfluentFlow.regDt : ''
		this.site = data?.selectInfluentFlow ? data.selectInfluentFlow.site : { __typename: 'SiteEntity', name: '', id: 0 }
		this.itemsByOrigin = data ? this.getItem(data) : []
		this.itemsByYear = this.getItemsByYear(data?.selectTotalToRegDt)
		this.itemsByMonth = this.getItemsByMonth(data?.selectTotalToRegDt)
		this.itemsByDay = this.getItemsByDay(data?.selectInfluentFlowRecently)
	}

	setUser(user: Me_me) {
		this.userId = user.id
		this.inspector = user.name
	}

	getItem({ selectFlowList, selectInfluentFlow }: FlowMeasure): FlowMeasure_selectInfluentFlow_influentFlowItems[] {
		return selectFlowList.map(flow => {
			const findItem = selectInfluentFlow?.influentFlowItems.find(item => item.flow.id === flow.id)
			return {
				__typename: 'InfluentFlowItemEntity',
				flow: { __typename: 'FlowEntity', id: flow.id },
				result: findItem ? findItem.result : 0,
			}
		})
	}

	getItemsByYear(
		selectTotalToRegDt?: FlowMeasure_selectTotalToRegDt | null,
	): FlowMeasure_selectInfluentFlow_influentFlowItems[] | undefined {
		return selectTotalToRegDt?.year.map(item => {
			return {
				__typename: 'InfluentFlowItemEntity',
				flow: { __typename: 'FlowEntity', id: item.id },
				result: item.result,
			}
		})
	}

	getItemsByMonth(
		selectTotalToRegDt?: FlowMeasure_selectTotalToRegDt | null,
	): FlowMeasure_selectInfluentFlow_influentFlowItems[] | undefined {
		return selectTotalToRegDt?.month.map(item => {
			return {
				__typename: 'InfluentFlowItemEntity',
				flow: { __typename: 'FlowEntity', id: item.id },
				result: item.result,
			}
		})
	}

	getItemsByDay(
		selectInfluentFlowRecently?: FlowMeasure_selectInfluentFlowRecently | null,
	): FlowMeasure_selectInfluentFlow_influentFlowItems[] | undefined {
		this.recentlyRegDt = selectInfluentFlowRecently?.regDt
		return selectInfluentFlowRecently?.influentFlowItems.map(item => {
			return {
				__typename: 'InfluentFlowItemEntity',
				flow: { __typename: 'FlowEntity', id: item.flow.id },
				result: item.result,
			}
		})
	}

	getCreate(): InsertInfluentFlowInput {
		return {
			id: this.id,
			userId: this.userId || 0,
			siteId: this.site?.id || 0,
			inspector: this.inspector,
			influentFlowItems: this.influentFlowItems.map(item => ({
				flowId: item.flow.id,
				result: Number(item.result),
			})),
		}
	}
}
