import React from 'react'
import Button from 'components/button'
import palette from 'styles/palette'

type Props = {
	onClick?(): void
	writeTitle?: string
	onDelete?(): void
	printRef?: React.RefObject<HTMLDivElement>
}

function WriteUtils({ onClick, printRef, onDelete, writeTitle }: Props) {
	console.log(onClick)
	return (
		<>
			{onClick && (
				<Button
					label={writeTitle || '등록하기'}
					onClick={onClick}
					backgroundColor={palette.grey['primary']}
					primary
					css={{ width: '10rem', padding: '1rem 0', marginRight: '1rem' }}
				/>
			)}

			{onDelete && (
				<Button
					label="삭제"
					onClick={onDelete}
					backgroundColor={palette.grey['primary']}
					primary
					css={{ width: '10rem', padding: '1rem 0', marginRight: '1rem' }}
				/>
			)}

			{printRef && (
				<Button
					label="인쇄"
					backgroundColor={palette.grey['primary']}
					primary
					css={{ width: '10rem', padding: '1rem 0' }}
				/>
			)}
		</>
	)
}

export default WriteUtils
