import { gql } from '@apollo/client'

export const MEDICINE_REMAINS_QUERY = gql`
	query MedicineRemains($data: SelectChemicalHistoryListInput!) {
		selectChemicalHistoryRemains(data: $data) {
			item {
				id
				name
			}
			remains
		}
	}
`

export const CREATE_MEDICINE_QUERY = gql`
	mutation CreateMedicine($data: InsertChemicalHistoryInput!) {
		insertChemicalHistory(data: $data) {
			id
		}
	}
`

export const MEDICINE_QUERY = gql`
	query Medicine($data: SelectChemicalHistoryListInput!) {
		chemicalForDay(data: $data) {
			id
			regDt
			orderAmount
			useAmount
			site {
				id
				name
			}
			item {
				id
				measure
				name
			}
		}
	}
`

export const MEDICINE_MONTH_QUERY = gql`
	query MedicineMonth($data: SelectManagementInput!) {
		selectChemicalHistoryManagement(data: $data) {
			id
			regDt
			orderAmount
			useAmount
			site {
				id
				name
			}
			item {
				id
				measure
				name
			}
		}
	}
`
