import NoticeWriteTable from 'components/notice/NoticeWriteTable'
import View from 'components/templete/view'
import WriteUtils from 'components/utils/WriteUtils'
import useUpdateNotice from 'hooks/notice/useUpdateNotice'
import React from 'react'

type props = {
	id: number
}

function NoticeUpdate({ id }: props) {
	const { notice, onSave, onChangeInput, onChangeContent } = useUpdateNotice(id)

	if (!notice) return null

	return (
		<View
			title="공지사항"
			tables={[
				<NoticeWriteTable
					key="NoticeWriteTable"
					notice={notice}
					onChangeInput={onChangeInput}
					onChangeContent={onChangeContent}
				/>,
			]}
			utils={<WriteUtils onClick={onSave} writeTitle="수정하기" />}
		/>
	)
}

export default NoticeUpdate
