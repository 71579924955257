import React, { useCallback, useMemo } from 'react'
import useSearch, { getSearchInitData } from 'hooks/useSearch'
import { Flows, FlowsVariables, SearchKindEnum } from 'types/graphql'
import { SelectorValueType } from 'types/common'
import View from 'components/templete/view'
import FlowMonthTable from 'components/flow/FlowMonthTable'
import useCustomQuery from 'hooks/useCustomQuery'
import { FLOWS_QUERY } from 'queries/flowQuery'
import moment from 'moment'
import SiteAndMonthUtils from 'components/utils/SiteAndMonthUtils'

function FlowMonth() {
	const { searchData, onChange, onChangeSiteId, onNext, onPrev, getUrlByPageNo, getPrevMonth, getNextMonth } =
		useSearch('/flow/month', getSearchInitData(SearchKindEnum.GROUP_NAME))
	const { onRefetch, data } = useCustomQuery<Flows, FlowsVariables>(
		FLOWS_QUERY,
		{
			data: {
				siteId: searchData.siteId,
				toRegDt: moment(searchData.regDt).format('YYYY-MM-') + '31',
				fromRegDt: moment(searchData.regDt).format('YYYY-MM-')+'01',
			},
		},
		false,
	)

	const _onChangeSelector = useCallback(
		(value: SelectorValueType) => {
			const regDt = moment(`${searchData.year}-${searchData.month}-01`)
			onChangeSiteId(Number(value))
			_onRefetch({
				siteId: Number(value),
				toRegDt: regDt.format('YYYY-MM-') + '31',
				fromRegDt: regDt.format('YYYY-MM-DD'),
			})
		},
		[searchData.year, searchData.month],
	)
	const onChangeType = getSearchInitData(SearchKindEnum.MANAGER)
	const _onChange = useCallback((type: keyof typeof onChangeType, value: string) => {
		onChange(type, value)
	}, [])

	const _onChangeMonth = useCallback(
		(type: 'next' | 'prev') => () => {
			const isNext = type === 'next'
			const month = isNext ? getNextMonth() : getPrevMonth()
			const regDt = moment(`${searchData.year}-${month}-01`)

			_onRefetch({
				siteId: Number(searchData.siteId),
				toRegDt: regDt.format('YYYY-MM-') + '31',
				fromRegDt: regDt.format('YYYY-MM-DD'),
			})

			isNext ? onNext() : onPrev()
		},
		[searchData],
	)

	const _onRefetch = ({ siteId, toRegDt, fromRegDt }: { siteId: number; toRegDt: string; fromRegDt: string }) => {
		onRefetch({
			data: {
				siteId,
				toRegDt,
				fromRegDt,
			},
		})
	}

	const _renderTable = useMemo(
		() => (
			<FlowMonthTable
				key="flowMonthTable"
				data={data?.selectInfluentFlowList}
				flows={data?.selectFlowList}
				date={moment(`${searchData.year}-${searchData.month}-01`)}
			/>
		),
		[searchData.year, searchData.month, data],
	)

	const _renderUtil = useMemo(
		() => (
			<SiteAndMonthUtils
				onChangeSelector={_onChangeSelector}
				searchData={searchData}
				onChange={_onChange}
				onNext={_onChangeMonth('next')}
				onPrev={_onChangeMonth('prev')}
			/>
		),
		[searchData],
	)

	return <View title="월간 유량측정" tables={[_renderTable]} utils={_renderUtil} baseUrl={getUrlByPageNo} />
}

export default FlowMonth
