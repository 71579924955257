import React, {useCallback, useState} from 'react'
import MedicineDto from 'dtos/medicine/MedicineDto'
import useCustomMutation from 'hooks/useCustomMutation'
import {CREATE_MEDICINE_QUERY, MEDICINE_QUERY} from 'queries/medicineQuery'
import {
    CreateMedicine,
    CreateMedicineVariables,
    InsertChemicalHistory,
    Items,
    Items_selectItemList,
    ItemsVariables,
    ItemTypeEnum,
    Medicine,
    MedicineVariables,
} from 'types/graphql'
import {QueryLazyOptions, useLazyQuery, useQuery} from '@apollo/client'
import {ITEMS_QUERY} from 'queries/ItemQuery'

function useCreateMedicine(): [
    (options?: (QueryLazyOptions<MedicineVariables> | undefined)) => void,
    {
        setMedicine: React.Dispatch<React.SetStateAction<MedicineDto[]>>,
        onChangeInput: (e: React.ChangeEvent<HTMLInputElement>) => void,
        onSave: (siteId: number) => () => void,
        medicines: MedicineDto[],
        items: Items_selectItemList[] | undefined
    }
] {
    const [medicines, setMedicine] = useState<MedicineDto[]>([])
    const [items, setItems] = useState<Items_selectItemList[]>([])
    const [onSave] = useCustomMutation<CreateMedicine, CreateMedicineVariables>(CREATE_MEDICINE_QUERY)
    const [refetch] = useLazyQuery<Medicine, MedicineVariables>(MEDICINE_QUERY, {
        onCompleted: ({chemicalForDay}) => {
            if (chemicalForDay.length === 0) setMedicine(
                items.map(item => {
                    return Object.assign(new MedicineDto(), {
                        item: {id: item.id, name: item.name, __typename: 'ItemEntity'},
                    })
                }),
            )
            else setMedicine(chemicalForDay.map(data => new MedicineDto(data)))
        },
    })
    const {data} = useQuery<Items, ItemsVariables>(ITEMS_QUERY, {
        variables: {data: {type: [ItemTypeEnum.ITEM1, ItemTypeEnum.ITEM2]}},
        onCompleted: ({selectItemList}) => {
            if (selectItemList) {
                setItems(selectItemList)
                setMedicine(
                    selectItemList.map(item => {
                        return Object.assign(new MedicineDto(), {
                            item: {id: item.id, name: item.name, __typename: 'ItemEntity'},
                        })
                    }),
                )
            }
        },
    })

    const _onSave = useCallback((siteId: number) => () => {
        const chemicals: InsertChemicalHistory[] = medicines.map(data => data.getCreateDto(siteId))
        onSave({data: {chemicals}})
    }, [medicines])

    const onChangeInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const name = e.target.name as 'orderAmount' | 'useAmount'
            setMedicine(
                medicines.map(data => {
                    return Number(e.target.id) === data.item.id ? Object.assign(data, {[name]: Number(e.target.value)}) : data
                }),
            )
        },
        [medicines],
    )

    return [
        refetch, {
            onSave: _onSave,
            items: data?.selectItemList,
            medicines,
            setMedicine,
            onChangeInput
        }
    ]
}

export default useCreateMedicine
