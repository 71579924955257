import { css } from '@emotion/react'
import { Input } from 'components/input'
import Table from 'components/table'
import { textStyle } from 'components/table/Table'
import React from 'react'
import palette from 'styles/palette'
import { Notice_selectNotice } from 'types/graphql'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

type Props = {
	notice?: Notice_selectNotice
}

function NoticeReadTable({ notice }: Props) {
	return (
		<Table>
			<Table.Row>
				<Table.Col flex={20} bgColor={palette.tableHeadColor}>
					<span css={textStyle}>제목</span>
				</Table.Col>
				<Table.Col flex={80}>
					<Input value={notice ? notice.title : ''} disabled />
				</Table.Col>
			</Table.Row>
			<Table.Row>
				<Table.Col flex={20} bgColor={palette.tableHeadColor}>
					<span css={textStyle}>내용</span>
				</Table.Col>
				<Table.Col flex={80}>
					<ReactQuill css={TextAreaStyle} value={notice ? notice.content : ''} readOnly={true} />
				</Table.Col>
			</Table.Row>
		</Table>
	)
}

export default NoticeReadTable

const TextAreaStyle = css`
	width: 100%;
	min-height: 400px;
	font-size: 16px;

	& div.ql-toolbar {
		display: none;
	}
	.ql-toolbar.ql-snow + .ql-container.ql-snow {
		border: 0;
	}
	div.ql-editor {
		p {
			font-size: 14px;
		}
	}
`
