import { IconType } from 'components/Icon/Icon'

export const MENU_DATA = [
	{
		id: 1,
		url: '/water/day',
		title: '수질 분석',
		isSelected: false,
		role: 10006,
		icon: 'medicineResult',
		child: [
			{
				id: 10001,
				url: '/water/day',
				title: '수질 분석',
				isSelected: false,
				role: 10006,
				icon: 'water',
				child: [
					{
						id: 11001,
						url: '/water/day',
						title: '일일 분석',
						isSelected: false,
						role: 10006,
						icon: 'water',
					},
					{
						id: 11002,
						url: '/water/month',
						title: '월간 분석',
						isSelected: false,
						role: 10006,
						icon: 'water',
					},
					{
						id: 11003,
						url: '/water/count',
						title: '분석 횟수',
						isSelected: false,
						role: 10006,
						icon: 'water',
					},
				],
			},
		],
	},
	{
		id: 2,
		url: '/operation/day',
		title: '운영 관리',
		isSelected: false,
		role: 10006,
		icon: 'operationMng',
		child: [
			{
				id: 20001,
				url: '/operation/day',
				title: '일일 운영 일지',
				isSelected: false,
				role: 10002,
				icon: 'dailyOperation',
				child: [
					{
						id: 21001,
						url: '/operation/day',
						title: '일지 조회',
						isSelected: false,
						role: 10002,
						icon: 'dailyOperation',
					},
					{
						id: 21002,
						url: '/operation/write',
						title: '일지 등록',
						isSelected: false,
						role: 10002,
						icon: 'dailyOperation',
					},
					{
						id: 21003,
						url: '/operation/check',
						title: '체크리스트',
						isSelected: false,
						role: 10002,
						icon: 'daily',
					},
				],
			},
			{
				id: 20003,
				url: '/medicine/stock',
				title: '약품 사용량',
				isSelected: false,
				role: 10002,
				icon: 'medicineMng',
				child: [
					{
						id: 23001,
						url: '/medicine/stock',
						title: '약품 재고량',
						isSelected: false,
						role: 10002,
						icon: 'medicineMng',
					},
					{
						id: 23002,
						url: '/medicine/write',
						title: '약품 구입/사용량 등록',
						isSelected: false,
						role: 10002,
						icon: 'medicineMng',
					},
					{
						id: 23003,
						url: '/medicine/month',
						title: '월간 사용량',
						isSelected: false,
						role: 10002,
						icon: 'medicineMng',
					},
				],
			},
			{
				id: 20004,
				url: '/sludge/write',
				title: '슬러지 처리량',
				isSelected: false,
				role: 10002,
				icon: 'sludge',
				child: [
					{
						id: 24001,
						url: '/sludge/write',
						title: '일일 슬러지 처리량 등록',
						isSelected: false,
						role: 10002,
						icon: 'sludge',
					},
					{
						id: 24002,
						url: '/sludge/month',
						title: '월간 슬러지 처리량',
						isSelected: false,
						role: 10002,
						icon: 'sludge',
					},
					{
						id: 24003,
						url: '/sludge/year',
						title: '연간 슬러지 처리량',
						isSelected: false,
						role: 10002,
						icon: 'sludge',
					},
				],
			},
			{
				id: 20005,
				url: '/flow/day',
				title: '유량측정',
				isSelected: false,
				role: 10006,
				icon: 'flowMng',
				child: [
					{
						id: 25001,
						url: '/flow/day',
						title: '일일 측정',
						isSelected: false,
						role: 10006,
						icon: 'flowMng',
					},
					{
						id: 25002,
						url: '/flow/month',
						title: '월간 측정',
						isSelected: false,
						role: 10006,
						icon: 'flowMng',
					},
					{
						id: 25003,
						url: '/flow/count',
						title: '분석 횟수',
						isSelected: false,
						role: 10006,
						icon: 'flowMng',
					},
				],
			},
			{
				id: 20006,
				url: '/facility/count',
				title: '월간 운영현황',
				isSelected: false,
				role: 10002,
				icon: 'stockMng',
			},
		],
	},
	{
		id: 3,
		url: '/facility/repair',
		title: '시설 현황',
		isSelected: false,
		role: 10002,
		icon: 'factoryOperation',
		child: [
			{
				id: 30001,
				url: '/facility/repair',
				title: '시설물 등록/수리',
				isSelected: false,
				role: 10002,
				icon: 'factory',
			},
		],
	},
	{
		id: 4,
		url: '/user',
		title: '계정 관리',
		isSelected: false,
		role: 10001,
		icon: 'settingMng',
		child: [
			{
				id: 40001,
				url: '/user',
				title: '계정 관리',
				isSelected: false,
				role: 10001,
				icon: 'accountMng',
			},
		],
	},
	{
		id: 5,
		url: '/notice',
		title: '공지사항',
		isSelected: false,
		role: 10006,
		icon: 'infoCircle',
		child: [
			{
				id: 40002,
				url: '/notice',
				title: '공지사항',
				isSelected: false,
				role: 10006,
				icon: 'notice',
			},
		],
	},
] as MenuDataType[]

export type MenuDataType = {
	id: number
	url: string
	title: string
	isSelected: boolean
	role: number
	icon: IconType
	child?: MenuDataType[]
}
