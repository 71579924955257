import React from 'react'
import ChartWithLine from 'components/chart/ChartWithLine'
import { css } from '@emotion/react'
import CheckBoxGroup from 'components/checkBox/CheckBoxGroup'

type Props = {
	samples?: any[]
	data: any[]
	onToggleSample(sampleId: number): void
}

const WaterMonthGraphTable: React.FC<Props> = ({ samples, data, onToggleSample }) => {
	return (
		<div css={ContainerStyle}>
			<div css={SelectBoxContainerStyle}>
				{samples?.map(sample => (
					<CheckBoxGroup
						label={sample.name}
						value={sample.isSelected}
						onToggle={() => onToggleSample(sample.id)}
						key={sample.id}
					/>
				))}
			</div>
			<ChartWithLine data={data} />
		</div>
	)
}
export default WaterMonthGraphTable

const ContainerStyle = css`
	border: 1px solid #000;
	background-color: #fff;
	padding: 1rem;
`

const SelectBoxContainerStyle = css``
