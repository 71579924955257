import React from 'react'
import Table from 'components/table'
import { textStyle } from 'components/table/Table'
import { WaterCount_selectProjectCountBySiteIdAndMonth } from 'types/graphql'

type Props = {
	data?: WaterCount_selectProjectCountBySiteIdAndMonth[]
}
const WaterCountTable: React.FC<Props> = ({ data }) => {
	return (
		<Table>
			<Table.Row bgColor="#E6E8EE">
				<Table.Col flex={10}>
					<span css={textStyle}>{''}</span>
				</Table.Col>
				<Table.Col flex={30}>
					<span css={textStyle}>장소</span>
				</Table.Col>
				<Table.Col flex={30}>
					<span css={textStyle}>점검자</span>
				</Table.Col>
				<Table.Col flex={30}>
					<span css={textStyle}>분석 횟수</span>
				</Table.Col>
			</Table.Row>
			{data?.map((project, index) => (
				<Table.Row key={index}>
					<Table.Col flex={10}>
						<span css={textStyle}>{index + 1}</span>
					</Table.Col>
					<Table.Col flex={30}>
						<span css={textStyle}>{project.site.name}</span>
					</Table.Col>
					<Table.Col flex={30}>
						<span css={textStyle}>{project.user.name}</span>
					</Table.Col>
					<Table.Col flex={30}>
						<span css={textStyle}>{project.count}회</span>
					</Table.Col>
				</Table.Row>
			))}
		</Table>
	)
}
export default WaterCountTable
