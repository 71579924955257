import React, { useCallback } from 'react'
import HeaderMenuItem from 'components/layout/header/HeaderMenuItem'
import { css } from '@emotion/react'
import MenuStore from 'stores/MenuStore'
import { useUserState } from 'stores/ContextProvider'
import { useLocation } from 'react-router-dom'

const HeaderMenu: React.FC = ({}) => {
	const { user } = useUserState()
	const { pathname } = useLocation()
	const { menuData } = MenuStore
	const getMenuData = useCallback(() => menuData, [menuData, pathname])
	return (
		<ul css={style}>
			{getMenuData()
				.filter(menu => {
					return user ? user.role.id <= menu.role : false
				})
				.map(menu => (
					<HeaderMenuItem
						key={menu.id}
						label={menu.title}
						to={menu.child ? menu.child.filter(m => (user ? user.role.id <= m.role : false))[0].url : menu.url}
						id={menu.child ? menu.child[0].id : menu.id}
						isSelected={menu.isSelected}
					/>
				))}
		</ul>
	)
}

export default React.memo(HeaderMenu)

const style = css`
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	margin-left: 45px;
`
