import { ValidationResultType } from 'types/common'

export const numberWithCommas = (x: number) => {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
export const getURLParameters = <T>(search: string): T | null => {
	if (!search) return null
	const arrayByQueryString = search.substr(1).split('&')
	if (arrayByQueryString.length === 0) return null
	const result: any = {}

	for (const item of arrayByQueryString) {
		const arrayByItem = item.split('=', 2)
		if (arrayByItem.length == 1) result[arrayByItem[0]] = ''
		else if (arrayByItem[1] === 'undefined') result[arrayByItem[0]] = ''
		else result[arrayByItem[0]] = decodeURIComponent(arrayByItem[1].replace(/\+/g, ' '))
	}

	return result as T
}

export const isNumber = (value: string) => {
	return !isNaN(Number(value))
}

export const getValidationByType = (type: string, result: boolean): ValidationResultType => {
	return { result, message: `${type}을(를) 확인해주세요.` }
}

export const getValidationByMessage = (message: string, result = false): ValidationResultType => {
	return { result, message }
}

export const groupBy = (data: any, key: string) => {
	return data.reduce(function (carry: any, el: any) {
		const group = el[key]

		if (carry[group] === undefined) {
			carry[group] = []
		}

		carry[group].push(el)
		return carry
	}, {})
}

export const deepCopy = (data: any, init?: any) => {
	return Object.assign(init ? init : {}, data)
}
