import React from 'react'
import Table from 'components/table'
import TitleTable from 'components/templete/titleTable'
import {textStyle} from 'components/table/Table'
import {
	ProjectItem_selectItemList,
	ProjectItem_selectSampleList,
	Projects_selectProjectListByRegDt
} from 'types/graphql'

type Props = {
	project: Projects_selectProjectListByRegDt | undefined
	items: ProjectItem_selectItemList[] | undefined
	samples: ProjectItem_selectSampleList[] | undefined
}

const OperationDayTable4: React.FC<Props> = ({project, items, samples}) => {
	return (
		<TitleTable title="현장 수질분석 결과" name="water" style={{ marginBottom: '2.4rem' }}>
			<Table>
				<Table.Row bgColor="#E6E8EE">
					<Table.Col flex={10}>
						<span css={textStyle}>측정항목</span>
					</Table.Col>
					{samples?.map(sample => (
						<Table.Col flex={10} key={sample.id + 'sample'}>
							<span css={textStyle}>{sample.name}</span>
						</Table.Col>
					))}
				</Table.Row>
				{items?.map(item => (
					<Table.Row key={item.id + 'data'}>
						<Table.Col flex={10}>
							<span css={textStyle}>{item.name}</span>
						</Table.Col>
						{samples?.map(sample => {
							const findProject = project?.measurements.find(
								m => m.item.id === item.id && m.sample.name === sample.name,
							)
							return (
								<Table.Col flex={10} key={item.id + sample.id + 'data'}>
									<span css={textStyle}>
										{findProject
											? Number(findProject.ppm) === -1
												? '초과'
												: Math.floor(findProject.ppm * findProject.dilution * 10) / 10
											: '-'}
									</span>
								</Table.Col>
							)
						})}
					</Table.Row>
				))}
			</Table>
		</TitleTable>
	)
}
export default OperationDayTable4
