import { DocumentNode, useQuery } from '@apollo/client'
import { OperationVariables } from '@apollo/client/core'
import { SearchType } from 'hooks/useSearch'
import { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { getURLParameters } from 'utils/common'
import moment from 'moment'

function useCustomQuery<TData = any, TVariables = OperationVariables>(
	query: DocumentNode,
	variables?: TVariables,
	isAutoQuery = true,
) {
	const { search, pathname } = useLocation()
	const { data, loading, error, refetch } = useQuery<TData, TVariables>(query, {
		variables,
	})

	const onRefetch = useCallback(
		async (variable?: TVariables) => {
			try {
				const result = getURLParameters<SearchType>(search)
				const data = variable ? variable : result ? getVariables(result) : variables ? variables : {}

				// @ts-ignore
				const d = data.data
				if (pathname === '/user') {
					delete d.siteId
					// @ts-ignore
					data.data = d
				}

				// @ts-ignore
				return await refetch(data)
			} catch ({ message }) {
				console.log(message)
			}
		},
		[search],
	)
	const getVariables = (result: SearchType | null) => {
		return {
			data: {
				siteId: Number(result?.siteId) || 0,
				type: result?.type,
				search: result?.search,
				searchKind: result?.searchKind,
				pageNo: result?.pageNo ? Number(result?.pageNo) : 1,
				startDt:
					(result?.year || moment().format('YYYY')) + '-' + (result?.month || moment().format('MM')) + '-' + '01',
				endDt: (result?.year || moment().format('YYYY')) + '-' + (result?.month || moment().format('MM')) + '-' + '31',
			},
		} as unknown as TVariables
	}

	useEffect(() => {
		if (isAutoQuery) onRefetch()
	}, [search])

	return {
		data,
		loading,
		error,
		onRefetch,
		getVariables,
	}
}

export default useCustomQuery
