import React from 'react'
import Table from 'components/table'
import { textStyle } from 'components/table/Table'
import { Notices_selectNoticeList_notices } from 'types/graphql'
import moment from 'moment'

type Props = {
	notices?: Notices_selectNoticeList_notices[]
}
const NoticeTable: React.FC<Props> = ({ notices }) => {
	return (
		<Table>
			<Table.Row bgColor="#E6E8EE">
				<Table.Col flex={10}>
					<span css={textStyle}>{''}</span>
				</Table.Col>
				<Table.Col flex={45}>
					<span css={textStyle}>등록일</span>
				</Table.Col>
				<Table.Col flex={45}>
					<span css={textStyle}>제목</span>
				</Table.Col>
			</Table.Row>
			{notices?.map((notice, index) => (
				<Table.LinkRow to={`/notice/${notice.id}`} key={notice.id}>
					<Table.Col flex={10}>
						<span css={textStyle}>{index + 1}</span>
					</Table.Col>
					<Table.Col flex={45}>
						<span css={textStyle}>{moment(notice.regDt).format('YYYY년 MM월 DD일')}</span>
					</Table.Col>
					<Table.Col flex={45}>
						<span css={textStyle}>{notice.title}</span>
					</Table.Col>
				</Table.LinkRow>
			))}
		</Table>
	)
}
export default NoticeTable
