import React from 'react'
import {IconType} from 'components/Icon/Icon'
import IconText from 'components/iconText'
import {css} from '@emotion/react'

type Props = {
    title: string
    subTitle?: string
    name: IconType
    style?: any
    size?: number
    customCSS?: any
    children: React.ReactNode
    button?: React.ReactNode
}
const TitleTable = ({title, name, children, style, size, subTitle, customCSS, button}: Props) => {
    return (
        <div css={style} style={{flex: 1}}>
            <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                <IconText name={name} label={title} size={size || 28} subTitle={subTitle} customCSS={customCSS}/>
                {button && button}
            </div>
            <div css={TableStyle}>{children}</div>
        </div>
    )
}

export default TitleTable

const TableStyle = css`
  margin-top: 1rem;
  width: 100%;
`
