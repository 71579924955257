import React, {useEffect} from 'react';
import useSearch, {getSearchInitData} from "hooks/useSearch";
import {
    Facilities,
    FacilitiesVariables, SearchKindEnum,
} from "types/graphql";
import {SelectorValueType} from "types/common";
import View from "components/templete/view";
import FacilityRepairTable from "components/facility/FacilityRepairTable";
import {FACILITIES_QUERY} from "queries/facilityQuery";
import SiteAndMonthUtils from "components/utils/SiteAndMonthUtils";
import {useLazyQuery} from "@apollo/client";
import moment from "moment";

function FacilityRepair() {
    const {searchData, onChange, onChangeSiteId, onNext, onPrev, getUrlByPageNo, onPushBySiteId} = useSearch(
        '/facility/repair',
        getSearchInitData(SearchKindEnum.GROUP_NAME),
    )
    const [onFetch, {data}] = useLazyQuery<Facilities, FacilitiesVariables>(FACILITIES_QUERY)

    const _onChangeSelector = (value: SelectorValueType) => {
        onChangeSiteId(value)
        onPushBySiteId(value)
    }

    useEffect(() => {
        onFetch({
            variables: {
                data: {
                    pageNo: Number(searchData.pageNo),
                    limit: 10,
                    siteId: searchData.siteId,
                    startDt: moment(`${searchData.year}-${searchData.month}`).format("YYYY-MM")
                }
            }
        })
    }, [searchData])


    return (
        <View
            title="시설물 수리내역"
            tables={[<FacilityRepairTable key="FacilityRepairTable"
                                          facilities={data?.selectFacilityListByWeb.facilities}
            />]}
            utils={
                <SiteAndMonthUtils
                    onChangeSelector={_onChangeSelector}
                    searchData={searchData}
                    onChange={onChange}
                    onNext={onNext}
                    onPrev={onPrev}
                    writeUrl="/facility/repair/write"
                />
            }
            paging={data?.selectFacilityListByWeb.paging}
            baseUrl={getUrlByPageNo}
        />
    )
}

export default FacilityRepair;
