import React, { useCallback } from 'react'
import useSearch, { getSearchInitData } from 'hooks/useSearch'
import { SearchKindEnum, SludgeYear as SludgesYear, SludgeYearVariables } from 'types/graphql'
import { SelectorValueType } from 'types/common'
import View from 'components/templete/view'
import SludgeYearTable from 'components/sludge/SludgeYearTable'
import SiteAndYearUtils from 'components/utils/SiteAndYearUtils'
import useCustomQuery from 'hooks/useCustomQuery'
import { SLUDGE_YEAR_QUERY } from 'queries/sludgeQuery'

function SludgeYear() {
	const { searchData, onChange, onChangeSiteId, getUrlByPageNo } = useSearch(
		'/sludge/year',
		getSearchInitData(SearchKindEnum.GROUP_NAME),
	)
	const { onRefetch, data } = useCustomQuery<SludgesYear, SludgeYearVariables>(
		SLUDGE_YEAR_QUERY,
		{
			data: {
				siteId: searchData.siteId,
				regDt: searchData.year,
			},
		},
		false,
	)

	const _onChangeSelector = useCallback(
		(value: SelectorValueType) => {
			onChangeSiteId(Number(value))
			_onRefetch({
				siteId: Number(value),
				regDt: searchData.year,
			})
		},
		[searchData.year],
	)

	const _onRefetch = ({ siteId, regDt }: { siteId: number; regDt: string }) => {
		onRefetch({
			data: {
				siteId,
				regDt,
			},
		})
	}

	return (
		<View
			title="연간 슬러지 사용량"
			tables={[<SludgeYearTable key="MedicineWriteTable" data={data?.selectSludgeByYear} />]}
			utils={<SiteAndYearUtils onChangeSelector={_onChangeSelector} searchData={searchData} onChange={onChange} />}
			baseUrl={getUrlByPageNo}
		/>
	)
}

export default SludgeYear
