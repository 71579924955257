// @flow
import React, { useCallback } from 'react'
import { css } from '@emotion/react'
import SideMenu from 'components/layout/side/SideMenu'
import MenuStore from 'stores/MenuStore'
import { useUserState } from 'stores/ContextProvider'
import Icon from 'components/Icon'
import { useLocation } from 'react-router-dom'
import { media } from 'styles/GlobalStyle'
import { observer } from 'mobx-react-lite'
import { IconType } from 'components/Icon/Icon'

function Side() {
	const { user } = useUserState()
	const { pathname } = useLocation()
	const { getMenu, menuData } = MenuStore

	const getMenuData = useCallback(() => {
		return menuData.find(menu => {
			return menu.isSelected && (user ? user.role.id <= menu.role : false)
		})
	}, [menuData, pathname])

	if (pathname === '/login') return null

	return (
		<aside css={style}>
			<div>
				<Icon
					name={(getMenuData() ? getMenuData()?.icon : getMenu(user) ? getMenu(user)?.icon : 'calendar') as IconType}
					color="#fff"
				/>
			</div>
			<SideMenu menus={getMenuData() ? getMenuData()?.child : getMenu(user) ? getMenu(user)?.child : []} />
		</aside>
	)
}

export default observer(Side)

const style = css`
	width: 250px;
	background-color: #323232;

	${media(
		'phone-land',
		`
		width: 100px;
	`,
	)}
	${media(
		'phone-port',
		`
		width: 100px;
	`,
	)}
  & > div {
		display: flex;
		justify-content: center;
		padding: 38px 86px 18px;
	}
`
