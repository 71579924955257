import React from 'react'
import CheckBox from 'components/checkBox/CheckBox'
import { css } from '@emotion/react'

type Props = {
	label: string
	value: boolean
	onToggle(): void
}
const CheckBoxGroup: React.FC<Props> = ({ label, value, onToggle }) => {
	return (
		<div css={style}>
			<CheckBox value={value} onToggle={onToggle} />
			<label>{label}</label>
		</div>
	)
}

export default CheckBoxGroup

const style = css`
	display: inline-flex;
	flex-direction: row;
	align-items: center;
	margin-right: 1rem;

	&:hover {
		cursor: pointer;
	}

	& > label {
		margin-left: 0.6rem;
		font-size: 1.5rem;
	}
`
