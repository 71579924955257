import { SignUp, SignUpInput, SignUpVariables } from 'types/graphql'
import useUserCommon from 'hooks/user/useUserCommon'
import { SIGN_UP_QUERY } from 'queries/userQuery'

function useCreateUser() {
	const { setUser, ...props } = useUserCommon<SignUp, SignUpVariables>(getUserObject(), SIGN_UP_QUERY)

	return {
		...props,
		setUser,
	}
}

export const getUserObject = () => {
	return {
		userId: '',
		password: '',
		name: '',
		cellPhone: '',
		email: '',
		memo: '',
		groupId: 0,
		roleId: 0,
	} as SignUpInput
}

export default useCreateUser
