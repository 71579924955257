import { gql } from '@apollo/client'

export const CREATE_OPERATION_QUERY = gql`
	mutation CreateOperation($data: InsertDailyCheckInput!) {
		insertDailyCheck(data: $data) {
			id
		}
	}
`

export const OPERATION_QUERY = gql`
	query Operation($data: DailyCheckByDayInput!) {
		dailyCheckByDay(data: $data) {
			id
			site {
				id
			}
			dailyCheckItems {
				id
				value
				memo

				code {
					id
					name
					parentCode {
						id
						name
					}
				}
			}
		}
	}
`

export const DAILY_OPERATION_QUERY = gql`
	query DailyOperation($regDt: String!, $siteId: Float!) {
		selectDiary(data: { regDt: $regDt, siteId: $siteId }) {
			id
			diaryItems {
				id
				value
				code {
					id
					name
				}
			}
		}

		chemicalForDay(data: { regDt: $regDt, siteId: $siteId }) {
			id
			regDt
			orderAmount
			useAmount
			site {
				id
				name
			}
			item {
				id
				measure
				name
			}
		}

		selectFlowList {
			id
			name
			ord
		}

		selectProjectListByRegDt(data: { siteId: $siteId, regDt: $regDt }) {
			id
			regDt
			measurements {
				id
				item {
					id
					name
				}
				sample {
					id
					name
				}
				ppm
			}
			user {
				id
				name
			}
		}

		selectInfluentFlowRecently(data: { siteId: $siteId, regDt: $regDt }) {
			id
			regDt
			influentFlowItems {
				result
				flow {
					id
				}
			}
		}

		selectInfluentFlow(data: { siteId: $siteId, regDt: $regDt }) {
			id
			regDt
			inspector
			site {
				id
				name
			}
			influentFlowItems {
				result
				flow {
					id
				}
			}
		}

		selectTotalToRegDt(data: { siteId: $siteId, regDt: $regDt }) {
			month {
				id
				result
			}
			year {
				id
				result
			}
		}

		selectChemicalHistoryRemains(data: { siteId: $siteId }) {
			item {
				id
				name
			}
			remains
		}
	}
`
