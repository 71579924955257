import React from 'react'
import { useParams } from 'react-router-dom'
import NoticeUpdate from './NoticeUpdate'
import NoticeWrite from './NoticeWrite'

function NoticeWriteOrUpdate() {
	const { id } = useParams<{ id?: string }>()

	return id ? <NoticeUpdate id={Number(id)} /> : <NoticeWrite />
}

export default NoticeWriteOrUpdate
