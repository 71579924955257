import React, {useCallback, useEffect} from 'react'
import useSearch, { getSearchInitData } from 'hooks/useSearch'
import { SearchKindEnum, Operation, OperationVariables } from 'types/graphql'
import { SelectorValueType } from 'types/common'
import View from 'components/templete/view'
import OperationCheckTable from 'components/operation/OperationCheckTable'
import SiteAndDayUtils from 'components/utils/SiteAndDayUtils'
import useCreateDaily from 'hooks/operation/useCreateDaily'
import { OPERATION_QUERY } from 'queries/operationQuery'
import moment from 'moment'
import { useQuery, useLazyQuery } from '@apollo/client'
import DailyCheckDto from 'dtos/dailyCheck/DailyCheckDto'

function OperationCheck() {
	const { searchData, onChangeSiteId, onChangeRegDt } = useSearch(
		'/operation/check',
		getSearchInitData(SearchKindEnum.GROUP_NAME),
	)
	const { onSave, operation, setOperation, onChangeInput, onChangeToggle, code, onChangeSite } = useCreateDaily()
	const [refetch] = useLazyQuery<Operation, OperationVariables>(OPERATION_QUERY, {
		onCompleted: ({ dailyCheckByDay }) => {
			console.log(dailyCheckByDay)
			if (dailyCheckByDay) {
				setOperation(new DailyCheckDto(dailyCheckByDay))
			}
		},
		onError: error => console.log(error),
	})

	const _onChangeSelector = useCallback(
		(value: SelectorValueType) => {
			if(Number(value) === 0) return
			onChangeSiteId(Number(value))
			onChangeSite(Number(value))
			refetch({ variables: {data: { siteId: Number(value), regDt: searchData.regDt } }})
		},
		[searchData.regDt],
	)

	const _onChangeRegDt = useCallback(
		(date: Date) => {
			if(searchData.siteId === 0) return
			onChangeRegDt(date)
			refetch({ variables: {data: { siteId: searchData.siteId, regDt: moment(date).format('YYYY-MM-DD') } }})
		},
		[searchData.siteId],
	)

	const onToggle = useCallback(
		(type: 'red' | 'green') => (id: number) => {
			onChangeToggle(type, id)
		},
		[],
	)

	useEffect(() => {
		refetch({variables: {data: {siteId: searchData.siteId, regDt: moment().format("YYYY-MM-DD")}}})
	}, [])

	return (
		<View
			title="일일 체크리스트"
			tables={[
				<OperationCheckTable
					key="OperationCheckTable"
					operation={operation}
					onToggle={onToggle}
					codes={code.data?.selectCodeList.data}
					onChangeInput={onChangeInput}
				/>,
			]}
			utils={
				<SiteAndDayUtils
					searchData={searchData}
					onChangeRegDt={_onChangeRegDt}
					onChangeSelector={_onChangeSelector}
					onClick={searchData.regDt === moment().format('YYYY-MM-DD') ? onSave : undefined}
				/>
			}
		/>
	)
}

export default OperationCheck
