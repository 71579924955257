import OperationWriteTable from 'components/operation/OperationWriteTable'
import View from 'components/templete/view'
import SiteAndDayUtils from 'components/utils/SiteAndDayUtils'
import useCreateDiary from 'hooks/operation/useCreateDiary'
import useSearch from 'hooks/useSearch'
import moment from 'moment'
import React, {useCallback, useEffect} from 'react'
import { SelectorValueType } from 'types/common'

function OperationWrite() {
	const { searchData, onChangeSiteId, onChangeRegDt } = useSearch('/operation/write')
	const { diary, codes, onSave, onChangeSite, onChangeInput, onRefetch } = useCreateDiary()

	const _onChangeSelector = useCallback(
		(value: SelectorValueType) => {
			onRefetch({ variables: {data: { siteId: Number(value), regDt: searchData.regDt } }})
			onChangeSiteId(Number(value))
			onChangeSite(Number(value))
		},
		[searchData.regDt],
	)

	const _onChangeRegDt = useCallback(
		(date: Date) => {
			onChangeRegDt(date)

			onRefetch({ variables: {data: { siteId: searchData.siteId, regDt: moment(date).format('YYYY-MM-DD') } }})
		},
		[searchData.siteId],
	)

	const getDiaryItemsByCodeId = useCallback((type: 1 | 2) => {
		return diary.diaryItems.filter(item => type === 1 ? item.code.id < 120000 : item.code.id > 120000)
	}, [diary])

	useEffect(() => {
		onRefetch({variables: {data: {siteId: searchData.siteId, regDt: moment().format("YYYY-MM-DD")}}})
	}, [])

	return (
		<View
			title="일일 운영 일지 등록"
			tables={[
				<OperationWriteTable
					key="OperationWorkerWriteTable"
					code={codes?.find(code => code.id === 11)}
					items={getDiaryItemsByCodeId(1)}
					onChangeInput={onChangeInput}
					title="근무자 현황"
					name="mans"
				/>,
				<OperationWriteTable
					key="OperationPowerWriteTable"
					marginTop="2.4rem"
					code={codes?.find(code => code.id === 12)}
					items={getDiaryItemsByCodeId(2)}
					onChangeInput={onChangeInput}
					title="전력량 현황"
					name="power"
				/>,
			]}
			utils={
				<SiteAndDayUtils
					searchData={searchData}
					onChangeSelector={_onChangeSelector}
					onChangeRegDt={_onChangeRegDt}
					onClick={onSave(searchData.siteId)}
				/>
			}
		/>
	)
}

export default OperationWrite
