import React from 'react'
import { css } from '@emotion/react'
import { font } from 'styles/Theme'
import { NavLink, useLocation } from 'react-router-dom'

type Props = {
	label: string
	to: string
}
const SubMenuItem: React.FC<Props> = ({ label, to }) => {
	const { pathname } = useLocation()
	return (
		<li css={itemStyle(pathname.indexOf(to) > -1)}>
			<NavLink to={to}>- {label}</NavLink>
		</li>
	)
}

export default SubMenuItem

const itemStyle = (isSelected: boolean) => css`
	${font.body3}
	height: 48px;
	line-height: 48px;
	& > a {
		color: #fff;
	}

	& > a:hover {
		color: #fff;
	}
	${isSelected &&
	css`
		& > a {
			color: #40e9ff;
		}
	`}
`
