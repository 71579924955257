import React, { useCallback, useState } from 'react'
import InfluentFlowDto from 'dtos/flow/InfluentFlowDto'
import useCustomMutation from 'hooks/useCustomMutation'
import { CreateFlow, CreateFlowVariables } from 'types/graphql'
import { CREATE_FLOW_QUERY } from 'queries/flowQuery'

function useCreateFlow() {
	const [influentFlow, setInfluentFlow] = useState<InfluentFlowDto>()
	const [save] = useCustomMutation<CreateFlow, CreateFlowVariables>(CREATE_FLOW_QUERY)

	const onChangeResult = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const { name, value } = e.target
			setInfluentFlow(
				Object.assign(new InfluentFlowDto(), {
					...influentFlow,
					influentFlowItems: influentFlow?.influentFlowItems.map(item => {
						return Number(name) === item.flow.id ? { ...item, result: Number(value) } : { ...item }
					}),
				}),
			)
		},
		[influentFlow],
	)

	const onChangeSite = (id: number) => {
		const result = Object.assign(new InfluentFlowDto(), {
			...influentFlow,
			site: { __typename: 'SiteEntity', name: '', id },
		})
		setInfluentFlow(result)
	}

	const onSave = useCallback(
		(siteId: number, callback: () => void) => () => {
			influentFlow && save({ data: { ...influentFlow.getCreate(), siteId } }, { onCompleted: callback })
		},
		[influentFlow],
	)

	return {
		influentFlow,
		setInfluentFlow,
		onChangeResult,
		onSave,
		onChangeSite,
	}
}

export default useCreateFlow
