import React from 'react'
import InputBase from 'components/inputBase/InputBase'
import Icon from 'components/Icon'
import { css } from '@emotion/react'
import Selector from 'components/selector/Selector'
import moment from 'moment'
import { SelectorType } from 'types/common'
import { getSearchInitData } from 'hooks/useSearch'
import { SearchKindEnum } from 'types/graphql'

const onChangeType = getSearchInitData(SearchKindEnum.MANAGER)
type Props = {
	onNext(): void
	onPrev(): void
	month: string
	yarn: string
	onChange: (type: keyof typeof onChangeType, value: string) => void
}
const MonthInput: React.FC<Props> = ({ onNext, onPrev, month, yarn, onChange }) => {
	const MIN_YARN = 2020
	const getYarn = () => {
		const MAX_YARN = Number(moment().format('YYYY'))
		const result: SelectorType[] = []
		for (let i = MAX_YARN; i >= MIN_YARN; i--) {
			result.push({ label: String(i), value: i })
		}

		return result
	}

	return (
		<>
			<Selector
				options={getYarn()}
				values={[yarn]}
				onChange={value => onChange('year', String(value))}
				css={css`
					width: 10rem;
					height: 3.6rem;
				`}
			/>
			<InputBase
				css={css`
					margin: 0 1rem;
					height: 3.6rem;
				`}
			>
				<div css={inputStyle}>
					<div onClick={onPrev} role="button" tabIndex={0}>
						<Icon name={'arrow'} size={14} />
					</div>
					<p>{month}</p>
					<div onClick={onNext} role="button" tabIndex={0}>
						<Icon name={'arrow'} size={14} />
					</div>
				</div>
			</InputBase>
		</>
	)
}

export default MonthInput

const inputStyle = css`
	width: 9.8rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 0.82rem;
	font-size: 18px;

	& svg:hover {
		cursor: pointer;
	}

	& div:first-child svg {
		transform: rotate(90deg);
	}

	& div:last-child svg {
		transform: rotate(-90deg);
	}
`
