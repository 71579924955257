import React, { useCallback, useState } from 'react'
import useSearch, { getSearchInitData, onChangeType } from 'hooks/useSearch'
import {
	AnalysisProcessEnum,
	Items,
	ItemsVariables,
	ItemTypeEnum,
	Samples,
	SearchKindEnum,
	WaterMonth as WaterMonthQuery,
	WaterMonth_selectProjectByMonth,
	WaterMonthVariables,
} from 'types/graphql'
import { useQuery } from '@apollo/client'
import { ITEMS_QUERY } from 'queries/ItemQuery'
import { WATER_MONTH_QUERY } from 'queries/waterQuery'
import moment from 'moment'
import { SelectorValueType } from 'types/common'
import View from 'components/templete/view'
import WaterMonthGraphTable from 'components/water/WaterMonthGraphTable'
import WaterMonthGraphUtils from 'components/water/WaterMonthGraphUtils'
import { SAMPLES_QUERY } from 'queries/sampleQuery'

function WaterMonthGraph() {
	const [itemId, setItemId] = useState(1)
	const [samples, setSamples] = useState<any[]>()
	const { searchData, onChange, onChangeSiteId, onNext, onPrev, getPrevMonth, getNextMonth } = useSearch(
		'/water/month/graph',
		getSearchInitData(SearchKindEnum.GROUP_NAME),
	)
	useQuery<Samples>(SAMPLES_QUERY, {
		onCompleted: ({ selectSampleList }) => {
			if (selectSampleList) {
				setSamples(
					selectSampleList
						.filter(sample => sample.analysisProcess === AnalysisProcessEnum.A2O)
						.map(sample => ({ name: sample.name, id: sample.id, isSelected: true })),
				)
			}
		},
	})
	const itemResult = useQuery<Items, ItemsVariables>(ITEMS_QUERY, {
		variables: { data: { type: [ItemTypeEnum.ITEM1] } },
	})
	const { refetch, data } = useQuery<WaterMonthQuery, WaterMonthVariables>(WATER_MONTH_QUERY, {
		variables: {
			data: {
				siteId: Number(searchData.siteId),
				regDt: moment(`${searchData.year}-${searchData.month}`).format('YYYY-MM'),
			},
		},
	})
	const _onChangeSelector = useCallback(
		(value: SelectorValueType) => {
			onChangeSiteId(Number(value))
			_onRefetch({
				siteId: Number(value),
				regDt: moment(`${searchData.year}-${searchData.month}-01`).format('YYYY-MM-DD'),
			})
		},
		[searchData.year, searchData.month],
	)
	const _onChange = useCallback((type: keyof typeof onChangeType, value: string) => {
		onChange(type, value)
	}, [])

	const _onChangeMonth = useCallback(
		(type: 'next' | 'prev') => () => {
			const isNext = type === 'next'
			const month = isNext ? getNextMonth() : getPrevMonth()

			_onRefetch({
				siteId: Number(searchData.siteId),
				regDt: `${searchData.year}-${month}`,
			})

			isNext ? onNext() : onPrev()
		},
		[searchData],
	)

	const _onRefetch = ({ siteId, regDt }: { siteId: number; regDt: string }) => {
		refetch({
			data: {
				siteId,
				regDt,
			},
		})
	}

	const getProjects = useCallback(() => {
		let result: WaterMonth_selectProjectByMonth[] = []
		if (data?.selectProjectByMonth) {
			const map = new Map<string, WaterMonth_selectProjectByMonth>()
			for (const project of data.selectProjectByMonth) {
				map.set(moment(project.regDt).format('YYYY-MM-DD'), project)
			}
			result = Array.from(map.values())
		}
		return samples
			?.filter(sample => sample.isSelected)
			.map(sample => {
				const tempData = []
				for (const project of result) {
					const findItem = project.measurements.find(
						item => item.item.id === itemId && item.sample.name === sample.name,
					)
					if (findItem) tempData.push({ regDt: moment(project.regDt).format('YYYY-MM-DD'), item: findItem })
				}
				return {
					id: sample.name,
					data: tempData.map(td => ({
						x: td.regDt,
						y: Number(td.item.ppm) === -1 ? '0' : Math.floor(Number(td.item.ppm) * td.item.dilution * 10) / 10,
					})),
				}
			})
	}, [data, itemId, samples])

	const onChangeItemId = useCallback((value: SelectorValueType) => {
		setItemId(value)
	}, [])

	const onToggleSample = useCallback(
		(sampleId: number) => {
			setSamples(
				samples?.map(sample =>
					sample.id === sampleId ? { ...sample, isSelected: !sample.isSelected } : { ...sample },
				),
			)
		},
		[samples],
	)

	return (
		<View
			title="월간 수질 분석 결과"
			tables={[
				<WaterMonthGraphTable
					key="WaterMonthGraphTable"
					samples={samples}
					data={getProjects() || []}
					onToggleSample={onToggleSample}
				/>,
			]}
			utils={
				<WaterMonthGraphUtils
					onChangeItemId={onChangeItemId}
					onChangeSelector={_onChangeSelector}
					itemId={itemId}
					items={itemResult.data?.selectItemList}
					searchData={searchData}
					onChange={_onChange}
					onNext={_onChangeMonth('next')}
					onPrev={_onChangeMonth('prev')}
					graphUrl={`/water/month?year=${searchData.year}&month=${searchData.month}&siteId=${searchData.siteId}`}
				/>
			}
		/>
	)
}

export default WaterMonthGraph
