import React, { useCallback } from 'react'
import useSearch, { getSearchInitData, onChangeType } from 'hooks/useSearch'
import { Items, ItemsVariables, ItemTypeEnum, SearchKindEnum } from 'types/graphql'
import { SelectorValueType } from 'types/common'
import View from 'components/templete/view'
import MedicineMonthTable from 'components/medicine/MedicineMonthTable'
import SiteAndYearUtils from 'components/utils/SiteAndYearUtils'
import useCustomQuery from 'hooks/useCustomQuery'
import { MEDICINE_MONTH_QUERY } from 'queries/medicineQuery'
import { useQuery } from '@apollo/client'
import { ITEMS_QUERY } from 'queries/ItemQuery'

function MedicineMonth() {
	const { searchData, onChange, onChangeSiteId } = useSearch(
		'/medicine/month',
		getSearchInitData(SearchKindEnum.GROUP_NAME),
	)
	const itemsResult = useQuery<Items, ItemsVariables>(ITEMS_QUERY, {
		variables: { data: { type: [ItemTypeEnum.ITEM1, ItemTypeEnum.ITEM2] } },
	})
	const { onRefetch, data } = useCustomQuery(
		MEDICINE_MONTH_QUERY,
		{ data: { siteId: searchData.siteId, regDt: searchData.year } },
		false,
	)

	const _onChangeSelector = useCallback(
		(value: SelectorValueType) => {
			onChangeSiteId(value)
			onRefetch({ data: { siteId: Number(value), regDt: searchData.year } })
		},
		[searchData.year],
	)

	const _onChange = useCallback(
		async (type: keyof typeof onChangeType, value: string) => {
			onChange(type, value)
			onRefetch({ data: { siteId: searchData.siteId, regDt: value } })
		},
		[searchData.siteId],
	)
	return (
		<View
			title="월간 약품 사용량"
			tables={[
				<MedicineMonthTable
					key="MedicineWriteTable"
					data={data?.selectChemicalHistoryManagement}
					items={itemsResult.data?.selectItemList}
				/>,
			]}
			utils={<SiteAndYearUtils onChangeSelector={_onChangeSelector} searchData={searchData} onChange={_onChange} />}
		/>
	)
}

export default MedicineMonth
