import { useUserDispatch, useUserState } from 'stores/ContextProvider'
import useCustomMutation from 'hooks/useCustomMutation'
import { Logout } from 'types/graphql'
import { LOGOUT_QUERY } from 'queries/userQuery'
import { useHistory } from 'react-router-dom'
import SiteStore from 'stores/SiteStore'

const useLogout = () => {
	const state = useUserState()
	const dispatch = useUserDispatch()
	const history = useHistory()
	const [logout] = useCustomMutation<Logout>(LOGOUT_QUERY, {
		onCompleted: () => {
			SiteStore.sites = []
			dispatch({ type: 'LOGOUT' })
			history.push('/login')
			window.location.reload()
		},
		onError: error => {
			console.log(error)
			dispatch({ type: 'LOGOUT' })
		},
	})

	return {
		state,
		logout,
	}
}

export default useLogout
