import React from 'react'
import View from 'components/templete/view'
import TableByUser from 'components/user/TableByUser'
import UtilsByUser from 'components/user/UtilsByUser'
import { SearchKindEnum, Users, UsersVariables } from 'types/graphql'
import { USERS_QUERY } from 'queries/userQuery'
import useSearch, { getSearchInitData } from 'hooks/useSearch'
import Error from 'components/error'
import Loading from 'components/loading'
import useCustomQuery from 'hooks/useCustomQuery'

const User: React.FC = ({}) => {
	const { data, loading, error } = useCustomQuery<Users, UsersVariables>(USERS_QUERY, { data: {} })
	const { searchData, onPush, getUrlByPageNo, onChangeSearch, onChangeSelector } = useSearch(
		'/user',
		getSearchInitData(SearchKindEnum.USER_ID),
	)

	if (error) return <Error error={error} />
	if (loading) return <Loading />

	return (
		<View
			title="계정 관리"
			tables={[<TableByUser key="tableByUser" data={data?.selectUserList.users} />]}
			utils={
				<UtilsByUser
					onChangeSearch={onChangeSearch}
					onChangeSelector={onChangeSelector}
					data={searchData}
					onPush={onPush}
				/>
			}
			baseUrl={getUrlByPageNo}
			paging={data?.selectUserList.paging}
		/>
	)
}

export default User
