import { useLazyQuery } from '@apollo/client'
import { Me } from 'types/graphql'
import { ME_QUERY } from 'queries/userQuery'
import { useUserDispatch, useUserState } from 'stores/ContextProvider'
import SiteStore from "stores/SiteStore";

function useMe() {
	const state = useUserState()
	const dispatch = useUserDispatch()
	const [me, {}] = useLazyQuery<Me>(ME_QUERY, {
		onCompleted: data => {
			if (data) {
				SiteStore.sites = data.me.sites || []
				dispatch({ type: 'SET_USER', value: data.me })
				dispatch({ type: 'LOGIN' })
			}
		},
		onError: () => {
			dispatch({ type: 'LOGOUT' })
		},
	})

	return {
		user: state.user,
		isLogin: state.isLogin,
		me,
	}
}

export default useMe
