import React, { useCallback } from 'react'
import useSearch, { getSearchInitData, onChangeType } from 'hooks/useSearch'
import { FlowsCount, FlowsCountVariables, SearchKindEnum } from 'types/graphql'
import { SelectorValueType } from 'types/common'
import View from 'components/templete/view'
import FlowCountTable from 'components/flow/FlowCountTable'
import useCustomQuery from 'hooks/useCustomQuery'
import { FLOWS_COUNT_QUERY } from 'queries/flowQuery'
import moment from 'moment'
import SiteAndMonthUtils from 'components/utils/SiteAndMonthUtils'

function FlowCount() {
	const { searchData, onChange, onChangeSiteId, onNext, onPrev, getUrlByPageNo, getPrevMonth, getNextMonth } =
		useSearch('/flow/count', getSearchInitData(SearchKindEnum.GROUP_NAME))
	const { onRefetch, data } = useCustomQuery<FlowsCount, FlowsCountVariables>(
		FLOWS_COUNT_QUERY,
		{
			data: {
                siteId: searchData.siteId,
                regDt: moment(`${searchData.year}-${searchData.month}-01`).format('YYYY-MM-DD')
            }
		},
		false,
	)

	const _onChangeSelector = useCallback(
		(value: SelectorValueType) => {
			onChangeSiteId(Number(value))
			_onRefetch({
				siteId: Number(value),
				regDt: moment(`${searchData.year}-${searchData.month}-01`).format('YYYY-MM-DD'),
			})
		},
		[searchData.year, searchData.month],
	)
	const _onChange = useCallback((type: keyof typeof onChangeType, value: string) => {
		onChange(type, value)
	}, [])

	const _onChangeMonth = useCallback(
		(type: 'next' | 'prev') => () => {
			const isNext = type === 'next'
			const month = isNext ? getNextMonth() : getPrevMonth()

			_onRefetch({
				siteId: Number(searchData.siteId),
				regDt: moment(`${searchData.year}-${month}-01`).format('YYYY-MM-DD'),
			})

			isNext ? onNext() : onPrev()
		},
		[searchData],
	)

	const _onRefetch = ({ siteId, regDt }: { siteId: number; regDt: string }) => {
		onRefetch({
			data: {
				siteId,
				regDt,
			},
		})
	}
	return (
		<View
			title="유량측정 횟수"
			tables={[<FlowCountTable key="WaterMonthTable" data={data?.selectFlowMonthResultWithRegDt} />]}
			utils={
				<SiteAndMonthUtils
					onChangeSelector={_onChangeSelector}
					searchData={searchData}
					onChange={_onChange}
					onNext={_onChangeMonth('next')}
					onPrev={_onChangeMonth('prev')}
				/>
			}
			baseUrl={getUrlByPageNo}
		/>
	)
}

export default FlowCount
